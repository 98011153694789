import DocumentCard from '@pages/Inspections/components/RightPanelCards/DocumentCard';
import RegoCheckCard from '@pages/Inspections/components/RightPanelCards/RegoCheckCard';
import { useInspectionDetailStore } from '@stores';
import { DocumentFormEnum, RegoSecurityCheckEnum } from '@types';

const RegoPaper = () => {
  const { activePanel, rightBarStatus, inspectionDetailData } =
    useInspectionDetailStore();

  return (
    <>
      {activePanel.activeSection === 'REGO_PAPERS' ||
        (activePanel.activeSection === 'registration' && (
          <DocumentCard type={DocumentFormEnum.DETAILS_REGO_PHOTO_FRONT} />
        ))}
      {activePanel.activeSection === 'registrationAuthenticity' && (
        <RegoCheckCard
          type={RegoSecurityCheckEnum.REGO_SECURITY_AUTHENTICITY}
          status={rightBarStatus}
          data={inspectionDetailData.inspectionChecks.regoCertificate}
        />
      )}
      {activePanel.activeSection === 'regoCertificate' && (
        <RegoCheckCard
          type={RegoSecurityCheckEnum.REGO_SECURITY_DATA_EDIT}
          status={rightBarStatus}
          data={inspectionDetailData.inspectionChecks.regoCertificate}
        />
      )}
      {activePanel.activeSection === 'expiry' && (
        <RegoCheckCard
          type={RegoSecurityCheckEnum.REGO_SECURITY_EXPIRY}
          status={rightBarStatus}
          data={inspectionDetailData.inspectionChecks.registrationExpiry}
        />
      )}
    </>
  );
};

export default RegoPaper;
