import React, { forwardRef, Ref } from 'react';
import { Checkbox, CheckboxProps } from '@material-tailwind/react';

type Props = CheckboxProps & {
  ref?: Ref<HTMLInputElement>;
};

const CheckBox = forwardRef<HTMLInputElement, Props>(
  ({ label, ...rest }, ref) => {
    return (
      <Checkbox
        ref={ref}
        label={label}
        className="bg-white before:w-0 checked:bg-primary rounded w-4 h-4 checked:border-primary"
        containerProps={{
          className: 'p-0 rounded-none'
        }}
        labelProps={{
          className: 'text-grey-700 pl-2 text-sm font-medium'
        }}
        ripple={false}
        {...rest}
      />
    );
  }
);

export default CheckBox;
