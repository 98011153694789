import React from 'react';
const VinIcon = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.80777 6.15127C1.80777 5.27846 1.80558 4.40492 1.80777 3.53211C1.81068 2.58784 2.41808 1.97023 3.35579 1.96804C5.1386 1.9644 6.92141 1.96513 8.70423 1.96804C9.21245 1.96877 9.51943 2.37929 9.32766 2.79054C9.2037 3.05596 8.97475 3.13617 8.69912 3.13471C8.06256 3.13252 7.426 3.13471 6.78943 3.13471C5.68912 3.13471 4.58808 3.13471 3.48777 3.13471C3.0262 3.13471 2.97662 3.18502 2.97662 3.65679C2.97662 5.33023 2.97662 7.00367 2.97662 8.67784C2.97662 8.7595 2.97881 8.8419 2.97443 8.92357C2.95256 9.29836 2.71339 9.54846 2.38454 9.54263C2.05495 9.53679 1.81214 9.28086 1.80995 8.90752C1.80412 7.98877 1.80777 7.07002 1.80777 6.15127Z"
        fill={color}
      />
      <path
        d="M5.95717 26.4309C5.11134 26.4309 4.2655 26.4331 3.41967 26.4309C2.39592 26.428 1.80821 25.8425 1.80748 24.826C1.80675 23.0709 1.80602 21.3158 1.80748 19.56C1.80748 19.1254 2.0379 18.8644 2.40394 18.8702C2.6854 18.8746 2.90852 19.0583 2.95592 19.3391C2.97561 19.455 2.97488 19.5746 2.97488 19.692C2.97634 21.3836 2.97488 23.0753 2.97561 24.767C2.97561 25.214 3.03321 25.2708 3.478 25.2708C5.22436 25.2708 6.96998 25.2708 8.71634 25.2716C9.0904 25.2716 9.33613 25.467 9.37769 25.7849C9.42363 26.1385 9.14873 26.4258 8.73894 26.428C7.81144 26.4339 6.88394 26.4295 5.95571 26.4302L5.95717 26.4309Z"
        fill={color}
      />
      <path
        d="M26.2689 6.13875C26.2689 7.06625 26.2733 7.99448 26.2667 8.92198C26.2638 9.29167 26.0101 9.54834 25.6834 9.54323C25.3546 9.53813 25.119 9.27854 25.1081 8.90813C25.1059 8.84469 25.1081 8.78052 25.1081 8.71708C25.1081 7.58906 25.1081 6.46177 25.1081 5.33375C25.1081 4.77011 25.1081 4.20573 25.1081 3.64208C25.1081 3.19073 25.0541 3.13531 24.6115 3.13531C22.9293 3.13531 21.2464 3.13531 19.5642 3.13531C19.4556 3.13531 19.3455 3.13677 19.2376 3.12438C18.9218 3.09011 18.7184 2.87136 18.7111 2.56875C18.7038 2.25886 18.916 2.01386 19.2325 1.97448C19.3134 1.96427 19.3958 1.96792 19.4775 1.96792C21.2056 1.96792 22.9337 1.96719 24.6618 1.96792C25.6827 1.96792 26.2682 2.55344 26.2704 3.575C26.2726 4.42958 26.2704 5.2849 26.2704 6.13948H26.2697L26.2689 6.13875Z"
        fill={color}
      />
      <path
        d="M22.1075 26.4309C21.1887 26.4309 20.2707 26.4338 19.3519 26.4294C18.9808 26.428 18.7496 26.2369 18.7154 25.9278C18.6738 25.5493 18.9276 25.2759 19.3366 25.273C20.0731 25.2686 20.8095 25.2715 21.5467 25.2715C22.5741 25.2715 23.6022 25.2715 24.6296 25.2715C25.0372 25.2715 25.1087 25.2015 25.1087 24.8012C25.1087 23.0461 25.108 21.291 25.1102 19.5359C25.1102 19.1203 25.3413 18.8665 25.7015 18.8709C25.9946 18.8746 26.2258 19.0722 26.2593 19.3638C26.2768 19.5169 26.2717 19.6723 26.2717 19.8268C26.2717 21.4908 26.2732 23.1555 26.2717 24.8194C26.271 25.8519 25.6986 26.428 24.6727 26.4309C23.8181 26.4331 22.9628 26.4309 22.1082 26.4309H22.1075Z"
        fill={color}
      />
      <g clipPath="url(#clip0_9270_61089)">
        <path
          d="M6.25 9.43053C6.28906 9.34733 6.32148 9.26022 6.36797 9.18131C6.52344 8.91803 6.76328 8.78795 7.06367 8.77975C7.38555 8.77115 7.7082 8.76959 8.03008 8.77975C8.50352 8.79459 8.83906 9.13717 8.83945 9.61256C8.84219 12.5481 8.84219 15.4833 8.83945 18.4188C8.83906 18.9005 8.49727 19.2407 8.01445 19.2524C7.70273 19.2598 7.39102 19.259 7.0793 19.2524C6.69062 19.2446 6.41602 19.0372 6.28477 18.6661C6.27656 18.643 6.26172 18.6223 6.25 18.6005C6.25 15.5438 6.25 12.4872 6.25 9.43014L6.25 9.43053Z"
          fill={color}
        />
        <path
          d="M19.6293 14.0169C19.6293 12.5774 19.6293 11.1384 19.6293 9.69893C19.6293 9.10128 19.9554 8.77784 20.5566 8.77706C20.8214 8.77706 21.0867 8.77471 21.3515 8.77745C21.8781 8.78253 22.2195 9.12315 22.2199 9.64971C22.2211 12.5595 22.2211 15.4692 22.2199 18.379C22.2199 18.9064 21.8789 19.2482 21.3539 19.2536C21.0679 19.2564 20.7824 19.2564 20.4964 19.2536C19.9695 19.2485 19.6308 18.9099 19.63 18.3814C19.6281 16.9267 19.6293 15.4716 19.6293 14.0169Z"
          fill={color}
        />
        <path
          d="M18.8224 12.1938C18.8224 13.0305 18.8228 13.8668 18.8224 14.7035C18.822 15.2848 18.497 15.609 17.9153 15.6105C17.6349 15.6113 17.354 15.6141 17.0735 15.6098C16.5728 15.602 16.2333 15.2637 16.2325 14.7621C16.2298 13.0527 16.2298 11.343 16.2325 9.63359C16.2333 9.12617 16.5767 8.78516 17.0853 8.77813C17.3813 8.77383 17.6778 8.77383 17.9739 8.77813C18.4813 8.78555 18.82 9.12773 18.822 9.6375C18.8251 10.4898 18.8228 11.3418 18.8228 12.1941L18.8224 12.1938Z"
          fill={color}
        />
        <path
          d="M12.1435 12.1926C12.1435 13.0239 12.1318 13.8555 12.1478 14.6868C12.1584 15.2297 11.7888 15.645 11.2189 15.6133C10.9548 15.5985 10.6888 15.6125 10.424 15.6106C9.88374 15.6063 9.55288 15.2758 9.5521 14.7325C9.55054 13.0387 9.55015 11.3446 9.5521 9.65083C9.55288 9.11958 9.8896 8.78325 10.4173 8.77817C10.7033 8.77544 10.9888 8.77544 11.2748 8.77817C11.7994 8.78325 12.1408 9.12505 12.1423 9.65239C12.1451 10.4993 12.1431 11.3461 12.1431 12.1934L12.1435 12.1926Z"
          fill={color}
        />
        <path
          d="M12.8519 12.1844C12.8519 11.3582 12.8511 10.532 12.8519 9.70587C12.8527 9.09727 13.1761 8.77735 13.7878 8.77696C14.0375 8.77696 14.2867 8.77618 14.5363 8.77696C15.1101 8.77852 15.4453 9.11212 15.4457 9.68907C15.4468 11.2531 15.4468 12.8172 15.4457 14.3813C15.4457 14.5574 15.448 14.7356 15.4273 14.9098C15.3804 15.3016 15.064 15.5934 14.6699 15.6051C14.3222 15.6156 13.9734 15.6156 13.6257 15.6051C13.2136 15.5926 12.8746 15.2762 12.8644 14.8645C12.8464 14.1375 12.855 13.4098 12.8527 12.6824C12.8523 12.516 12.8527 12.35 12.8527 12.1836L12.8519 12.1844Z"
          fill={color}
        />
        <path
          d="M11.1733 18.3212C11.4366 18.3212 11.6999 18.3204 11.9632 18.3212C12.1378 18.322 12.2096 18.3876 12.2128 18.545C12.2159 18.7095 12.1389 18.7856 11.9636 18.786C11.6151 18.7868 11.2671 18.7876 10.9186 18.786C10.6757 18.7849 10.6315 18.7228 10.6124 18.4833C10.5874 18.1688 10.7198 17.9423 10.9557 17.7591C11.0991 17.6474 11.2491 17.5435 11.3866 17.4251C11.48 17.3446 11.5803 17.2567 11.6327 17.1501C11.6714 17.0716 11.6733 16.9302 11.6257 16.8665C11.5776 16.8024 11.4475 16.7755 11.3538 16.7771C11.2479 16.779 11.1913 16.8657 11.1729 16.9782C11.1428 17.1606 11.0257 17.2466 10.86 17.2231C10.7163 17.2032 10.6436 17.0888 10.6585 16.9067C10.6858 16.5767 10.9507 16.3423 11.3428 16.3017C11.8753 16.2462 12.2866 16.6356 12.1995 17.122C12.1542 17.3747 11.9979 17.5622 11.7991 17.7153C11.6428 17.8356 11.4784 17.9462 11.3261 18.0712C11.2553 18.129 11.2046 18.2103 11.1444 18.2813L11.1725 18.3216L11.1733 18.3212Z"
          fill={color}
        />
        <path
          d="M13.9854 18.321C14.2499 18.321 14.5147 18.3199 14.7792 18.321C14.9475 18.3218 15.0214 18.3949 15.0202 18.5535C15.019 18.7128 14.9456 18.7851 14.7776 18.7855C14.4241 18.7867 14.0706 18.7874 13.7175 18.7855C13.485 18.7843 13.4401 18.7187 13.4198 18.4894C13.3893 18.1535 13.5452 17.9242 13.7932 17.732C13.9698 17.5953 14.1471 17.4593 14.3167 17.3144C14.378 17.2621 14.444 17.1894 14.4589 17.116C14.4764 17.0296 14.4718 16.9042 14.4202 16.8476C14.3675 16.7898 14.2374 16.7613 14.1565 16.7816C14.0874 16.7992 14.0128 16.8953 13.9893 16.9718C13.9323 17.1578 13.8292 17.2499 13.6596 17.221C13.5143 17.1964 13.4448 17.0726 13.4694 16.8828C13.5151 16.532 13.8452 16.2792 14.2522 16.2984C14.5663 16.3132 14.8436 16.4253 14.9714 16.7324C15.1038 17.0507 14.9952 17.3425 14.7596 17.5703C14.5741 17.7496 14.3522 17.891 14.1522 18.0562C14.0772 18.1183 14.0186 18.2003 13.9526 18.2734C13.9636 18.289 13.9749 18.3046 13.9858 18.3203L13.9854 18.321Z"
          fill={color}
        />
        <path
          d="M15.749 18.3211C16.0138 18.3211 16.2786 18.32 16.5435 18.3215C16.6986 18.3223 16.7786 18.3997 16.7822 18.5446C16.7857 18.6965 16.7013 18.7852 16.5447 18.786C16.1861 18.7875 15.8275 18.7879 15.4685 18.786C15.2466 18.7844 15.1908 18.7137 15.1747 18.4985C15.1509 18.1821 15.2767 17.9457 15.5204 17.7594C15.6693 17.6457 15.8224 17.5375 15.9634 17.4153C16.0564 17.3344 16.156 17.2446 16.2052 17.1368C16.2411 17.0586 16.2322 16.9207 16.1825 16.8547C16.1372 16.7946 16.0103 16.7731 15.9204 16.7758C15.8122 16.7793 15.7564 16.868 15.7357 16.9766C15.7005 17.1621 15.5915 17.2457 15.4263 17.2227C15.2829 17.2028 15.2216 17.1106 15.2271 16.9227C15.2365 16.6036 15.4814 16.3657 15.8615 16.3071C16.3267 16.2352 16.7615 16.5344 16.7779 16.9504C16.7908 17.2735 16.6208 17.5125 16.3779 17.7039C16.2146 17.8325 16.0411 17.9481 15.8798 18.0789C15.813 18.1332 15.7661 18.2121 15.7103 18.2801C15.7232 18.2934 15.7357 18.3071 15.7486 18.3204L15.749 18.3211Z"
          fill={color}
        />
        <path
          d="M9.83344 16.9138C9.58461 17.0013 9.46039 16.9274 9.49086 16.7333C9.4932 16.7181 9.49476 16.702 9.50023 16.6876C9.54203 16.5794 10.0483 16.2782 10.1662 16.2962C10.2866 16.3149 10.3647 16.3759 10.3643 16.5122C10.3631 17.197 10.3651 17.8821 10.3627 18.5669C10.3623 18.7169 10.2631 18.7895 10.0854 18.7845C9.91351 18.7798 9.83461 18.7126 9.83422 18.5602C9.83266 18.0724 9.83344 17.5849 9.83344 17.097C9.83344 17.0407 9.83344 16.9841 9.83344 16.9138Z"
          fill={color}
        />
        <path
          d="M12.6416 16.9106C12.6018 16.9247 12.5779 16.9301 12.5565 16.9407C12.4744 16.9829 12.3776 16.9997 12.3432 16.904C12.3143 16.8239 12.3037 16.6774 12.3498 16.6407C12.5135 16.509 12.6967 16.3954 12.8877 16.3071C13.0303 16.2411 13.1701 16.3559 13.1709 16.5157C13.1733 17.1954 13.1729 17.8754 13.1709 18.5551C13.1705 18.7122 13.0822 18.7844 12.9049 18.7848C12.7268 18.7848 12.6424 18.7145 12.642 18.5563C12.6404 18.0684 12.6412 17.5805 12.6412 17.093C12.6412 17.0376 12.6412 16.9817 12.6412 16.9106H12.6416Z"
          fill={color}
        />
        <path
          d="M18.2477 16.9035C18.1208 16.9688 17.9993 17.0282 17.9254 16.8629C17.8708 16.7395 17.9215 16.6379 18.0844 16.5375C18.1946 16.4696 18.3032 16.3977 18.4169 16.3364C18.6184 16.2278 18.7848 16.3258 18.7852 16.5512C18.7864 17.2102 18.786 17.8692 18.7852 18.5282C18.7852 18.711 18.7028 18.7918 18.5149 18.7836C18.3426 18.7762 18.2477 18.7274 18.252 18.5219C18.2622 18.0395 18.2555 17.5571 18.2547 17.0742C18.2547 17.0242 18.2504 16.9746 18.2473 16.9039L18.2477 16.9035Z"
          fill={color}
        />
        <path
          d="M17.2039 16.9011C17.0668 16.9714 16.9535 17.0253 16.8805 16.8656C16.825 16.7449 16.8691 16.641 17.0191 16.5476C17.1293 16.4792 17.239 16.4105 17.3519 16.3468C17.5719 16.2226 17.734 16.3136 17.734 16.5597C17.7348 17.2132 17.7297 17.8667 17.7371 18.5199C17.739 18.6984 17.6879 18.7718 17.4758 18.7824C17.2828 18.7921 17.2043 18.7093 17.2039 18.5128C17.2031 17.9843 17.2039 17.4558 17.2039 16.9003V16.9011Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9270_61089">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(6.25 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default VinIcon;
