import React from 'react';
import Modal from '@components/widgets/Modal';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateLenderModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal title="Add Lender" size="md" open={open} onClose={onClose}>
      <div className="mb-4">
        <p className="text-sm font-medium text-grey-700 mb-1.5">Lender</p>
        <Select placeholder="Select Lender">
          <Option>Lender 1</Option>
          <Option>Lender 2</Option>
          <Option>Lender 3</Option>
        </Select>
      </div>

      <div className="mb-4">
        <p className="text-sm font-medium text-grey-700 mb-1.5">
          Lender Ref Number
        </p>
        <Input placeholder="Lender Ref Number" />
      </div>

      <div className="flex justify-end pt-4">
        <Button className="font-semibold bg-primary w-auto capitalize">
          Submit Lender Request
        </Button>
      </div>
    </Modal>
  );
};

export default CreateLenderModal;
