import {
  InspectionStatusEnum,
  CreateInspectionStepEnum,
  CreateInvoiceStepEnum,
  InspectionCardTypeEnum,
  InspectionDocumentStatusEnum,
  InspectionSecurityStatusEnum
} from '@types';
import { ReactComponent as UserCarIcon } from '@assets/icons/user_car_icon.svg';
import { ReactComponent as UserMoneyIcon } from '@assets/icons/user-money.svg';
import { ReactComponent as VehicleIcon } from '@assets/icons/vehicle.svg';

import { ReactComponent as ReviewIcon } from '@assets/icons/review.svg';
import { ReactComponent as SettingIcon } from '@assets/icons/settings_icon.svg';
import { ReactComponent as DocumentIcon } from '@assets/icons/document_icon.svg';
import { StatusChipVariant } from '@components/widgets/StatusChip';
import { format } from 'date-fns';
import { faker, simpleFaker } from '@faker-js/faker';

const formattedDate = (date: any) => format(date, 'dd MMM yyyy');
export type INSPECTION_CARD_TYPES =
  | 'vehicleInfo'
  | 'buyerCard'
  | 'sellerCard'
  | 'lenderCard';
export const INSPECTION_CARD_TYPE: Record<
  InspectionCardTypeEnum,
  INSPECTION_CARD_TYPES
> = {
  [InspectionCardTypeEnum.VehicleInfo]: 'vehicleInfo',
  [InspectionCardTypeEnum.BuyerCard]: 'buyerCard',
  [InspectionCardTypeEnum.SellerCard]: 'sellerCard',
  [InspectionCardTypeEnum.LenderCard]: 'lenderCard'
};

export const INSPECTION_STATUS_VARIANT: Record<
  InspectionStatusEnum,
  StatusChipVariant
> = {
  [InspectionStatusEnum.Completed]: 'success1',
  [InspectionStatusEnum.Submitted]: 'text',
  [InspectionStatusEnum.InProgress]: 'info2',
  [InspectionStatusEnum.Initiated]: 'info2',
  [InspectionStatusEnum.All]: 'text',
  [InspectionStatusEnum.UpdateRequired]: 'info2'
};

export const INSPECTION_STATUS_TEXT: Record<InspectionStatusEnum, any> = {
  [InspectionStatusEnum.Completed]: 'CLOSED',
  [InspectionStatusEnum.Submitted]: 'SUBMITTED',
  [InspectionStatusEnum.InProgress]: 'IN PROGRESS',
  [InspectionStatusEnum.Initiated]: 'INITIATED',
  [InspectionStatusEnum.All]: 'ALL',
  [InspectionStatusEnum.UpdateRequired]: 'RESUPPLY DOCS PENDING'
};
export const INSPECTION_DOCUMENT_STATUS_VARIANT: Record<
  InspectionDocumentStatusEnum,
  StatusChipVariant
> = {
  [InspectionDocumentStatusEnum.Completed]: 'success1',
  [InspectionDocumentStatusEnum.Incomplete]: 'text',
  [InspectionSecurityStatusEnum.notFound]: 'info_light',
  [InspectionSecurityStatusEnum.notRequested]: 'info_light',
  [InspectionSecurityStatusEnum.notPerformed]: 'info_light',
  [InspectionSecurityStatusEnum.warning]: 'warning_light',
  [InspectionSecurityStatusEnum.registrations]: 'warning_light',
  [InspectionSecurityStatusEnum.registered]: 'success_light',
  [InspectionSecurityStatusEnum.noRegister]: 'info_light',
  [InspectionSecurityStatusEnum.inprogress]: 'info2',
  [InspectionSecurityStatusEnum.notProvided]: 'error_light',
  [InspectionSecurityStatusEnum.ResupplyPending]: 'error_light'
};
export const INSPECTION_SECURITY_CHECK_LABEL_STATUS = {
  [InspectionSecurityStatusEnum.notPerformed]: 'Not Performed',
  [InspectionSecurityStatusEnum.notFound]: 'Not Found',
  [InspectionSecurityStatusEnum.notProvided]: 'Not Provided',
  [InspectionSecurityStatusEnum.warning]: 'Warning',
  [InspectionSecurityStatusEnum.pass]: 'Pass',
  [InspectionSecurityStatusEnum.failed]: 'Failed',
  [InspectionSecurityStatusEnum.registrations]: '{0} Registrations Found',
  [InspectionSecurityStatusEnum.registered]: 'Registered',
  [InspectionSecurityStatusEnum.noRegister]: 'Unregistered',
  [InspectionSecurityStatusEnum.reportFound]: 'Report Found',
  [InspectionSecurityStatusEnum.noRecord]: 'Not Found',
  [InspectionSecurityStatusEnum.noReport]: 'No Report',
  [InspectionSecurityStatusEnum.match]: 'Matches NEVDIS',
  [InspectionSecurityStatusEnum.discrepancyFound]: 'Discrepancy found',
  [InspectionSecurityStatusEnum.authenticityWarning]: 'Warning',
  [InspectionSecurityStatusEnum.ocrFailed]: 'Warning',
  [InspectionSecurityStatusEnum.inprogress]: 'In Progress'
};
export const INSPECTION_SECURITY_CHECK_VARIANT: Record<
  InspectionSecurityStatusEnum,
  StatusChipVariant
> = {
  [InspectionSecurityStatusEnum.notFound]: 'info_light',
  [InspectionSecurityStatusEnum.notRequested]: 'info_light',
  [InspectionSecurityStatusEnum.notPerformed]: 'info_light',
  [InspectionSecurityStatusEnum.notAvailable]: 'not_available',
  [InspectionSecurityStatusEnum.warning]: 'warning_light',
  [InspectionSecurityStatusEnum.expired]: 'warning_light',
  [InspectionSecurityStatusEnum.pass]: 'success_light',
  [InspectionSecurityStatusEnum.failed]: 'error_light',
  [InspectionSecurityStatusEnum.registrations]: 'warning_light',
  [InspectionSecurityStatusEnum.registered]: 'success_light',
  [InspectionSecurityStatusEnum.noRegister]: 'error_light',
  [InspectionSecurityStatusEnum.reportFound]: 'error_light',
  [InspectionSecurityStatusEnum.noRecord]: 'info_light',
  [InspectionSecurityStatusEnum.notProvided]: 'error_light',
  [InspectionSecurityStatusEnum.noReport]: 'success_light',
  [InspectionSecurityStatusEnum.match]: 'success_light',
  [InspectionSecurityStatusEnum.discrepancyFound]: 'error_light',
  [InspectionSecurityStatusEnum.error]: 'info_light',
  [InspectionSecurityStatusEnum.authenticityWarning]: 'warning_light',
  [InspectionSecurityStatusEnum.ocrFailed]: 'warning_light',
  [InspectionSecurityStatusEnum.inprogress]: 'info_light',
  [InspectionSecurityStatusEnum.ResupplyPending]: 'error_light'
};
export const vehicleInformationMock =
  process.env.NODE_ENV === 'development'
    ? {
        imageUrl: faker?.image.url(),
        nvic: '0D1923',
        make: 'VOLKSWAGEN',
        model: 'T-ROC',
        year: 2023,
        variant: 'R',
        series: 'D1 MY23 UPDATE',
        guideModel: 'T-ROC R D1 MY23 UPDATE',
        segment: 'SUV SMALL',
        bodystyle: 'WAGON',
        doors: 4,
        seats: 5,
        drivetrain: 'AWD',
        engineSize: '2.0L',
        engineType: 'TURBO DIRECT F/INJ',
        cylinders: 4,
        gears: 7,
        transmission: 'AUTO DIR SHIFT SPORT',
        transmissionCode: 'A',
        fuelType: 'PREMIUM UNLEADED PETROL',
        fuelCode: 'P',
        releaseDate: '2022-12-01',
        priceNew: 61990,
        serviceMonths: 12,
        serviceKms: 15,
        warrantyMonths: 60,
        warrantyKms: 999,
        vehicleMass: 1519,
        towingMass: 1700
      }
    : {};
export const VEHICLE_ITEMS_MOCK =
  process.env.NODE_ENV === 'development'
    ? {
        rav: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          type: 'RAV_COMPLIANCE'
        },
        ppsr: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.registrations
          ]),
          amount: faker?.number.int({ min: 1, max: 10 }),
          type: 'PPSR_ENCUMBRANCE'
        },
        registration: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.registered,
            InspectionSecurityStatusEnum.noRegister
          ]),
          type: 'NEVDIS_REGISTRATION'
        },
        stolen: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.noRecord,
            InspectionSecurityStatusEnum.reportFound
          ]),
          type: 'NEVDIS_STOLEN'
        },
        writtenOff: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.noRecord,
            InspectionSecurityStatusEnum.reportFound
          ]),
          amount: faker?.number.int({ min: 1, max: 10 }),
          type: 'NEVDIS_WRITTEN_OFF'
        },
        vin: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        plateNumber: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        odometer: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        assetFront: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        assetBack: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        assetDriverSide: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        assetPassengerSide: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        }
      }
    : {};
export const VEHICLE_DATA_MOCK = {
  inspectionChecks: VEHICLE_ITEMS_MOCK,
  details: vehicleInformationMock
};
export const DRIVER_LICENSE_ITEMS_MOCK =
  process.env.NODE_ENV === 'development'
    ? {
        DRIVER_LICENCE_FRONT: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        DRIVER_LICENCE_BACK: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        FACE: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        licenseExpiry: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          dateOfExpiry: '2026-03-12',
          type: 'LICENSE_EXPIRY'
        },
        licenseEdits: {
          type: 'LICENSE_EXPIRY',
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.warning,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          addressLine1: {
            ocrConfidence: 0.996,
            ocrValue: faker?.location.streetAddress(),
            userEditValue: faker?.location.streetAddress()
          },
          addressLine2: {
            ocrConfidence: 0.996,
            ocrValue: faker?.location.streetAddress(),
            userEditValue: faker?.location.streetAddress()
          },
          cardNumber: {
            ocrConfidence: 0.993,
            ocrValue: faker?.number.int(),
            userEditValue: faker?.number.int()
          },
          dateOfBirth: {
            ocrConfidence: 0.991,
            ocrValue: formattedDate(
              faker?.date.between({
                from: '1970-01-01T00:00:00.000Z',
                to: '2010-01-01T00:00:00.000Z'
              })
            ),
            userEditValue: formattedDate(
              faker?.date.between({
                from: '1970-01-01T00:00:00.000Z',
                to: '2010-01-01T00:00:00.000Z'
              })
            )
          },
          firstName: {
            ocrConfidence: 0.996,
            ocrValue: faker?.person.firstName(),
            userEditValue: faker?.person.firstName()
          },
          lastName: {
            ocrConfidence: 0.996,
            ocrValue: faker?.person.lastName(),
            userEditValue: faker?.person.lastName()
          },
          middleName: {
            ocrConfidence: 0.996,
            ocrValue: faker?.person.middleName(),
            userEditValue: faker?.person.middleName()
          },
          licenseNumber: {
            ocrConfidence: 0.994,
            ocrValue: faker?.number.int(),
            userEditValue: faker?.number.int()
          },
          licenseType: {
            ocrConfidence: 0.995,
            ocrValue: 'C',
            userEditValue: faker?.string.alpha({
              length: 1,
              casing: 'upper',
              exclude: ['A']
            })
          },
          state: {
            ocrConfidence: 1.0,
            ocrValue: faker?.helpers.arrayElement([
              'ACT',
              'NSW',
              'NT',
              'QLD',
              'SA',
              'TAS',
              'VIC',
              'WA'
            ]),
            userEditValue: faker?.helpers.arrayElement([
              'ACT',
              'NSW',
              'NT',
              'QLD',
              'SA',
              'TAS',
              'VIC',
              'WA'
            ])
          }
        },
        licenseFaceMatch: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.warning,
            InspectionSecurityStatusEnum.failed
          ]),
          score: faker?.number.float({
            min: 0,
            max: 1,
            multipleOf: 0.0000000001
          }),
          type: 'LICENSE_FACE_MATCH'
        },
        licenseLiveness: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          score: faker?.number.float({
            min: 0,
            max: 1,
            multipleOf: 0.0000000001
          }),
          type: 'LICENSE_LIVENESS'
        },
        licenseAuthenticity: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          type: 'LICENSE_AUTHENTICITY',
          score: faker?.number.float({
            min: 0,
            max: 1,
            multipleOf: 0.0000000001
          }),
          subChecks: [
            {
              name: 'Text Features',
              score: faker?.number.float({
                min: 0,
                max: 1,
                multipleOf: 0.0000000001
              }),
              status: simpleFaker?.helpers.arrayElement([
                InspectionSecurityStatusEnum.notPerformed,
                InspectionSecurityStatusEnum.pass,
                InspectionSecurityStatusEnum.failed
              ]),
              type: 'TEXT_FEATURES'
            },
            {
              name: 'Black And White',
              score: faker?.number.float({
                min: 0,
                max: 1,
                multipleOf: 0.0000000001
              }),
              status: simpleFaker?.helpers.arrayElement([
                InspectionSecurityStatusEnum.notPerformed,
                InspectionSecurityStatusEnum.pass,
                InspectionSecurityStatusEnum.failed
              ]),
              type: 'BLACK_WHITE'
            },
            {
              name: 'Expiry',
              score: faker?.number.float({
                min: 0,
                max: 1,
                multipleOf: 0.0000000001
              }),
              status: simpleFaker?.helpers.arrayElement([
                InspectionSecurityStatusEnum.notPerformed,
                InspectionSecurityStatusEnum.pass,
                InspectionSecurityStatusEnum.failed
              ]),
              type: 'EXPIRY'
            },
            {
              name: 'Photo Substitution',
              score: faker?.number.float({
                min: 0,
                max: 1,
                multipleOf: 0.0000000001
              }),
              status: simpleFaker?.helpers.arrayElement([
                InspectionSecurityStatusEnum.notPerformed,
                InspectionSecurityStatusEnum.pass,
                InspectionSecurityStatusEnum.failed
              ]),
              type: 'PHOTO_SUBSTITUTION'
            },
            {
              name: 'Visual Integrity',
              score: faker?.number.float({
                min: 0,
                max: 1,
                multipleOf: 0.0000000001
              }),
              status: simpleFaker?.helpers.arrayElement([
                InspectionSecurityStatusEnum.notPerformed,
                InspectionSecurityStatusEnum.pass,
                InspectionSecurityStatusEnum.failed
              ]),
              type: 'VISUAL_INTEGRITY'
            }
          ]
        },
        rav: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          type: 'RAV_COMPLIANCE'
        },
        stolen: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.notRequested,
            InspectionSecurityStatusEnum.noRecord,
            InspectionSecurityStatusEnum.reportFound
          ]),
          type: 'NEVDIS_STOLEN'
        },
        writtenOff: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.notRequested,
            InspectionSecurityStatusEnum.noRecord,
            InspectionSecurityStatusEnum.reportFound
          ]),
          type: 'NEVDIS_WRITTEN_OFF'
        },
        registration: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.registered,
            InspectionSecurityStatusEnum.noRegister
          ]),
          type: 'NEVDIS_REGISTRATION',
          vin: faker?.vehicle.vin(),
          plate: faker?.vehicle.vrm(),
          state: faker?.helpers.arrayElement([
            'ACT',
            'NSW',
            'NT',
            'QLD',
            'SA',
            'TAS',
            'VIC',
            'WA'
          ]),
          regoStatus: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.registered,
            InspectionSecurityStatusEnum.noRegister
          ]),
          expiry: formattedDate(
            faker?.date.between({
              from: '1970-01-01T00:00:00.000Z',
              to: '2010-01-01T00:00:00.000Z'
            })
          )
        },
        nevdisVehicleDetails: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.reportFound,
            InspectionSecurityStatusEnum.noRecord
          ]),
          type: 'NEVDIS_VEHICLE_DETAILS',
          make: 'Mitsubishi',
          model: 'Lancer',
          colour: 'WHITE',
          bodyType: 'CAR/SEDAN',
          vehicleType: 'CAR / SMALL PASSENGER VEHICLE',
          engineNumber: '4G93NQ6798',
          complianceMonth: '2002-05',
          buildYear: 2002,
          nvic: '0D1923',
          year: 2023,
          variant: 'R',
          series: 'D1 MY23 UPDATE',
          guideModel: 'T-ROC R D1 MY23 UPDATE',
          segment: 'SUV SMALL',
          bodystyle: 'WAGON',
          doors: 4,
          seats: 5,
          drivetrain: 'AWD',
          engineSize: '2.0L',
          engineType: 'TURBO DIRECT F/INJ',
          cylinders: 4,
          gears: 7,
          transmission: 'AUTO DIR SHIFT SPORT',
          transmissionCode: 'A',
          fuelType: 'PREMIUM UNLEADED PETROL',
          fuelCode: 'P',
          releaseDate: '2022-12-01',
          priceNew: 61990,
          serviceMonths: 12,
          serviceKms: 15,
          warrantyMonths: 60,
          warrantyKms: 999,
          vehicleMass: 1519,
          towingMass: 1700
        },
        ppsr: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          type: 'PPSR_ENCUMBRANCE'
        },
        glassesVehicleData: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.failed
          ]),
          type: 'VEHICLE_DATA'
        },
        ASSET_FRONT: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        ASSET_BACK: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        BANK_STATEMENTS: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ]),
          bsb: faker?.finance.bic(),
          accountNumber: faker?.finance.accountNumber(),
          accountName: faker?.finance.accountName()
        },
        ROADWORTHY_CERT: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ]),
          location: faker?.location.streetAddress(),
          timestamp: formattedDate(
            faker?.date.between({
              from: '1970-01-01T00:00:00.000Z',
              to: '2010-01-01T00:00:00.000Z'
            })
          )
        },
        VIN: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        REGO_PLATE: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        ODOMETER: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        ASSET_LEFT: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        },
        ASSET_RIGHT: {
          status: simpleFaker?.helpers.arrayElement([
            InspectionDocumentStatusEnum.Completed,
            InspectionDocumentStatusEnum.Incomplete,
            InspectionDocumentStatusEnum.notFound
          ])
        }
      }
    : {};
export const VEHICLE_PHOTO_LIST = [
  'ASSET_FRONT',
  'ASSET_RIGHT',
  'ASSET_LEFT',
  'ASSET_BACK'
];
export const INSPECTION_TABLE_ROWS: Record<string, any>[] =
  process.env.NODE_ENV === 'development'
    ? [
        {
          inspectionId: '1d25c75a-a1f2-4a3c-81b8-be85ed1a648a',
          friendlyId: 'AU-0001',
          status: InspectionStatusEnum.Completed,
          vehicleInfo:
            faker?.date.past().getFullYear() +
            ' ' +
            faker?.vehicle.manufacturer() +
            ' ' +
            faker?.vehicle.model() +
            ' ' +
            faker?.vehicle.type(),
          renderRef: 'A-' + faker?.string.numeric(6),
          buyer: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            createdTime: faker?.date.recent(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: '01'
          },
          createdTime: faker?.date.recent().toISOString(),
          documentTypes: [
            'SELLER_ID',
            'DRIVER_LICENCE_FRONT',
            'DRIVER_LICENCE_BACK',
            'FACE',
            'ASSET_BACK',
            'ASSET_FRONT',
            'ASSET_LEFT',
            'ASSET_RIGHT',
            'VIN',
            'REGO_PLATE',
            'ODOMETER',
            'REGO_PAPERS',
            'BANK_STATEMENTS',
            'PPSR',
            'NEVDIS',
            'RAV',
            'GLASSES',
            'ROADWORTHY_CERT',
            'SAFETY_CERT'
          ],
          seller: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: '02'
          },
          lender: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            userId: '03'
          },
          lenderMail: faker?.internet.email(),
          lenderPhone: faker?.phone.number(),
          type: 'Car',
          progress: 100,
          inspectionChecks: DRIVER_LICENSE_ITEMS_MOCK,
          driverLicense: {},
          vehicle: {}
        },
        {
          inspectionId: '5ebf510b-5bcb-40b9-9d70-e73511e054e3',
          friendlyId: 'AU-0002',
          status: InspectionStatusEnum.Submitted,
          vehicleInfo:
            faker?.date.recent().getFullYear() +
            ' ' +
            faker?.vehicle.manufacturer() +
            ' ' +
            faker?.vehicle.model() +
            ' ' +
            faker?.vehicle.type(),
          renderRef: 'A-' + faker?.string.numeric(6),
          documentTypes: [
            'SELLER_ID',
            'DRIVER_LICENCE_FRONT',
            'DRIVER_LICENCE_BACK',
            'FACE',
            'ASSET_BACK',
            'ASSET_FRONT',
            'ASSET_LEFT',
            'ASSET_RIGHT',
            'VIN',
            'REGO_PLATE',
            'ODOMETER',
            'REGO_PAPERS',
            'BANK_STATEMENTS',
            'PPSR',
            'NEVDIS',
            'RAV',
            'GLASSES',
            'ROADWORTHY_CERT',
            'SAFETY_CERT'
          ],
          buyer: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            createdTime: faker?.date.recent(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          createdTime: faker?.date.recent().toISOString(),
          seller: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          lender: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            userId: '04'
          },
          lenderMail: faker?.internet.email(),
          lenderPhone: faker?.phone.number(),
          type: 'Motor cycle',
          progress: 80,
          driverLicense: {},
          inspectionChecks: DRIVER_LICENSE_ITEMS_MOCK,
          vehicle: {}
        },
        {
          inspectionId: '6bc78c85-f510-4ff7-9268-4605497b9474',
          friendlyId: 'AU-0003',
          status: InspectionStatusEnum.Initiated,
          vehicleInfo:
            faker?.date.recent().getFullYear() +
            ' ' +
            faker?.vehicle.manufacturer() +
            ' ' +
            faker?.vehicle.model() +
            ' ' +
            faker?.vehicle.type(),
          renderRef: 'A-' + faker?.string.numeric(6),
          buyer: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            createdTime: faker?.date.recent(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          createdTime: faker?.date.recent().toISOString(),
          seller: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          lender: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            userId: '05'
          },
          lenderMail: faker?.internet.email(),
          lenderPhone: faker?.phone.number(),
          type: 'Campervan',
          progress: 0,
          inspectionChecks: DRIVER_LICENSE_ITEMS_MOCK,
          driverLicense: {},
          vehicle: {},
          regoPaper: {},
          bank: {},
          cert: {}
        },
        {
          inspectionId: 'd0f35279-8d04-4159-82f1-4247c07b06ab',
          friendlyId: 'AU-0004',
          status: InspectionStatusEnum.Completed,
          documentTypes: [
            'SELLER_ID',
            'DRIVER_LICENCE_FRONT',
            'DRIVER_LICENCE_BACK',
            'FACE',
            'ASSET_BACK',
            'ASSET_FRONT',
            'ASSET_LEFT',
            'ASSET_RIGHT',
            'VIN',
            'REGO_PLATE',
            'ODOMETER',
            'REGO_PAPERS',
            'BANK_STATEMENTS',
            'PPSR',
            'NEVDIS',
            'RAV',
            'GLASSES',
            'ROADWORTHY_CERT',
            'SAFETY_CERT'
          ],
          vehicleInfo:
            faker?.date.recent().getFullYear() +
            ' ' +
            faker?.vehicle.manufacturer() +
            ' ' +
            faker?.vehicle.model() +
            ' ' +
            faker?.vehicle.type(),
          renderRef: 'A-' + faker?.string.numeric(6),
          buyer: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            createdTime: faker?.date.recent(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          createdTime: faker?.date.recent().toISOString(),
          seller: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          lender: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            userId: '06'
          },
          lenderMail: faker?.internet.email(),
          lenderPhone: faker?.phone.number(),
          type: 'Car',
          progress: 100,
          inspectionChecks: DRIVER_LICENSE_ITEMS_MOCK
        },
        {
          inspectionId: '7d9bbf8f-d697-479c-aeda-b1976ea21b58',
          friendlyId: 'AU-0005',
          status: InspectionStatusEnum.Submitted,
          vehicleInfo:
            faker?.date.recent().getFullYear() +
            ' ' +
            faker?.vehicle.manufacturer() +
            ' ' +
            faker?.vehicle.model() +
            ' ' +
            faker?.vehicle.type(),
          renderRef: 'A-' + faker?.string.numeric(6),
          documentTypes: [
            'SELLER_ID',
            'DRIVER_LICENCE_FRONT',
            'DRIVER_LICENCE_BACK',
            'FACE',
            'ASSET_BACK',
            'ASSET_FRONT',
            'ASSET_LEFT',
            'ASSET_RIGHT',
            'VIN',
            'REGO_PLATE',
            'ODOMETER',
            'REGO_PAPERS',
            'BANK_STATEMENTS',
            'PPSR',
            'NEVDIS',
            'RAV',
            'GLASSES',
            'ROADWORTHY_CERT',
            'SAFETY_CERT'
          ],
          buyer: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            createdTime: faker?.date.recent(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          createdTime: faker?.date.recent().toISOString(),
          seller: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          lender: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            userId: '07'
          },
          lenderMail: faker?.internet.email(),
          lenderPhone: faker?.phone.number(),
          type: 'Motor cycle',
          progress: 40,
          inspectionChecks: DRIVER_LICENSE_ITEMS_MOCK
        },
        {
          inspectionId: 'b8a6bb4d-fe82-4834-b84c-6b90366aa185',
          friendlyId: 'AU-0006',
          status: InspectionStatusEnum.Initiated,
          documentTypes: [
            'SELLER_ID',
            'DRIVER_LICENCE_FRONT',
            'DRIVER_LICENCE_BACK',
            'FACE',
            'ASSET_BACK',
            'ASSET_FRONT',
            'ASSET_LEFT',
            'ASSET_RIGHT',
            'VIN',
            'REGO_PLATE',
            'ODOMETER',
            'REGO_PAPERS',
            'BANK_STATEMENTS',
            'PPSR',
            'NEVDIS',
            'RAV',
            'GLASSES',
            'ROADWORTHY_CERT',
            'SAFETY_CERT'
          ],
          vehicleInfo:
            faker?.date.recent().getFullYear() +
            ' ' +
            faker?.vehicle.manufacturer() +
            ' ' +
            faker?.vehicle.model() +
            ' ' +
            faker?.vehicle.type(),
          renderRef: 'A-' + faker?.string.numeric(6),
          buyer: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            createdTime: faker?.date.recent(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          createdTime: faker?.date.recent().toISOString(),
          seller: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            mail: faker?.internet.email(),
            phone: faker?.phone.number(),
            userId: ''
          },
          lender: {
            lastName: faker?.person.lastName(),
            firstName: faker?.person.firstName(),
            userId: '08'
          },
          lenderMail: faker?.internet.email(),
          lenderPhone: faker?.phone.number(),
          type: 'Campervan',
          progress: 0,
          inspectionChecks: DRIVER_LICENSE_ITEMS_MOCK
        }
      ]
    : [];

export const LANDING_DATA =
  process.env.NODE_ENV === 'development'
    ? [
        {
          date: '19/01/2024 1:31PM',
          vin: 'JTMRW3FV20D124483',
          registration: 'YPS08T',
          state: 'ACT',
          vehicle: '2020 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Initiated
        },
        {
          date: '20/01/2024 2:31PM',
          vin: 'JTMRW3FV20D124484',
          registration: 'YPS08U',
          state: 'NSW',
          vehicle: '2021 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Submitted
        },
        {
          date: '21/01/2024 3:31PM',
          vin: 'JTMRW3FV20D124485',
          registration: 'YPS08V',
          state: 'VIC',
          vehicle: '2022 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Completed
        },
        {
          date: '22/01/2024 4:31PM',
          vin: 'JTMRW3FV20D124486',
          registration: 'YPS08W',
          state: 'QLD',
          vehicle: '2023 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Initiated
        },
        {
          date: '23/01/2024 5:31PM',
          vin: 'JTMRW3FV20D124487',
          registration: 'YPS08X',
          state: 'SA',
          vehicle: '2024 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Submitted
        },
        {
          date: '24/01/2024 6:31PM',
          vin: 'JTMRW3FV20D124488',
          registration: 'YPS08Y',
          state: 'WA',
          Vehicle: '2025 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Completed
        },
        {
          date: '25/01/2024 7:31PM',
          vin: 'JTMRW3FV20D124489',
          registration: 'YPS08Z',
          state: 'TAS',
          vehicle: '2026 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Initiated
        },
        {
          date: '26/01/2024 8:31PM',
          vin: 'JTMRW3FV20D124490',
          registration: 'YPS08A',
          state: 'NT',
          vehicle: '2027 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Submitted
        },
        {
          date: '27/01/2024 9:31PM',
          vin: 'JTMRW3FV20D124491',
          registration: 'YPS08B',
          state: 'ACT',
          vehicle: '2028 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Completed
        },
        {
          date: '28/01/2024 10:31PM',
          vin: 'JTMRW3FV20D124492',
          registration: 'YPS08C',
          state: 'NSW',
          vehicle: '2029 TOYOTA RAV4 EDGE',
          status: InspectionStatusEnum.Initiated
        }
      ]
    : [];

export const INSPECTION_ONBOARDING_STEP = [
  {
    label: 'Setup',
    value: CreateInspectionStepEnum.Lender,
    icon: SettingIcon,
    key: 'lender.isDone'
  },
  {
    label: 'Documents',
    value: CreateInspectionStepEnum.Documents,
    icon: DocumentIcon,
    key: 'documentValidation'
  },
  {
    label: 'Contact details',
    value: CreateInspectionStepEnum.Seller,
    icon: UserCarIcon,
    key: 'seller.isDone'
  },
  {
    label: 'Review',
    value: CreateInspectionStepEnum.Review,
    icon: ReviewIcon,
    key: 'documentValidation'
  }
];
export const INVOICE_TEMPLATE_STEP = [
  {
    label: 'Buyer',
    value: 'Buyer',
    icon: UserMoneyIcon,
    key: CreateInvoiceStepEnum.Buyer
  },
  {
    label: 'Seller',
    value: 'Seller',
    icon: UserCarIcon,
    key: CreateInvoiceStepEnum.Seller
  },
  {
    label: 'Vehicle',
    value: 'Vehicle',
    icon: VehicleIcon,
    key: CreateInvoiceStepEnum.Vehicle
  }
];
export const INVOICE_TEMPLATE_STEP_ORDER = [
  CreateInvoiceStepEnum.Buyer,
  CreateInvoiceStepEnum.Seller,
  CreateInvoiceStepEnum.Vehicle
];
export const INSPECTION_ONBOARDING_STEP_ORDER = [
  CreateInspectionStepEnum.Lender,
  CreateInspectionStepEnum.Documents,
  CreateInspectionStepEnum.Seller,
  CreateInspectionStepEnum.Review
];

export const ACTIVITY_HISTORY_STEPS = [
  {
    label: 'Inspection created',
    date: '18/08/23 1:30pm',
    status: true
  },
  {
    label: 'Inspection started',
    date: '18/08/23 1:30pm',
    status: true
  },
  {
    label: 'Inspection submitted',
    date: '18/08/23 1:30pm',
    status: true
  },
  {
    label: 'Inspection processed',
    date: '18/08/23 1:30pm',
    status: true
  },
  {
    label: 'Completed',
    status: false
  }
];

export const DETAIL_VIEW_ITEMS = [
  {
    label: "Driver's Licence Details",
    shortLabel: "Driver's Licence",
    value: '1',
    success: false,
    keyPath: 'driverLicense'
  },
  {
    label: 'Vehicle Details',
    shortLabel: 'Vehicle Details',
    value: '2',
    success: false,
    keyPath: 'vehicle'
  },
  {
    label: 'Registration Cert.',
    shortLabel: 'Registration Cert.',
    value: '3',
    success: true,
    keyPath: 'regoPaper'
  },
  {
    label: 'Bank Statement',
    shortLabel: 'Bank Statement',
    value: '4',
    success: true,
    keyPath: 'bank'
  },
  {
    label: 'Roadworthy Cert.',
    shortLabel: 'Roadworthy Cert.',
    shortLabel2: 'Safety Cert.',
    value: '5',
    success: true,
    keyPath: 'cert'
  }
];
export const DETAILS_VIEW_DRIVER_LICENSE = [
  {
    key: 'firstName',
    displayName: 'First Name',
    keyPath: 'firstName'
  },
  {
    key: 'middleName',
    displayName: 'Middle Name',
    keyPath: 'middleName'
  },
  {
    key: 'lastName',
    displayName: 'Last Name',
    keyPath: 'lastName'
  },
  {
    key: 'dateOfBirth',
    displayName: 'Date of birth',
    keyPath: 'dateOfBirth'
  },
  {
    key: 'licenseNumber',
    displayName: 'Licence Number',
    keyPath: 'licenseNumber'
  },
  {
    key: 'cardNumber',
    displayName: 'Card Number',
    keyPath: 'cardNumber'
  },
  {
    key: 'licenseType',
    displayName: 'Licence Type',
    keyPath: 'licenseType'
  },
  {
    key: 'dateOfExpiry',
    displayName: 'Expiry Date',
    keyPath: 'dateOfExpiry'
  },
  {
    key: 'addressLine1',
    displayName: 'Address1',
    keyPath: 'addressLine1'
  },
  {
    key: 'addressLine2',
    displayName: 'Address2',
    keyPath: 'addressLine2'
  }
];
export const DETAILS_VIEW_REGISTRATION = [
  {
    key: 'familyName',
    displayName: 'Family Name',
    keyPath: 'familyName'
  },
  {
    key: 'givenName',
    displayName: 'Given Name',
    keyPath: 'givenName'
  },

  {
    key: 'middleName',
    displayName: 'Middle Name',
    keyPath: 'middleName'
  },
  {
    key: 'addressLine1',
    displayName: 'Address Line 1',
    keyPath: 'addressLine1'
  },
  {
    key: 'addressLine2',
    displayName: 'Address Line 2',
    keyPath: 'addressLine2'
  },
  {
    key: 'vin',
    displayName: 'VIN',
    keyPath: 'vin'
  },
  {
    key: 'rego',
    displayName: 'Number Plate',
    keyPath: 'rego'
  },
  {
    key: 'dateOfExpiry',
    displayName: 'Registration expiry date',
    keyPath: 'dateOfExpiry'
  },
  {
    key: 'plate',
    displayName: 'Number Plate',
    keyPath: 'plate'
  },
  {
    key: 'expiry',
    displayName: 'Registration expiry date',
    keyPath: 'expiry'
  },
  {
    key: 'state',
    displayName: 'Registration State',
    keyPath: 'state'
  },
  {
    key: 'regoStatus',
    displayName: 'Registration Status',
    keyPath: 'regoStatus'
  }
];
export const DETAILS_VIEW_BANK = [
  // {
  //   key: 'familyName',
  //   displayName: 'Family Name',
  //   keyPath: 'familyName'
  // },
  // {
  //   key: 'givenName',
  //   displayName: 'Given Name',
  //   keyPath: 'givenName'
  // },
  // {
  //   key: 'middleName',
  //   displayName: 'Middle Name',
  //   keyPath: 'middleName'
  // },
  // {
  //   key: 'bankName',
  //   displayName: 'Bank Name',
  //   keyPath: 'bankName'
  // },
  // {
  //   key: 'statementDate',
  //   displayName: 'Statement Date',
  //   keyPath: 'statementDate'
  // },
  {
    key: 'bsb',
    displayName: 'BSB',
    keyPath: 'bsb'
  },
  {
    key: 'accountNumber',
    displayName: 'Account number',
    keyPath: 'accountNumber'
  },
  {
    key: 'addressLine1',
    displayName: 'Address Line 1',
    keyPath: 'addressLine1'
  },
  {
    key: 'addressLine2',
    displayName: 'Address Line 2',
    keyPath: 'addressLine2'
  }
];
export const WRITTEN_OFF_VIEW = [
  {
    key: 'damageCodes',
    displayName: 'Damage Codes',
    keyPath: 'damageCodes'
  },
  {
    key: 'incidentCode',
    displayName: 'Incident Code',
    keyPath: 'incidentCode'
  },
  {
    key: 'incidentRecordedDate',
    displayName: 'Incident Recorded Date',
    keyPath: 'incidentRecordedDate'
  },
  {
    key: 'state',
    displayName: 'State',
    keyPath: 'state'
  },
  {
    key: 'typeCode',
    displayName: 'Type Code',
    keyPath: 'typeCode'
  }
];
export const SELLER_SUBMITTED_VIEW = [
  {
    key: 'userVin',
    displayName: 'VIN',
    keyPath: 'userVin'
  },
  {
    key: 'userRego',
    displayName: 'Number Plate',
    keyPath: 'userRego'
  },
  {
    key: 'userState',
    displayName: 'Registration State /Territory',
    keyPath: 'userState',
    type: 'STATE'
  }
];
export const NEVDIS_RETRIEVE_VIEW = [
  {
    key: 'vin',
    displayName: 'VIN',
    keyPath: 'vin'
  },
  {
    key: 'rego',
    displayName: 'Number Plate',
    keyPath: 'rego'
  },
  {
    key: 'state',
    displayName: 'Registration State /Territory',
    keyPath: 'state',
    type: 'STATE'
  },
  {
    key: 'registrationStatus',
    displayName: 'Registration Status',
    keyPath: 'registrationStatus',
    type: 'STATE'
  },
  {
    key: 'registrationExpiry',
    displayName: 'Registration Expiry',
    keyPath: 'registrationExpiry'
  }
];
export const PPSR_VIEW = [
  {
    key: 'registrationKind',
    displayName: 'Registration Kind',
    keyPath: 'registrationKind'
  },
  {
    key: 'registrationStartTime',
    displayName: 'Registration Start Time',
    type: 'Date',
    keyPath: 'registrationStartTime'
  },
  {
    key: 'registrationEndTime',
    displayName: 'Registration End Time',
    type: 'Date',
    keyPath: 'registrationEndTime'
  },
  {
    key: 'registrationChangeTime',
    displayName: 'Registration Change Time',
    type: 'Date',
    keyPath: 'registrationChangeTime'
  },
  {
    key: 'collateralClassDescription',
    displayName: 'Collateral Class Description',
    keyPath: 'collateralClassDescription'
  },
  {
    key: 'collateralType',
    displayName: 'Collateral Type',
    keyPath: 'collateralType'
  },
  {
    key: 'grantors',
    displayName: 'Grantor',
    type: 'array',
    keyPath: 'grantors'
  },
  {
    key: 'securedParties',
    displayName: 'Secured Parties',
    type: 'array',
    keyPath: 'securedParties'
  },
  {
    key: 'addressForService',
    displayName: 'Address For Service',
    type: 'object',
    keyPath: 'addressForService'
  }
];
export const GRANTOR_ORG_VIEW = [
  {
    key: 'grantorType',
    displayName: 'Grantor Type',
    keyPath: 'grantorType'
  },
  {
    key: 'organisationName',
    displayName: 'Organisation Name',
    keyPath: 'organisation.organisationName'
  },
  {
    key: 'organisationNumber',
    displayName: 'Organisation Number',
    keyPath: 'organisationNumber'
  },
  {
    key: 'organisationNumberType',
    displayName: 'Organisation Number Type',
    keyPath: 'organisation.organisationNumberType'
  }
];
export const SECURED_ORG_PARTIES_VIEW = [
  {
    key: 'securedPartyType',
    displayName: 'Secured Party Type',
    keyPath: 'securedPartyType'
  },
  {
    key: 'organisationName',
    displayName: 'Organisation Name',
    keyPath: 'organisation.organisationName'
  },
  {
    key: 'organisationNumber',
    displayName: 'Organisation Number',
    keyPath: 'organisation.organisationNumber'
  },
  {
    key: 'organisationNumberType',
    displayName: 'Organisation Number Type',
    keyPath: 'organisation.organisationNumberType'
  }
];
export const GRANTOR_INDIVIDUAL_VIEW = [
  {
    key: 'grantorType',
    displayName: 'Grantor Type',
    keyPath: 'grantorType'
  },
  {
    key: 'familyName',
    displayName: 'Family Name',
    keyPath: 'individual.familyName'
  },
  {
    key: 'givenNames',
    displayName: 'Given Name',
    keyPath: 'individual.givenNames'
  }
];
export const SECURED_INDIVIDUAL_PARTIES_VIEW = [
  {
    key: 'securedPartyType',
    displayName: 'Secured Party Type',
    keyPath: 'securedPartyType'
  },
  {
    key: 'familyName',
    displayName: 'Family Name',
    keyPath: 'individual.familyName'
  },
  {
    key: 'givenNames',
    displayName: 'Given Name',
    keyPath: 'individual.givenNames'
  }
];
export const ADDRESS_VIEW = [
  {
    key: 'addressee',
    displayName: 'Addressee',
    keyPath: 'addressee'
  },
  {
    key: 'mailingAddress',
    displayName: 'Mailing Address',
    keyPath: 'mailingAddress'
  },
  {
    key: 'emailAddress',
    displayName: 'Email Address',
    keyPath: 'emailAddress'
  }
];
export const STOLEN_VIEW = [
  {
    key: 'type',
    displayName: 'Stolen Type',
    keyPath: 'type'
  },
  {
    key: 'jurisdiction',
    displayName: 'Jurisdiction',
    keyPath: 'jurisdiction'
  },
  {
    key: 'incidentRecordedDate',
    displayName: 'Reported Date',
    keyPath: 'reportedDate'
  },
  {
    key: 'summary',
    displayName: 'Summary',
    keyPath: 'summary'
  }
];
export const COMPLIANCE_VIEW = [
  {
    key: 'approvalHolder',
    displayName: 'Approval Holder',
    keyPath: 'approvalHolder'
  },
  {
    key: 'approvalNumber',
    displayName: 'Approval Number',
    keyPath: 'approvalNumber'
  },
  {
    key: 'authorisedBy',
    displayName: 'Authorised By',
    keyPath: 'authorisedBy'
  },
  {
    key: 'buildDate',
    displayName: 'Build Date',
    keyPath: 'buildDate'
  },
  {
    key: 'entryDate',
    displayName: 'Entry Date',
    keyPath: 'entryDate'
  },
  {
    key: 'entryPathway',
    displayName: 'Entry Pathway',
    keyPath: 'entryPathway'
  },
  {
    key: 'make',
    displayName: 'Make',
    keyPath: 'make'
  },
  {
    key: 'model',
    displayName: 'Model',
    keyPath: 'model'
  },
  {
    key: 'powerKw',
    displayName: 'Power Kw',
    keyPath: 'powerKw'
  },
  {
    key: 'seats',
    displayName: 'Seats',
    keyPath: 'seats'
  },
  {
    key: 'tareMass',
    displayName: 'Tare Mass',
    keyPath: 'tareMass'
  },
  {
    key: 'vehicleCategoryCode',
    displayName: 'Vehicle Category Code',
    keyPath: 'vehicleCategoryCode'
  }
];
export const DETAILS_VIEW_CERT = [
  {
    key: 'location',
    displayName: 'Location',
    keyPath: 'location'
  },
  {
    key: 'createdTime',
    displayName: 'Timestamp',
    keyPath: 'createdTime',
    type: 'Date'
  }
];

export const DETAILS_VIEW_VIN = [
  {
    key: 'vin',
    displayName: 'VIN',
    keyPath: 'vin'
  }
];
export const DETAILS_VIEW_PLATE = [
  {
    key: 'plate',
    displayName: 'Number Plate',
    keyPath: 'plate'
  },
  {
    key: 'state',
    displayName: 'Registration State',
    keyPath: 'state'
  }
];

export const DETAILS_VIEW_LICENSE_EXPIRY = [
  {
    key: 'dateOfExpiry',
    displayName: 'Expiry Date',
    keyPath: 'dateOfExpiry'
  }
];

export const DETAILS_VIEW_VERIFIFICATIONS = [
  {
    key: 'createdTime',
    displayName: 'Created Time',
    keyPath: 'createdTime',
    type: 'Date'
  },
  {
    key: 'status',
    displayName: 'Status',
    keyPath: 'status'
  },
  {
    key: 'updatedTime',
    displayName: 'Updated Time',
    keyPath: 'updatedTime',
    type: 'Date'
  },
  {
    key: 'vin',
    displayName: 'Vin',
    keyPath: 'vin'
  },
  {
    key: 'registrationPlate',
    displayName: 'Registration Plate',
    keyPath: 'registration.data.plate'
  },
  {
    key: 'registrationState',
    displayName: 'Registration State',
    keyPath: 'registration.data.state'
  }
];
export const DETAILS_VIEW_PPSR = [
  {
    key: 'areProceedsClaimed',
    displayName: 'Are Proceeds Claimed',
    keyPath: 'registrationDetail.areProceedsClaimed'
  },
  {
    key: 'isSecurityInterestRegistrationKind',
    displayName: 'Is Security Interest Registration',
    keyPath: 'registrationDetail.isSecurityInterestRegistrationKind'
  },
  {
    key: 'registrationKind',
    displayName: 'Registration Kind',
    keyPath: 'registrationDetail.registrationKind'
  },
  {
    key: 'isInventory',
    displayName: 'Is Inventory',
    keyPath: 'registrationDetail.isInventory'
  },
  {
    key: 'isPMSI',
    displayName: 'Is Purchase Money Security Interest',
    keyPath: 'registrationDetail.isPMSI'
  },
  {
    key: 'registrationNumber',
    displayName: 'Registration Number',
    keyPath: 'registrationDetail.registrationNumber'
  },
  {
    key: 'registrationStartTime',
    displayName: 'Registration Start Time',
    type: 'Date',
    keyPath: 'registrationDetail.registrationStartTime'
  },
  {
    key: 'registrationEndTime',
    displayName: 'Registration End Time',
    type: 'Date',
    keyPath: 'registrationDetail.registrationEndTime'
  },
  {
    key: 'registrationChangeTime',
    displayName: 'Registration Change Time',
    type: 'Date',
    keyPath: 'registrationDetail.registrationChangeTime'
  },
  {
    key: 'registrarAlert',
    displayName: 'Registrar Alert',
    keyPath: 'registrationDetail.registrarAlert'
  },
  {
    key: 'collateralClassDescription',
    displayName: 'Collateral Class Description',
    keyPath: 'registrationDetail.collateralClassDescription'
  },
  {
    key: 'collateralType',
    displayName: 'Collateral Type',
    keyPath: 'registrationDetail.collateralType'
  },
  {
    key: 'grantors',
    displayName: 'grantors',
    type: 'SpecialName',
    keyPath: 'registrationDetail.grantors'
  },
  {
    key: 'securedParties',
    displayName: 'securedParties',
    type: 'SpecialName',
    keyPath: 'registrationDetail.securedParties'
  },
  {
    key: 'addrForServiceAddressee',
    displayName: 'Address For Service Addressee',
    keyPath: 'registrationDetail.addressForService.addressee',
    defaultValue: ''
  },
  {
    key: 'mailingAddress',
    displayName: 'Mailing Address',
    type: 'Addr',
    keyPath: 'registrationDetail.addressForService.mailingAddress'
  },
  {
    key: 'addrEmail',
    displayName: 'Addressee Email Address',
    keyPath: 'registrationDetail.addressForService.emailAddress'
  }
];
export const DETAILS_VIEW_PPSR_NO_DATA = [
  {
    key: 'noDataPPSR',
    displayName: 'PPSR Check',
    keyPath: 'registrationDetail.noData',
    defaultValue: 'No Registration'
  }
];
export const DETAILS_VIEW_STOLEN_WRITTEN = [
  {
    key: 'nvic',
    displayName: 'NVIC',
    keyPath: 'nvic.data'
  },
  {
    key: 'nevdisRegistrationPlate',
    displayName: 'Plate',
    keyPath: 'registration.data.plate'
  },
  {
    key: 'nevdisRegistrationState',
    displayName: 'State',
    keyPath: 'registration.data.state'
  },
  {
    key: 'registrationExpiryDate',
    displayName: 'Registration Expiry Date',
    keyPath: 'registration.data.expiry_date'
  },
  {
    key: 'registration',
    displayName: 'Registration Status',
    keyPath: 'registration.data.status'
  },
  {
    key: 'stolen',
    displayName: 'Stolen Check',
    type: 'Check',
    keyPath: 'stolen.data'
  },
  {
    key: 'writtenOff',
    displayName: 'Written Off Check',
    type: 'Check',
    keyPath: 'writtenOff.data'
  }
];

export const DETAILS_VIEW_COMPLIANCE_NO_DATA = [
  {
    key: 'noDataCompliance',
    displayName: 'NEVDIS Check',
    keyPath: 'registration.noData',
    defaultValue: 'No registration found'
  }
];

export const DETAILS_VIEW_VEHICLE_COMPLIANCE = [
  {
    key: 'complianceStatus',
    displayName: 'Compliance Status',
    keyPath: 'status'
  },
  {
    key: 'approvalHolder',
    displayName: 'Approval Holder',
    keyPath: 'data.approvalHolder'
  },
  {
    key: 'approvalNumber',
    displayName: 'Approval Number',
    keyPath: 'data.approvalNumber'
  },
  {
    key: 'authorisedBy',
    displayName: 'Authorised By',
    keyPath: 'data.authorisedBy'
  },
  {
    key: 'buildDate',
    displayName: 'Build Date',
    keyPath: 'data.buildDate'
  },
  {
    key: 'entryDate',
    displayName: 'Entry Date',
    keyPath: 'data.entryDate'
  },
  {
    key: 'entryPathway',
    displayName: 'Entry Pathway',
    keyPath: 'data.entryPathway'
  },
  {
    key: 'gvm',
    displayName: 'GVM',
    keyPath: 'data.gvm'
  },
  {
    key: 'rovMake',
    displayName: 'Make',
    keyPath: 'data.make'
  },
  {
    key: 'rovModel',
    displayName: 'Model',
    keyPath: 'data.model'
  },
  {
    key: 'powerKw',
    displayName: 'Power Kw',
    keyPath: 'data.powerKw'
  },
  {
    key: 'rovSeats',
    displayName: 'Seats',
    keyPath: 'data.seats'
  },
  {
    key: 'tareMass',
    displayName: 'Tare Mass',
    keyPath: 'data.tareMass'
  },
  {
    key: 'vehicleCategoryCode',
    displayName: 'Vehicle Category Code',
    keyPath: 'data.vehicleCategoryCode'
  }
];
export const DETAILS_VIEW_VEHICLE_DETAILS = [
  { key: 'make', displayName: 'Make', keyPath: 'make' },
  { key: 'model', displayName: 'Model', keyPath: 'model' },
  { key: 'colour', displayName: 'Colour', keyPath: 'colour' },
  { key: 'bodyType', displayName: 'Body Type', keyPath: 'bodyType' },
  { key: 'vehicleType', displayName: 'Vehicle Type', keyPath: 'vehicleType' },
  {
    key: 'engineNumber',
    displayName: 'Engine Number',
    keyPath: 'engineNumber'
  },
  {
    key: 'complianceMonth',
    displayName: 'Compliance Month',
    keyPath: 'complianceMonth'
  },
  { key: 'buildYear', displayName: 'Build Year', keyPath: 'buildYear' },
  { key: 'nvic', displayName: 'NVIC', keyPath: 'nvic' },
  { key: 'priceNew', displayName: 'Price New', keyPath: 'priceNew' },
  { key: 'releaseDate', displayName: 'Release Date', keyPath: 'releaseDate' },
  { key: 'seats', displayName: 'Seats', keyPath: 'seats' },
  { key: 'segment', displayName: 'Segment', keyPath: 'segment' },
  { key: 'series', displayName: 'Series', keyPath: 'series' },
  { key: 'serviceKms', displayName: 'Service Kms', keyPath: 'serviceKms' },
  {
    key: 'serviceMonths',
    displayName: 'Service Months',
    keyPath: 'serviceMonths'
  },
  { key: 'towingMass', displayName: 'Towing Mass', keyPath: 'towingMass' },
  { key: 'transmission', displayName: 'Transmission', keyPath: 'transmission' },
  {
    key: 'transmissionCode',
    displayName: 'Transmission Code',
    keyPath: 'transmissionCode'
  },
  { key: 'variant', displayName: 'Variant', keyPath: 'variant' },
  { key: 'vehicleMass', displayName: 'Vehicle Mass', keyPath: 'vehicleMass' },
  { key: 'warrantyKms', displayName: 'Warranty Kms', keyPath: 'warrantyKms' },
  {
    key: 'warrantyMonths',
    displayName: 'Warranty Months',
    keyPath: 'warrantyMonths'
  },
  { key: 'year', displayName: 'Year', keyPath: 'year' },
  { key: 'cylinders', displayName: 'Cylinders', keyPath: 'cylinders' },
  {
    key: 'discountDate',
    displayName: 'Discount Date',
    keyPath: 'discountDate'
  },
  { key: 'doors', displayName: 'Doors', keyPath: 'doors' },
  { key: 'drivetrain', displayName: 'Drivetrain', keyPath: 'drivetrain' },
  { key: 'engineSize', displayName: 'Engine Size', keyPath: 'engineSize' },
  { key: 'engineType', displayName: 'Engine Type', keyPath: 'engineType' },
  { key: 'fuelCode', displayName: 'Fuel Code', keyPath: 'fuelCode' },
  { key: 'fuelType', displayName: 'Fuel Type', keyPath: 'fuelType' },
  { key: 'gears', displayName: 'Gears', keyPath: 'gears' },
  { key: 'guideModel', displayName: 'Guide Model', keyPath: 'guideModel' }
];
export const DOCUMENT_LIST = [
  ['DRIVER_LICENCE_FRONT', 'DRIVER_LICENCE_BACK', 'FACE'],
  [
    'VIN',
    'REGO_PLATE',
    'ODOMETER',
    'ASSET_FRONT',
    'ASSET_BACK',
    'ASSET_LEFT',
    'ASSET_RIGHT'
  ],
  ['registration'],
  ['BANK_STATEMENTS'],
  ['ROADWORTHY_CERT']
];
export const licenseChecks = [
  'LICENSE_AUTHENTICITY',
  'LICENSE_FACE_MATCH',
  'LICENSE_LIVENESS',
  'LICENSE_EXPIRY',
  'LICENSE_EDITS'
];
export const stepKeyMappings = [
  'driverLicense',
  'vehicle',
  'regoPaper',
  'bank',
  'cert'
];
export const SECURITY_CHECK_LIST = [
  [
    'licenseExpiry',
    'licenseEdits',
    'licenseFaceMatch',
    'licenseLiveness',
    'licenseAuthenticity'
  ],
  [
    'rav',
    'ppsr',
    'stolen',
    'writtenOff',
    'vin',
    'registration',
    'vehiclePhotos',
    'dataEdits'
  ],
  ['expiry', 'registrationAuthenticity', 'regoCertificate'],
  ['bankAuthenticity', 'bankStatement', 'BANK_STATEMENT_ADDRESS'],
  [],
  ['rav', 'registration', 'ppsr', 'stolen', 'writtenOff']
];
export const FULL_DOC_TYPES = [
  'SELLER_ID',
  'VIN',
  'ASSET_FRONT',
  'ASSET_BACK',
  'ASSET_LEFT',
  'ASSET_RIGHT',
  'REGO_PLATE',
  'ODOMETER',
  'REGO_PAPERS',
  'BANK_STATEMENTS',
  'ROADWORTHY_CERT',
  'SAFETY_CERT'
];

export const LOGOS_LIST = [
  'BMW',
  'BYD',
  'FORD',
  'GWM',
  'HONDA',
  'HYUNDAI',
  'ISUZU UTE',
  'KIA',
  'MAZDA',
  'MG',
  'MITSUBISHI',
  'NISSAN',
  'SUBARU',
  'SUZUKI',
  'TESLA',
  'TOYOTA',
  'VOLKSWAGEN'
];
