import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
interface OrgStoreState {
  organisation: {
    assetVerificationTypes: string[];
    documents: any[];
    emailDomains: string[];
    hasBrokers: boolean;
    logoBase64: string;
    logoUrl: string;
    name: string;
    organisationId: string;
    primaryColour: string;
    status: string;
  };
  setOrg: (org: any) => void;
}

const organisation = {
  assetVerificationTypes: [],
  documents: [],
  emailDomains: [],
  hasBrokers: true,
  logoBase64: '',
  logoUrl: '',
  name: '',
  organisationId: '',
  primaryColour: '',
  status: ''
};
export const useOrgStore = create<OrgStoreState>()(
  devtools(
    persist(
      (set) => ({
        organisation,
        setOrg: (org) => set({ organisation: org })
      }),
      {
        name: 'org',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
