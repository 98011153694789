import { useEffect, useState } from 'react';
import HelpCircle from '@assets/images/HelpCircle';
import ContactSupportSellerModal from '@components/widgets/ContactSupportSeller';
import { useOrgStore } from '@stores';

const SellerHeader = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, [organisation.primaryColour]);
  return (
    <>
      <div className="h-18 bg-grey-100">
        <div className="border-b container border-b-grey-100 border-opacity-10 py-2 h-[72px] flex items-center w-full">
          <div className="flex items-center justify-between w-full">
            <div className="h-[72px] flex items-center py-4">
              <img
                src={organisation.logoUrl}
                alt="Uploaded SVG"
                height={40}
                className="max-w-full h-[40px]"
              />
            </div>
            <div
              onClick={() => setIsOpenModal(true)}
              className="flex items-center cursor-pointer">
              <HelpCircle color={primaryColor} />
              <p className="text-[#101828] ml-2 whitespace-nowrap">Support</p>
            </div>
          </div>
        </div>
      </div>
      <ContactSupportSellerModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default SellerHeader;
