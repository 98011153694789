import React, { useState, useEffect } from 'react';
import Button from '@components/forms/Button';
import { ReactComponent as Tick } from '@assets/icons/Tick.svg';
import { UPDATE_USER_PROFILE, CURRENT_USER, post } from '@services';
import { useAuthStore } from '@stores';

interface InitialsAvatarProps {
  title: string;
}
const InitialsAvatar: React.FC<InitialsAvatarProps> = ({ title }) => {
  const [initials, setInitials] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [profileSaved, setProfileSaved] = useState(false);
  const { setUser, user } = useAuthStore();
  const [error, setError] = useState('');
  const colors = [
    '#DD3434',
    '#EC6BC0',
    '#58A356',
    '#862E9C',
    '#FD9C2B',
    '#667085',
    '#39ACFF'
  ];

  useEffect(() => {
    post(CURRENT_USER, {}).then((res) => {
      setInitials(res?.user?.initials);
      setSelectedColor('#' + res?.user?.avatarColour);
    });
  }, []);

  const handleSave = () => {
    if ((initials && initials.length < 2) || initials.length > 4) {
      setError('Initials must be between 2 and 4 characters.');
      return; // Stop the function if there is an error
    }
    setError(''); // Clear any existing errors

    // Save profile logic here
    post(UPDATE_USER_PROFILE, {
      initials,
      avatarColour: selectedColor ? selectedColor.replace('#', '') : ''
    })
      .then(() => {
        setUser({
          ...user,
          initials,
          avatarColour: selectedColor
            ? selectedColor.replace('#', '')
            : 'DD3434'
        });
        setProfileSaved(true);
        setTimeout(() => setProfileSaved(false), 5000); // Hide message after 3 seconds
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <h1 className="font-semibold text-3xl text-gray-900 mb-6">{title}</h1>

      <div className="mb-6" style={{ maxWidth: '520px' }}>
        <label
          className="block font-medium text-gray-700 text-sm"
          htmlFor="initials">
          Initials
        </label>
        <input
          id="initials"
          type="text"
          className="w-full p-2 border rounded"
          value={initials}
          onChange={(e) => {
            const formattedInitials = e.target.value.toUpperCase();
            if (formattedInitials.length <= 4) {
              setInitials(formattedInitials);
            }
          }}
        />
        {error && <div className="text-primary text-xs mt-1">{error}</div>}
      </div>
      <div className="flex mb-6">
        {colors.map((color) => (
          <button
            key={color}
            style={{
              backgroundColor: color
            }}
            className="w-10 h-10 mr-2 rounded-[10px] flex items-center justify-center"
            onClick={() => setSelectedColor(color)}>
            {selectedColor === color && <Tick />}
          </button>
        ))}
      </div>
      {profileSaved && (
        <div className="mb-2 text-green-700 text-sm">Profile saved!</div>
      )}
      <Button type="submit" className="bg-primary" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};

export default InitialsAvatar;
