import React, { useState } from 'react';
import Modal from '@components/widgets/Modal';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';
import { RoleEnum } from '@types';
import { useForm } from 'react-hook-form';
import { ADD_LENDER_BROKER, LIST_LENDER_BROKERS, post } from '@services';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBrokerStore } from '@stores';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';

type Props = {
  open: boolean;
  onClose: () => void;
};
const lenderInviteFormSchema = yup
  .object({
    email: yup
      .string()
      .email('Please provide a valid email')
      .required('Please provide a valid email'),
    lenderReference: yup.string()
  })
  .required();

const CreateLenderModal: React.FC<Props> = ({ open, onClose }) => {
  const {
    register,
    getValues,
    setError,
    reset,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(lenderInviteFormSchema),
    defaultValues: {
      email: '',
      lenderReference: ''
    }
  });
  const { email, lenderReference } = watch();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { setBrokerList } = useBrokerStore();
  const sendInvitation = () => {
    const userId = localStorage.getItem('userId');

    const lenders = localStorage.getItem(`lenders_${userId}`);
    if (lenders != null && !errors.email) {
      const lender = JSON.parse(lenders)[0];
      const organisationId = lender.organisationId;
      post(
        ADD_LENDER_BROKER,
        {
          email: getValues('email'),
          lenderOrganisationId: organisationId,
          lenderReference: getValues('lenderReference')
        },
        false
      )
        .then((data: any) => {
          if (data.userId) {
            reset();
            const userId = localStorage.getItem('userId');
            const lenders = localStorage.getItem(`lenders_${userId}`);
            if (lenders != null) {
              const lender = JSON.parse(lenders)[0];
              const lenderOrganisationId = lender.organisationId;
              post(LIST_LENDER_BROKERS, { lenderOrganisationId }).then(
                (data) => {
                  setBrokerList(data.lenderBrokers);
                }
              );
            }
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowSuccessMessage(false);
              onClose();
            }, 4000);
          }
        })
        .catch((e: any) => {
          setError(
            'email',
            {
              type: 'focus',
              message: 'This user email address has already been added'
            },
            { shouldFocus: true }
          );
        });
    }
  };
  return (
    <Modal title="Add Broker" size="md" open={open} onClose={onClose}>
      <form>
        <div className="mb-4">
          <p className="text-sm font-medium text-grey-700 mb-1.5">Email</p>
          <Input
            placeholder="Email"
            type="email"
            icon={errors.email && <ErrorIcon />}
            value={email}
            {...register('email')}
          />
          {errors.email && (
            <span className="text-xs text-error-light">
              {(errors.email as any).message}
            </span>
          )}
        </div>
        <div className="mb-4">
          <p className="text-sm font-medium text-grey-700 mb-1.5">
            Broker Reference Number
          </p>
          <Input
            placeholder="A-XXX XXX"
            type="lenderReference"
            icon={errors.email && <ErrorIcon />}
            value={lenderReference}
            {...register('lenderReference')}
          />
        </div>
        <div className="mt-12">
          <div className="h-4 w-full text-right mb-2">
            {showSuccessMessage && (
              <div className="mt-4 text-green-500">Invite sent!</div>
            )}
          </div>
          <div className="flex gap-4 justify-center lg:justify-end">
            <Button
              className="font-semibold bg-white border-grey-300 text-grey-700 w-auto capitalize"
              variant="outlined"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="font-semibold bg-primary w-auto capitalize"
              onClick={handleSubmit(sendInvitation)}>
              Send Invite
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateLenderModal;
