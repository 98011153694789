import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_LENDER_INSPECTIONS, GET_MY_INSPECTION, post } from '@services';
import Button from '@components/forms/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus_icon.svg';
import { ReactComponent as NoResult } from '@assets/icons/no_result.svg';
import { useInspectionDetailStore, useInspectionStore } from '@stores';
import { parseISO } from 'date-fns';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import InspectionTable from './components/InspectionTable';
import FilterBar from './components/FilterBar';

const Inspection = () => {
  const navigate = useNavigate();
  const [lenderInspections, setLenderInspections] = useState<any[]>([]);
  const [lenderInspectionsOriginal, setLenderInspectionsOriginal] = useState<
    any[]
  >([]);
  const { query, status, asset, lender, setLenderOptions } =
    useInspectionStore();
  const { reset } = useInspectionDetailStore();
  const [isLoading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const loadMore = (createdTime: string) => {
    fetchData(createdTime);
  };
  useEffect(() => {
    fetchData();
  }, []);
  function filterInspections(
    keyword: string,
    status: string,
    asset: string,
    lender: string
  ) {
    let newData = lenderInspectionsOriginal;
    const lenders = _uniqBy(
      _map(lenderInspectionsOriginal, (item) =>
        item.lender
          ? {
              label: item.lender?.firstName + ' ' + item.lender?.lastName,
              value: item.lender?.userId
            }
          : item.broker
          ? {
              label: item.broker?.firstName + ' ' + item.broker?.lastName,
              value: item.broker?.userId
            }
          : null
      ),
      'value'
    );
    lenders.unshift({ value: 'ALL', label: 'All' });
    setLenderOptions(lenders);
    const pattern = /[`~!@#$^&*()={}':;',\\]/g;
    keyword = keyword.replace(pattern, '');
    if (!_isNil(keyword) && keyword !== '') {
      setIsFilter(true);
      const pattern = new RegExp(keyword, 'i');
      newData = lenderInspectionsOriginal.filter((item: any) => {
        return (
          pattern.test(item.vehicleInfo) ||
          pattern.test(item.status) ||
          pattern.test(item.lenderReference) ||
          pattern.test(item.friendlyId) ||
          pattern.test(
            (item.buyer?.firstName || '') + ' ' + (item.buyer?.lastName || '')
          ) || // Check for null buyer
          pattern.test(
            (item.seller?.firstName || '') + ' ' + (item.seller?.lastName || '')
          ) || // Check for null seller
          pattern.test(
            (item.lender?.firstName || '') + ' ' + (item.lender?.lastName || '')
          ) || // Check for null lender
          pattern.test(
            (item.broker?.firstName || '') + ' ' + (item.broker?.lastName || '')
          ) || // Check for null broker
          pattern.test(item.assetType)
        );
      });
    }
    if (status !== 'ALL' && newData.length > 0) {
      setIsFilter(true);
      newData = newData.filter(
        (item: any) => item.status.toLowerCase() === status.toLowerCase()
      );
    }
    if (asset !== 'ALL' && newData.length > 0) {
      setIsFilter(true);
      newData = newData.filter(
        (item: any) => item.assetType.toLowerCase() === asset.toLowerCase()
      );
    }
    if (lender !== 'ALL' && newData.length > 0) {
      setIsFilter(true);
      newData = newData.filter(
        (item: any) =>
          item.lender?.userId === lender || item.broker?.userId === lender
      );
    }
    //to avoid duplicate data when the length of total is just match % 40 === 0 (40 80 160)
    if (
      lenderInspections.length > 0 &&
      newData.length > 0 &&
      parseISO(newData[0].createdTime) >=
        parseISO(lenderInspections[lenderInspections.length - 1].createdTime)
    ) {
      setIsFilter(false);
    }
    setLenderInspections(newData);
  }
  const fetchData = (createdTime?: string) => {
    setLoading(true);
    const userId = localStorage.getItem('userId');
    if (userId) {
      const lenderUser = localStorage.getItem(`lenders_${userId}` as any);
      const lenderBrokers = localStorage.getItem(
        `lenderBrokers_${userId}` as any
      );
      if (lenderUser || lenderBrokers) {
        const organisationId = lenderUser
          ? JSON.parse(lenderUser)[0].organisationId
          : lenderBrokers
          ? JSON.parse(lenderBrokers).map(
              (broker: any) => broker.lenderOrganisationId
            )
          : null;
        const params: { lenderOrganisationId: string; olderThan?: string } = {
          lenderOrganisationId: organisationId
        };
        if (createdTime) {
          params.olderThan = createdTime;
        }
        const url = lenderUser ? GET_LENDER_INSPECTIONS : GET_MY_INSPECTION;
        post(url, params).then((data) => {
          setLoading(false);
          if (data.inspections) {
            setLenderInspectionsOriginal(
              lenderInspectionsOriginal.concat(data.inspections)
            );
            filterInspections(query, status, asset, lender);
          }
        });
      }
    }
  };
  useEffect(() => {
    filterInspections(query, status, asset, lender);
  }, [query, status, asset, lender, lenderInspectionsOriginal]);
  if (!isLoading && !isFilter && lenderInspections.length === 0)
    return (
      <div className="p-8 rounded-lg border border-grey-200 bg-grey-50 flex flex-row justify-center items-center m-8 no-result">
        <div className="flex flex-col gap-8 justify-center items-center">
          <div className="border border-grey-200 rounded-xl w-14 h-14 flex justify-center items-center">
            <NoResult />
          </div>
          <p className="text-lg font-semibold">No inspections found</p>
          <Button
            className="capitalize bg-primary flex items-center gap-2 w-auto text-sm font-semibold py-2.5 text-white mb-1"
            onClick={() => {
              reset();
              navigate('/inspections/create');
            }}>
            <PlusIcon />
            New inspection
          </Button>
        </div>
      </div>
    );
  return (
    <>
      <div className="flex items-center justify-between lg:flex-row md:flex-col md:items-start md:w-full">
        <FilterBar />
      </div>
      <div className="pt-6">
        <InspectionTable data={lenderInspections} loadMoreData={loadMore} />
      </div>
    </>
  );
};

export default Inspection;
