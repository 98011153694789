export const TITLE_PATH_MAPPINGS = [
  { path: '/inspections', title: 'Inspections' },
  { path: '/inspections/', title: 'Inspections' },
  { path: '/lenders', title: 'Lenders' },
  { path: '/users', title: 'Users' },
  { path: '/broker', title: 'Brokers' },
  { path: '/profile', title: 'Profile' },
  { path: '/settings', title: 'Settings' },
  { path: '/lenders/', title: 'Lenders' },
  { path: '/inspection-templates/', title: 'Inspection Templates' },
  { path: '/inspection-templates', title: 'Inspection Templates' },
  { path: '/verifications/', title: 'Asset Verifications' },
  { path: '/verifications', title: 'Asset Verifications' },
  { path: '/', title: 'Dashboard' }
];
