import React from 'react';
import { NewInspectionModalEnum, confirmModalType } from '@types';
import { Button } from '@material-tailwind/react';
import Modal from './Modal';

type Props = {
  open: boolean;
  onClose: () => void;
  type: confirmModalType;
  onSubmit: () => void;
};

const ConfirmSellerModal: React.FC<Props> = ({
  open,
  type,
  onClose,
  onSubmit
}) => {
  return (
    <Modal size="md" open={open} onClose={onClose}>
      <div className="flex flex-col pt-6 gap-[10px]">
        <div className="flex flex-col py-2 gap-[10px]">
          <h2 className="text-grey-900 font-semibold flex gap-2 flex-row w-full">
            {type === NewInspectionModalEnum.close
              ? 'Discard changes?'
              : type === NewInspectionModalEnum.draft
              ? 'Load draft'
              : 'Confirm submission'}
          </h2>

          {type === NewInspectionModalEnum.close && (
            <p>Are you sure you want close? Your changes will be lost.</p>
          )}

          {type === NewInspectionModalEnum.draft && (
            <p>
              Do you want to load your recent changes? Any unsaved changes will
              be lost.
            </p>
          )}
          {type === NewInspectionModalEnum.submit && (
            <>
              <p>
                By confirming this submission, an SMS notification will be sent
                to the Seller, with instructions to complete the inspection.
              </p>
              <p>
                Are you sure you want to proceed with this inspection
                submission?
              </p>
            </>
          )}
        </div>
        <div className="grid grid-cols-2 w-full gap-4">
          <Button
            variant="outlined"
            data-dialog-close="true"
            onClick={onClose}
            className="border border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
            Cancel
          </Button>
          <Button
            data-dialog-close="true"
            className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
            onClick={onSubmit}>
            {type === NewInspectionModalEnum.close && 'Close'}
            {type === NewInspectionModalEnum.draft && 'Load draft'}
            {type === NewInspectionModalEnum.submit && 'Confirm'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmSellerModal;
