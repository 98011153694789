import DocumentCard from '@pages/Inspections/components/RightPanelCards/DocumentCard';
import NevdisCheckCard from '@pages/Inspections/components/RightPanelCards/NevdisCheckCard';
import { DocumentFormEnum, DocumentFormType } from '@types';
import { useInspectionDetailStore } from '@stores';
import { SECURITY_CHECK_LIST } from '@constants';

const VehicleDetails = () => {
  const { activePanel, inspectionDetailData } = useInspectionDetailStore();

  return (
    <>
      {activePanel.activeSection === 'VIN' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_VEHICLE_VIN} />
      )}
      {activePanel.activeSection === 'REGO_PLATE' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_VEHICLE_PLATE} />
      )}
      {activePanel.activeSection === 'ODOMETER' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_VEHICLE_ODOMETER} />
      )}
      {activePanel.activeSection === 'ASSET_FRONT' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_VEHICLE_ASSET_FRONT} />
      )}
      {activePanel.activeSection === 'ASSET_BACK' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_VEHICLE_ASSET_BACK} />
      )}
      {activePanel.activeSection === 'ASSET_RIGHT' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_VEHICLE_DRIVER_SIDE} />
      )}
      {activePanel.activeSection === 'ASSET_LEFT' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_VEHICLE_PASSENGER_SIDE} />
      )}
      {SECURITY_CHECK_LIST[1].includes(activePanel.activeSection) && (
        <NevdisCheckCard
          type={activePanel.activeSection as DocumentFormType}
          data={
            activePanel.activeSection === 'vin' ||
            activePanel.activeSection === 'dataEdits'
              ? inspectionDetailData.inspectionChecks
              : inspectionDetailData.inspectionChecks[
                  activePanel.activeSection as keyof typeof inspectionDetailData.inspectionChecks
                ]
          }
          status={
            inspectionDetailData.inspectionChecks[
              activePanel.activeSection as keyof typeof inspectionDetailData.inspectionChecks
            ]?.status
          }
        />
      )}
    </>
  );
};

export default VehicleDetails;
