import React, { forwardRef, useImperativeHandle } from 'react';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import clsx from 'clsx';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { useInspectionDetailStore } from '@stores';
interface BuyerDetailStepRef {
  submitForm: () => Promise<any>;
}
const BuyerDetailStep = forwardRef<BuyerDetailStepRef>((props, ref) => {
  const phoneRegExp = /^(04\d{8}|\+614\d{8}|614\d{8})$/;
  const sellerFormSchema = yup.object({
    email: yup.string().email('Please provide a valid email address'),
    firstName: yup
      .string()
      .required('Please enter the buyer’s first name')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    lastName: yup.string().required('Please enter the buyer’s last name'),
    mobile: yup.string(),
    address: yup.string()
  });
  const { inspectionDetailData, setBuyer, setDraft } =
    useInspectionDetailStore();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(sellerFormSchema),
    defaultValues: {
      email: inspectionDetailData.buyer.email,
      firstName: inspectionDetailData.buyer.firstName,
      lastName: inspectionDetailData.buyer.lastName,
      mobile: inspectionDetailData.buyer.mobile,
      address: inspectionDetailData.buyer.address
    }
  });
  const { firstName, lastName, email, mobile } = watch();

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      return new Promise((resolve, reject) => {
        handleSubmit(
          (data) => {
            const buyer = { ...data, isDone: true };
            setBuyer(buyer);
            resolve(true);
          },
          (errors) => reject(errors)
        )();
      });
    }
  }));
  return (
    <form>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">
        Buyer Details
      </h3>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Buyer First Name
        </p>
        <Input
          placeholder="First name"
          {...register(`firstName`)}
          onKeyDown={(event) => {
            if (!/[A-Za-z]+/i.test(event.key)) {
              event.preventDefault();
            }
          }}
          icon={errors.firstName && <ErrorIcon />}
          value={firstName}
        />
        {errors.firstName && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.firstName.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Buyer Last Name
        </p>
        <Input
          placeholder="Last name"
          {...register(`lastName`)}
          onKeyDown={(event) => {
            if (!/[A-Za-z]+/i.test(event.key)) {
              event.preventDefault();
            }
          }}
          icon={errors.lastName && <ErrorIcon />}
          value={lastName}
        />
        {errors.lastName && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.lastName.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Buyer Email (optional)
        </p>
        <Input
          placeholder="Email"
          {...register(`email`)}
          value={email}
          icon={errors.email && <ErrorIcon />}
        />
        {errors.email && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.email.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Buyer Mobile (optional)
        </p>
        <Input
          placeholder="Mobile number"
          {...register(`mobile`)}
          icon={errors.mobile && <ErrorIcon />}
          value={mobile}
        />
        {errors.mobile && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.mobile.message}`}
          </span>
        )}
      </div>
    </form>
  );
});

export default BuyerDetailStep;
