/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Input from '@components/forms/Input';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@components/forms/Button';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';

const SellerVerify = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState('init');

  if (step === 'init') {
    return <InitialForm nextStep={() => setStep('verify')} />;
  }

  if (step === 'verify') {
    return <VerifyForm nextStep={() => setStep('verify-phone')} />;
  }

  if (step === 'verify-phone') {
    return <VerifyPhoneForm nextStep={() => setStep('select')} />;
  }

  if (step === 'select') {
    return <SelectInspection />;
  }

  return <InitialForm nextStep={() => setStep('verify')} />;
};

function InitialForm({ nextStep }: { nextStep: () => void }) {
  return (
    <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 md:px-10 py-12 rounded-xl shadow-paper relative">
      <form>
        <h3 className="text-2xl text-grey-900 font-semibold mb-2 text-center">
          Welcome
        </h3>
        <p className="text-center text-grey-600 mb-6">
          Enter your email address to continue
        </p>
        <p>Email</p>
        <Input className="mb-5 mt-1" placeholder="example@email.com" />

        <Button className="bg-primary text-white" fullWidth onClick={nextStep}>
          Continue
        </Button>
      </form>
    </div>
  );
}

function VerifyForm({ nextStep }: { nextStep: () => void }) {
  return (
    <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 md:px-10 py-12 rounded-xl shadow-paper relative">
      <form>
        <h3 className="text-2xl text-grey-900 font-semibold mb-2 text-center">
          Verify your email
        </h3>
        <p className="text-center text-grey-600 mb-6">
          We've sent an email with your code to steve@valeur.io
        </p>
        <p>Email</p>
        <div className="relative">
          <Input
            className="mb-5 mt-1 !bg-white"
            placeholder="example@email.com"
          />
          <button className="!absolute right-2 top-3 text-[#2E90FA]">
            Edit
          </button>
        </div>
        <p>Enter code from email</p>
        <Input
          className="mb-5 mt-1"
          type="password"
          placeholder="example@email.com"
        />
        <Button className="bg-primary text-white" fullWidth onClick={nextStep}>
          Continue
        </Button>
        <p className="mt-2 text-center text-grey-600">
          Didn’t receive a code?{' '}
          <a className="underline text-[#2E90FA]">Resend</a>
        </p>
      </form>
    </div>
  );
}

function VerifyPhoneForm({ nextStep }: { nextStep: () => void }) {
  return (
    <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 md:px-10 py-12 rounded-xl shadow-paper relative">
      <form>
        <h3 className="text-2xl text-grey-900 font-semibold mb-2 text-center">
          Verify your mobile
        </h3>
        <p className="text-center text-grey-600 mb-6">
          We’ve sent a text message to:
        </p>
        <p>Mobile</p>
        <div className="relative">
          <Input className="mb-5 mt-1 !bg-white" placeholder="XXXXXX1339" />
          <button className="!absolute right-2 top-3 text-[#2E90FA]">
            Edit
          </button>
        </div>
        <p>Enter the 6-digit code</p>
        <Input className="mb-5 mt-1" maxLength={6} type="password" />
        <Button className="bg-primary text-white" fullWidth onClick={nextStep}>
          Continue
        </Button>
        <p className="mt-2 text-center text-grey-600">
          Didn’t receive a code?{' '}
          <a className="underline text-[#2E90FA]">Resend</a>
        </p>
      </form>
    </div>
  );
}

function SelectInspection() {
  return (
    <form>
      <p className="text-grey-700 mb-6 text-center">
        Choose the inspection you'd like to continue with
      </p>
      <div className="mx-auto w-full md:w-[440px] bg-white rounded-xl shadow-paper relative">
        <Button className="bg-white border-b !rounded-none border-grey-200 w-full p-5 text-dark-primary flex justify-between">
          <p>00001</p>
          <LeftIcon />
        </Button>
        <Button className="bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between">
          <p>00002</p>
          <LeftIcon />
        </Button>
      </div>
      <Button
        className="mx-auto mt-6 bg-primary text-white"
        fullWidth
        onClick={() => {}}>
        Continue
      </Button>
    </form>
  );
}

export default SellerVerify;
