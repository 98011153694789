import React, { ReactNode } from 'react';
import { Card } from '@material-tailwind/react';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow_down_icon.svg';
import clsx from 'clsx';
import _get from 'lodash/get';

export type TableHeader = {
  label: string;
  field: string;
  sort?: boolean;
  render?: (row: any) => ReactNode;
  headerClassName?: string;
  contentClassName?: string;
};

type Props = {
  headers: TableHeader[];
  data: Record<string, any>[];
  onClickRow?: (rowData: any) => void;
};

const Table: React.FC<Props> = ({ headers, data, onClickRow }) => {
  return (
    <Card className="h-full w-full overflow-y-hidden border border-grey-200 shadow-none">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {headers.map((head) => (
              <th
                key={head.field}
                className={clsx(
                  'border-b border-grey-200 bg-blue-gray-50/50 px-6 py-3',
                  head.sort && 'hover:bg-blue-gray-50',
                  head.contentClassName
                )}>
                <div className="flex items-center gap-2 text-xs text-grey-600 cursor-pointer font-medium">
                  {head.label}{' '}
                  {head.sort && (
                    <ArrowDownIcon strokeWidth={2} className="h-4 w-4" />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className="cursor-pointer hover:bg-grey-50"
              onClick={onClickRow && (() => onClickRow(row))}>
              {headers.map((head) => (
                <td
                  key={head.field}
                  className={clsx(
                    'px-6 py-5 border-b border-grey-200',
                    index === data.length - 1 && 'border-none',
                    head.contentClassName
                  )}>
                  {head.render ? (
                    head.render(row)
                  ) : (
                    <div className="text-sm">{_get(row, head.field)}</div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default Table;
