import React from 'react';
import { Outlet } from 'react-router-dom';
import { ReactComponent as AppLogo } from '@assets/images/login_logo.svg';
import { ReactComponent as LenderLogo } from '@assets/images/lender-logo.svg';
import { useLocation } from 'react-router-dom';

const AuthLayout: React.FC = () => {
  const location = useLocation();

  const sellerRoute = location.pathname.includes('seller');

  return (
    <div className="auth-layout min-h-screen bg-grey-50 px-4 py-6 flex justify-center items-center flex-col overflow-hidden">
      <div className="relative flex justify-center pt-16 md:pt-0 pb-16">
        {sellerRoute ? (
          <div className="flex flex-col items-center font-semibold">
            <LenderLogo className="relative z-5" />
          </div>
        ) : (
          <AppLogo className="relative z-5" />
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default AuthLayout;
