import React from 'react';
const CustomRegistration = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className="w-6 h-6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path
          id="Icon"
          d="M18.6673 3.02539V8.53278C18.6673 9.27951 18.6673 9.65288 18.8126 9.9381C18.9405 10.189 19.1444 10.393 19.3953 10.5208C19.6805 10.6661 20.0539 10.6661 20.8007 10.6661H26.308M21.334 17.3327H10.6673M21.334 22.666H10.6673M13.334 11.9993H10.6673M18.6673 2.66602H11.734C9.49377 2.66602 8.37367 2.66602 7.51802 3.10199C6.76537 3.48548 6.15345 4.09741 5.76996 4.85005C5.33398 5.7057 5.33398 6.82581 5.33398 9.06602V22.9327C5.33398 25.1729 5.33398 26.293 5.76996 27.1486C6.15345 27.9013 6.76537 28.5132 7.51802 28.8967C8.37367 29.3327 9.49377 29.3327 11.734 29.3327H20.2673C22.5075 29.3327 23.6276 29.3327 24.4833 28.8967C25.2359 28.5132 25.8478 27.9013 26.2313 27.1486C26.6673 26.293 26.6673 25.1729 26.6673 22.9327V10.666L18.6673 2.66602Z"
          stroke={color}
          strokeWidth="2.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export default CustomRegistration;
