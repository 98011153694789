import { LenderStatusEnum } from '@types';
import { StatusChipVariant } from '@components/widgets/StatusChip';

export const LENDER_TABLE_ROWS: Record<string, any>[] = [
  {
    lender: 'Macuarie',
    status: LenderStatusEnum.Active,
    renderRef: 'A-123457'
  },
  {
    lender: 'CommBank',
    status: LenderStatusEnum.Pending,
    renderRef: 'A-342332'
  },
  {
    lender: 'Macquarie',
    status: LenderStatusEnum.Active,
    renderRef: 'A-123457'
  }
];

export const LENDER_STATUS_VARIANT: Record<
  LenderStatusEnum,
  StatusChipVariant
> = {
  [LenderStatusEnum.Active]: 'success1',
  [LenderStatusEnum.Pending]: 'info2',
  [LenderStatusEnum.InvitationExpired]: 'error',
  [LenderStatusEnum.Revoked]: 'text'
};
export const LENDER_LABEL_VARIANT: Record<LenderStatusEnum, StatusChipVariant> =
  {
    [LenderStatusEnum.Active]: 'success1',
    [LenderStatusEnum.Pending]: 'info2',
    [LenderStatusEnum.InvitationExpired]: 'error',
    [LenderStatusEnum.Revoked]: 'text'
  };

export const LENDER_ACTION_STATUS_VARIANT: Record<LenderStatusEnum, string> = {
  [LenderStatusEnum.Active]: '',
  [LenderStatusEnum.Pending]: '',
  [LenderStatusEnum.InvitationExpired]: '',
  [LenderStatusEnum.Revoked]: 'text-red'
};
