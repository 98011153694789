import React, { useEffect, useState, useRef } from 'react';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { useInvoiceDetailStore } from '@stores';
import { Tooltip } from 'react-tooltip';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { SEARCH_ADDRESS, GET_ADDRESS, post } from '@services';
import Select from '@components/forms/Select';
import { Chip, Radio } from '@material-tailwind/react';
import { Option } from '@material-tailwind/react';
type Props = {
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
};

const SellerDetailStep: React.FC<Props> = ({ onClose, onPrevious, onNext }) => {
  const phoneRegExp = /^(04\d{8}|\+614\d{8}|614\d{8})$/;
  const [items, setItems] = useState([
    {
      text: ''
    }
  ]);
  const states = [
    { value: '', label: 'Select a State' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' }
  ];
  const sellerFormSchema = yup
    .object({
      applicationType: yup.string(),
      email: yup
        .string()
        .required('Please enter your email.')
        .email('Please provide a valid email address'),
      firstName: yup
        .string()
        .required('Please enter the seller’s first name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      lastName: yup.string().required('Please enter the seller’s last name'),
      mobile: yup
        .string()
        .matches(
          phoneRegExp,
          'Invalid mobile number.  Please enter a valid mobile.'
        )
        .required('Please enter your mobile.'),
      address: yup.string().required('Please provide the seller’s address'),
      state: yup.string().required('Please select a state.'),
      postcode: yup.string().required('Please enter the postcode.'),
      suburb: yup.string().required('Please enter the suburb.'),
      bsb: yup.string().required('Please enter the bsb.'),
      accountNumber: yup.string().required('Please enter the account number.'),
      businessName: yup.string().when('applicationType', {
        is: 'business',
        then: (schema) => schema.required('Please enter the entity name.'),
        otherwise: (schema) => schema.notRequired()
      }),
      businessNumber: yup.string().when('applicationType', {
        is: 'business',
        then: (schema) =>
          schema
            .required('Please enter the ABN or ACN.')
            .transform((value) => value.replace(/\s/g, ''))
            .matches(/^\d+$/, 'Only numbers and spaces are allowed.')
            .test(
              'length',
              'ABN must be 11 digits or ACN must be 9 digits.',
              (value) => value?.length === 11 || value?.length === 9
            ),
        otherwise: (schema) => schema.notRequired()
      })
    })
    .required();
  const { invoiceDetailData, setInvoiceDetailData } = useInvoiceDetailStore();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(sellerFormSchema),
    defaultValues: {
      applicationType: invoiceDetailData.seller.applicationType,
      email: invoiceDetailData.seller.email,
      firstName: invoiceDetailData.seller.firstName,
      lastName: invoiceDetailData.seller.lastName,
      mobile: invoiceDetailData.seller.mobile,
      address: invoiceDetailData.seller.address,
      postcode: invoiceDetailData.seller.postcode,
      suburb: invoiceDetailData.seller.suburb,
      bsb: invoiceDetailData.seller.bsb,
      accountNumber: invoiceDetailData.seller.accountNumber,
      businessName: invoiceDetailData.seller.businessName,
      businessNumber: invoiceDetailData.seller.businessNumber
    }
  });
  useEffect(() => {
    if (invoiceDetailData?.seller) {
      setValue('email', invoiceDetailData.seller.email || '');
      setValue('firstName', invoiceDetailData.seller.firstName || '');
      setValue('lastName', invoiceDetailData.seller.lastName || '');
      setValue('mobile', invoiceDetailData.seller.mobile || '');
      setValue('address', invoiceDetailData.seller.address || '');
      setValue('postcode', invoiceDetailData.seller.postcode || '');
      setValue('state', invoiceDetailData.seller.state || '');
      setValue('suburb', invoiceDetailData.seller.suburb || '');
      setValue('bsb', invoiceDetailData.seller.bsb || '');
      setValue('accountNumber', invoiceDetailData.seller.accountNumber || '');
      setValue('businessName', invoiceDetailData.seller.businessName || '');
      setValue('businessNumber', invoiceDetailData.seller.businessNumber || '');
    }
  }, [invoiceDetailData, setValue]);
  const {
    firstName,
    lastName,
    email,
    mobile,
    state,
    postcode,
    suburb,
    address,
    businessName,
    businessNumber,
    bsb,
    accountNumber,
    applicationType
  } = watch();

  const onSubmit = async (data: any) => {
    const seller: any = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      address: data.address,
      postcode: data.postcode,
      suburb: data.suburb,
      bsb: data.bsb,
      accountNumber: data.accountNumber,
      state: data.state,
      isDone: true
    };
    if (applicationType === 'business') {
      seller.businessName = data.businessName;
      seller.businessNumber = data.businessNumber;
    }
    setInvoiceDetailData({ ...invoiceDetailData, seller });
    onNext();
  };
  const handleOnSelect = (item: any) => {
    if (!item || !item.placeId) {
      setValue('suburb', '');
      setValue('state', '');
      setValue('postcode', '');
      return;
    }
    setValue('address', item.text.split(',')[0]);
    post(GET_ADDRESS, { placeId: item.placeId }).then((data: any) => {
      if (data.place) {
        setValue('suburb', data.place?.neighborhood);
        if (data.place?.region) {
          const stateObjs = states.filter(
            (state) => state.label === data.place?.region
          );
          if (stateObjs.length > 0) {
            setValue('state', stateObjs[0].value);
          }
        }
        setValue('postcode', data.place?.postalCode);
      }
    });
  };
  const handleOnSearch = (text: any) => {
    if (text.length < 1) {
      return;
    }
    setValue('address', text);
    post(SEARCH_ADDRESS, { text }).then((data: any) => {
      const addresses = data.addresses.map((address: any, index: number) => ({
        ...address,
        id: index,
        displayText: address.text.split(',')[0]
      }));
      if (addresses) {
        setItems(addresses);
      }
    });
  };

  const handleStateChange = (e: any) => {
    setValue('state', e);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">Seller</h3>
      <div className="mb-4">
        <p className="text-grey-700 text-sm mb-1.5 font-semibold">
          Is the seller a personal or business seller?
        </p>

        <Radio
          name="type"
          containerProps={{
            className: 'p-2'
          }}
          checked={applicationType === 'personal'}
          onChange={() => setValue('applicationType', 'personal')}
          label="Personal"
        />
        <Radio
          name="type"
          containerProps={{
            className: 'p-2 ml-4'
          }}
          checked={applicationType === 'business'}
          onChange={() => setValue('applicationType', 'business')}
          label="Business"
        />
      </div>
      {applicationType === 'business' && (
        <>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">
              Entity name
            </p>
            <Input
              placeholder="Entity name"
              {...register(`businessName`)}
              onKeyDown={(event) => {
                if (!/[A-Za-z\s]+/i.test(event.key)) {
                  event.preventDefault();
                }
              }}
              icon={errors.businessName && <ErrorIcon />}
              value={businessName}
            />
            {errors.businessName && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.businessName.message}`}
              </span>
            )}
          </div>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">
              ABN / ACN
            </p>
            <Input
              placeholder="ABN / ACN"
              {...register(`businessNumber`)}
              icon={errors.businessNumber && <ErrorIcon />}
              value={businessNumber}
            />
            {errors.businessNumber && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.businessNumber.message}`}
              </span>
            )}
          </div>
          <h3 className="text-xl font-semibold text-grey-900 mb-6">
            Contact details
          </h3>
        </>
      )}
      {applicationType && (
        <>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">
              First name
            </p>
            <Input
              placeholder="First name"
              {...register(`firstName`)}
              onKeyDown={(event) => {
                if (!/[A-Za-z]+/i.test(event.key)) {
                  event.preventDefault();
                }
              }}
              icon={errors.firstName && <ErrorIcon />}
              value={firstName}
            />
            {errors.firstName && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.firstName.message}`}
              </span>
            )}
          </div>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">
              Last name
            </p>
            <Input
              placeholder="Last name"
              {...register(`lastName`)}
              onKeyDown={(event) => {
                if (!/[A-Za-z]+/i.test(event.key)) {
                  event.preventDefault();
                }
              }}
              icon={errors.lastName && <ErrorIcon />}
              value={lastName}
            />
            {errors.lastName && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.lastName.message}`}
              </span>
            )}
          </div>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">Email</p>
            <Input
              placeholder="Email"
              {...register(`email`)}
              value={email}
              icon={errors.email && <ErrorIcon />}
            />
            {errors.email && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.email.message}`}
              </span>
            )}
          </div>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">Mobile</p>
            <Input
              placeholder="Mobile number"
              {...register(`mobile`)}
              icon={errors.mobile && <ErrorIcon />}
              value={mobile}
            />
            {errors.mobile && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.mobile.message}`}
              </span>
            )}
          </div>
          {applicationType === 'business' && (
            <h3 className="text-xl font-semibold text-grey-900 mb-6">
              Principle place of business
            </h3>
          )}
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">Address</p>
            <ReactSearchAutocomplete
              items={items}
              fuseOptions={{ keys: ['text'] }}
              resultStringKeyName="displayText"
              inputDebounce={500}
              showNoResults={false}
              onSearch={handleOnSearch}
              formatResult={(item: any) => item.text}
              autoFocus={false}
              placeholder="Search Address"
              onSelect={handleOnSelect}
              showIcon={false}
              inputSearchString={address}
              styling={{ borderRadius: '8px' }}
              className="!border-grey-300 focus:!border-blue-500 !text-base !shadow-none bg-white text-grey-900 z-50 custom-auto-complete"
            />

            {errors.address && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.address.message}`}
              </span>
            )}
          </div>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">Suburb</p>
            <Input
              placeholder="Suburb"
              {...register(`suburb`)}
              value={suburb}
              icon={errors.suburb && <ErrorIcon />}
            />

            {errors.suburb && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.suburb.message}`}
              </span>
            )}
          </div>
          <div className="mb-5 flex flex-row justify-start gap-5">
            <div className="min-w-[260px]">
              <span className="text-[14px] text-grey-700 font-medium">
                State
              </span>
              <Select
                placeholder="Select a State"
                {...register(`state`)}
                value={state}
                onChange={handleStateChange}>
                {states.map((state, index) => (
                  <Option
                    key={index}
                    value={state.value}
                    disabled={state.value === ''}>
                    {state.label}
                  </Option>
                ))}
              </Select>
              {errors.state && (
                <span className="text-error-500 text-2sm mt-[6px]">
                  {`${errors.state.message}`}
                </span>
              )}
            </div>
            <div className="w-auto">
              <span className="text-[14px] text-grey-700 font-medium">
                Postcode
              </span>
              <Input
                placeholder="Postcode"
                {...register(`postcode`)}
                className="min-w-fit"
                value={postcode}
                icon={errors.postcode && <ErrorIcon />}
              />

              {errors.postcode && (
                <span className="text-error-500 text-2sm mt-[6px]">
                  {`${errors.postcode.message}`}
                </span>
              )}
            </div>
          </div>
          <h3 className="text-xl font-semibold text-grey-900 mb-6">
            Bank account details
          </h3>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">BSB</p>
            <Input
              placeholder="BSB"
              {...register(`bsb`)}
              value={bsb}
              icon={errors.bsb && <ErrorIcon />}
            />
            {errors.bsb && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.bsb.message}`}
              </span>
            )}
          </div>
          <div className="mb-5">
            <p className="text-sm text-grey-700 font-medium mb-1.5">
              Account number
            </p>
            <Input
              placeholder="Account number"
              {...register(`accountNumber`)}
              value={accountNumber}
              icon={errors.accountNumber && <ErrorIcon />}
            />
            {errors.accountNumber && (
              <span className="text-error-500 text-2sm mt-[6px]">
                {`${errors.accountNumber.message}`}
              </span>
            )}
          </div>
        </>
      )}
      <Tooltip
        id="badge-tooltip"
        place="bottom"
        className="rounded-lg z-10 w-[320px] text-sm !text-grey-700 bg-[#F2F4F7] bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-4"
        offset={2}></Tooltip>

      <div className="flex justify-between border-t border-t-grey-200 pt-5 mt-4  gap-3">
        <div className="flex flex-row gap-2">
          <Button
            className="bg-white text-grey-700 border-grey-300 !text-sm"
            variant="outlined"
            onClick={onPrevious}>
            Previous
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button type="submit" className="text-white bg-primary !text-sm">
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SellerDetailStep;
