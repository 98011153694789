import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  element: React.ReactElement;
  allowedRoles: string[];
  redirectPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  allowedRoles,
  redirectPath
}) => {
  const userId = localStorage.getItem('userId');

  const lenders = localStorage.getItem(`lenders_${userId}`);
  const brokers = localStorage.getItem(`brokers_${userId}`);

  if (lenders != null) {
    const lender = JSON.parse(lenders)[0];
    const userRole = lender.roles[0];
    if (!userRole || !allowedRoles.includes(userRole)) {
      // Redirect to the specified path if the user's role is not allowed
      return <Navigate to={redirectPath} replace />;
    }
  } else {
    return <Navigate to={redirectPath} replace />;
  }

  // Render the protected component if the user's role is allowed
  return element;
};

export default ProtectedRoute;
