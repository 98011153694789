type ISemiCircleProgress = {
  strokeWidth: number;
  strokeLinecap?: 'butt' | 'round' | 'square' | 'inherit';

  percentage: number;
  percentageSeperator?: string;
  size: {
    width: number;
    height: number;
  };
  strokeColor?: string;
  fontStyle?: {
    fontSize: string;
    fontFamily?: string;
    fontWeight: string;
    fill: string;
  };
  hasBackground?: boolean;
  bgStrokeColor?: string;
};

const SemiCircleProgress = ({
  strokeWidth,
  percentage,
  strokeColor,
  size,
  strokeLinecap,
  hasBackground = false,
  bgStrokeColor
}: ISemiCircleProgress) => {
  // Clamp percentage between 0 and 100
  const validPercentage = Math.min(Math.max(percentage || 0, 0), 100);

  // Ensure positive stroke width with fallback
  const validStrokeWidth = Math.max(strokeWidth || 1, 1);

  // Ensure positive size dimensions with fallbacks
  const validSize = {
    width: Math.max(size?.width || 100, 1),
    height: Math.max(size?.height || 100, 1)
  };

  const radius = 50 - validStrokeWidth / 2;
  const circumference = 1.1 * Math.PI * radius;
  const strokeDashoffset =
    circumference - (validPercentage / 100) * circumference;
  const bgStrokeDashoffset = circumference - 1 * circumference;
  const pathDescription = 'M5,64 a1,1 0 0,1 90,0';

  return (
    <svg
      className="_half-circular-progress w-[200px] h-[200px] md:w-full md:h-full"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {hasBackground && (
        <path
          cx="45"
          cy="45"
          r="32"
          d={pathDescription}
          style={{
            transition: 'stroke-dashoffset 0.35s',
            stroke: bgStrokeColor || '#d3d3d3',
            strokeLinecap: strokeLinecap || 'round',
            strokeDasharray: `${circumference}`,
            strokeDashoffset: `${bgStrokeDashoffset}`,
            strokeWidth: `${validStrokeWidth}`
          }}
          fill="none"
        />
      )}
      <path
        cx="45"
        cy="45"
        r="32"
        d={pathDescription}
        style={{
          transition: 'stroke-dashoffset 0.35s',
          stroke: strokeColor || '#04001b',
          strokeLinecap: strokeLinecap || 'round',
          strokeDasharray: `${circumference}`,
          strokeDashoffset: `${strokeDashoffset}`,
          strokeWidth: `${validStrokeWidth}`
        }}
        fill="none"
      />
      <animate
        attributeName="stroke-dashoffset"
        from="283"
        to="0"
        dur="1s"
        fill="freeze"
      />
    </svg>
  );
};
export default SemiCircleProgress;
