import clsx from 'clsx';
import { ReactComponent as CloseIcon } from '@assets/icons/x_close_icon.svg';
import { InspectionVehicleDetailsSection } from '@types';
import StatusChip from '@components/widgets/StatusChip';
import { useMediaQuery } from 'usehooks-ts';
import { Button } from '@material-tailwind/react';
import {
  NevdisSecurityCheckEnum,
  DocumentFormType,
  InspectionSecurityStatusEnum
} from '@types';
import {
  DETAILS_VIEW_REGISTRATION,
  PPSR_VIEW,
  STOLEN_VIEW,
  WRITTEN_OFF_VIEW,
  ADDRESS_VIEW,
  GRANTOR_INDIVIDUAL_VIEW,
  GRANTOR_ORG_VIEW,
  SECURED_INDIVIDUAL_PARTIES_VIEW,
  SECURED_ORG_PARTIES_VIEW,
  COMPLIANCE_VIEW
} from '@constants';
import _get from 'lodash/get';
import _map from 'lodash/map';
interface IProps {
  type: DocumentFormType;
  status: InspectionSecurityStatusEnum;
  data: any;
  isCollapsed: boolean;
  setCollapse: (value: boolean) => void;
  variant: any;
  statusChipLabel: string;
}
export const RightDetailsBar = ({
  type,
  status,
  data,
  isCollapsed,
  setCollapse,
  variant,
  statusChipLabel
}: IProps) => {
  const isMobile = useMediaQuery('(max-width: 720px)');
  const handleClose = () => {
    setCollapse(true);
  };

  return (
    <div
      className={clsx(
        'fixed bg-opacity-0 w-screen min-h-screen flex ',
        isCollapsed ? '!w-0' : 'z-70'
      )}>
      <div
        className={clsx(
          'w-full h-full',
          isCollapsed || isMobile ? 'hidden' : 'z-80 fixed'
        )}
        onClick={handleClose}></div>
      <div
        className={clsx(
          'flex flex-col sidebarMenu fixed h-full right-0 bg-white xl:w-1/4 lg:w-1/3 md:w-1/2 w-4/5 border shadow-xl rightBar z-100  overflow-y-scroll no-scrollbar clear-both',
          isCollapsed ? 'collapsed' : 'expanded'
        )}>
        <div className="flex flex-col h-full">
          <div
            className={clsx(
              'flex p-6 gap-3',
              statusChipLabel && statusChipLabel.length > 16 && 'flex-col'
            )}>
            <h3 className="text-lg font-semibold text-grey-900 whitespace-nowrap">
              {
                InspectionVehicleDetailsSection[
                  type as keyof typeof InspectionVehicleDetailsSection
                ]
              }
            </h3>
            <StatusChip
              variant={variant}
              clsName={clsx(
                'border text-xs font-normal flex justify-center items-center whitespace-nowrap'
              )}
              label={statusChipLabel}
            />
          </div>
          <div
            className={clsx(
              'flex p-6 absolute right-0 justify-center items-start mt-0 z-10',
              isCollapsed && 'hidden'
            )}>
            <div
              className="cursor-pointer hover:shadow-paper hover:border"
              onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
          <NevdisCheckCard
            type={type}
            status={status}
            isCollapsed={isCollapsed}
            setCollapse={setCollapse}
            data={data}
            variant={variant}
            statusChipLabel={statusChipLabel}
          />
        </div>
      </div>
    </div>
  );
};
interface IProps {
  type: DocumentFormType;
  status: InspectionSecurityStatusEnum;
  data: any;
  isCollapsed: boolean;
  setCollapse: (value: boolean) => void;
  variant: any;
  statusChipLabel: string;
}

const NevdisCheckCard = ({
  type,
  status,
  data,
  isCollapsed,
  setCollapse,
  variant,
  statusChipLabel
}: IProps) => {
  const states = {
    ACT: 'Australian Capital Territory',
    NSW: 'New South Wales',
    NT: 'Northern Territory',
    QLD: 'Queensland',
    SA: 'South Australia',
    TAS: 'Tasmania',
    VIC: 'Victoria',
    WA: 'Western Australia'
  };
  const handleClose = () => {
    setCollapse(true);
  };
  if (
    [
      InspectionSecurityStatusEnum.notPerformed,
      InspectionSecurityStatusEnum.notFound
    ].includes(status)
  ) {
    return null;
  }
  switch (type) {
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_RAV:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Compliance Details{' '}
                  {data.rav.results && data.rav.results.length > 0 && (
                    <>(1 / 2)</>
                  )}
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    COMPLIANCE_VIEW,
                    (row: any, index: number) =>
                      data.rav.results &&
                      data.rav.results.length > 0 &&
                      _get(data.rav.results[0], row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {_get(data.rav.results[0], row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
                {data.rav.results && data.rav.results.length > 1 && (
                  <>
                    <p className="font-semibold text-grey-800">
                      Compliance Details{' '}
                      {data.rav.results && data.rav.results.length > 1 && (
                        <>(2 / 2)</>
                      )}
                    </p>
                    <div className="flex flex-col border-y border-grey-200 w-full">
                      {_map(
                        COMPLIANCE_VIEW,
                        (row: any, index: number) =>
                          data.rav.results &&
                          data.rav.results.length > 0 &&
                          _get(data.rav.results[1], row.keyPath) && (
                            <div
                              key={index}
                              className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                              <p className="text-grey-500 text-sm font-medium leading-5">
                                {row.displayName}
                              </p>
                              <p className="text-sm font-medium text-grey-950 text-right">
                                {_get(data.rav.results[1], row.keyPath)}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_PPSR:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <div className="flex flex-col border-b border-grey-200 w-full">
                  {_map(data.ppsr.ppsrData, (ppsrData: any, dIndex: number) => {
                    return (
                      <>
                        <p
                          className="font-semibold text-grey-800 py-6 border-b first:pt-0 "
                          key={'ppsrTitle' + dIndex}>
                          Registration {dIndex + 1} /{' '}
                          {data.ppsr.ppsrData.length}
                        </p>
                        {_map(PPSR_VIEW, (row: any, index: number) => {
                          if (_get(ppsrData, row.keyPath) && !row.type)
                            return (
                              <div
                                key={'ppsrData' + index}
                                className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                <p className="text-grey-500 text-sm font-medium leading-5">
                                  {row.displayName}
                                </p>
                                <p className="text-sm font-medium text-grey-950 text-right">
                                  {_get(ppsrData, row.keyPath)}
                                </p>
                              </div>
                            );
                          else if (
                            _get(ppsrData, row.keyPath) &&
                            row.type === 'array'
                          ) {
                            const dataRow = _get(ppsrData, row.keyPath);
                            return (
                              _get(ppsrData, row.keyPath).length > 0 && (
                                <>
                                  <p
                                    className="font-semibold py-3.5 border-b border-grey-200 text-grey-800"
                                    key={'ppsrData' + index}>
                                    {row.displayName}
                                  </p>
                                  {_map(dataRow, (item: any, i: number) => {
                                    if (dataRow.length === 0) return null;
                                    return _map(
                                      row.key === 'grantors' &&
                                        item.grantorType === 'Individual'
                                        ? GRANTOR_INDIVIDUAL_VIEW
                                        : row.key !== 'grantors' &&
                                          item.grantorType === 'Individual'
                                        ? SECURED_INDIVIDUAL_PARTIES_VIEW
                                        : row.key === 'grantors' &&
                                          item.grantorType === 'Organisation'
                                        ? GRANTOR_ORG_VIEW
                                        : SECURED_ORG_PARTIES_VIEW,
                                      (subRow: any, subIndex: number) => (
                                        <div
                                          key={
                                            (row.key === 'grantors'
                                              ? 'grantors_'
                                              : 'secured_parties_') +
                                            dIndex +
                                            '_' +
                                            i +
                                            '_' +
                                            subIndex
                                          }
                                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                          <p className="text-grey-500 text-sm font-medium leading-5">
                                            {subRow.displayName}
                                          </p>
                                          <p className="text-sm font-medium text-grey-950 text-right">
                                            {_get(item, subRow.keyPath)}
                                          </p>
                                        </div>
                                      )
                                    );
                                  })}
                                </>
                              )
                            );
                          } else if (
                            _get(data.ppsr.ppsrData, row.keyPath) &&
                            row.type === 'object'
                          ) {
                            return (
                              <>
                                <p
                                  className="font-semibold border-b border-grey-200 py-3.5 text-grey-800"
                                  key={'addresseeTitle_' + dIndex}>
                                  {row.displayName}
                                </p>
                                {_map(
                                  ADDRESS_VIEW,
                                  (subRow: any, subIndex: number) => {
                                    const dataRow = _get(
                                      data.ppsr.ppsrData,
                                      row.keyPath
                                    );
                                    return (
                                      _get(dataRow, subRow.keyPath) && (
                                        <div
                                          key={
                                            'addressee_' +
                                            dIndex +
                                            '_' +
                                            subIndex
                                          }
                                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                          <p className="text-grey-500 text-sm font-medium leading-5">
                                            {subRow.displayName}
                                          </p>
                                          <p className="text-sm font-medium text-grey-950 text-right">
                                            {_get(dataRow, subRow.keyPath)}
                                          </p>
                                        </div>
                                      )
                                    );
                                  }
                                )}
                              </>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_REGISTRATION:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Registration Details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    DETAILS_VIEW_REGISTRATION,
                    (row: any, index: number) =>
                      _get(data.registration, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {row.key === 'regoStatus' &&
                            status === 'REGISTRATION_EXPIRED'
                              ? 'Registration Expired'
                              : _get(data.registration, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_STOLEN:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                {_map(data.stolen.stolenDetails, (item, i) => (
                  <>
                    <p className="font-semibold text-grey-800" key={i}>
                      Stolen report details ({i + 1}/
                      {data.stolen.stolenDetails.length})
                    </p>
                    <div className="flex flex-col border-y border-grey-200 w-full">
                      {_map(
                        STOLEN_VIEW,
                        (row: any, index: number) =>
                          _get(item, row.keyPath) && (
                            <div
                              key={index}
                              className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                              <p className="text-grey-500 text-sm font-medium leading-5">
                                {row.displayName}
                              </p>
                              <p className="text-sm font-medium text-grey-950 text-right">
                                {_get(item, row.keyPath)}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_WRITTEN_OFF:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                {_map(data.writtenOff.writtenOffDetails, (item, i) => (
                  <>
                    <p className="font-semibold text-grey-800" key={i}>
                      Written-off Report Details ({i + 1}/
                      {data.writtenOff.writtenOffDetails.length})
                    </p>
                    <div className="flex flex-col border-y border-grey-200 w-full">
                      {_map(
                        WRITTEN_OFF_VIEW,
                        (row: any, index: number) =>
                          _get(item, row.keyPath) && (
                            <div
                              key={index}
                              className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                              <p className="text-grey-500 text-sm font-medium leading-5">
                                {row.displayName}
                              </p>
                              <p className="text-sm font-medium text-grey-950 text-right">
                                {_get(item, row.keyPath)}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ))}
              </div>
              {!isCollapsed && (
                <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return <> </>;
  }
};
export default RightDetailsBar;
