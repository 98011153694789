import React from 'react';
const TakePicIcon = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_9270_61229)">
        <rect x="2.5" y="1" width="56" height="56" rx="28" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4654 19.8333C28.3736 19.8332 28.2937 19.8332 28.2175 19.8378C27.2534 19.8962 26.4093 20.5047 26.049 21.4008C26.0205 21.4716 25.9953 21.5474 25.9663 21.6346L25.9601 21.6531C25.9214 21.769 25.913 21.793 25.9059 21.8108C25.7858 22.1095 25.5045 22.3123 25.1831 22.3317C25.1643 22.3329 25.136 22.3333 25.0053 22.3333L24.9588 22.3333C24.6964 22.3332 24.5036 22.3332 24.3387 22.3495C22.7576 22.5061 21.5069 23.7569 21.3502 25.3379C21.3339 25.5028 21.3339 25.6879 21.334 25.9377L21.334 32.5343C21.334 33.2051 21.334 33.7588 21.3708 34.2098C21.4091 34.6782 21.4912 35.1088 21.6973 35.5132C22.0169 36.1404 22.5268 36.6504 23.154 36.9699C23.5585 37.176 23.9891 37.2582 24.4575 37.2964C24.9085 37.3333 25.4621 37.3333 26.1329 37.3333H34.8684C35.5392 37.3333 36.0929 37.3333 36.5438 37.2964C37.0123 37.2582 37.4429 37.176 37.8473 36.9699C38.4745 36.6504 38.9844 36.1404 39.304 35.5132C39.5101 35.1088 39.5922 34.6782 39.6305 34.2098C39.6673 33.7588 39.6673 33.2052 39.6673 32.5344L39.6673 25.9377C39.6674 25.6879 39.6674 25.5028 39.6511 25.3379C39.4944 23.7569 38.2437 22.5061 36.6626 22.3495C36.4977 22.3332 36.3049 22.3332 36.0426 22.3333L35.996 22.3333C35.8653 22.3333 35.8371 22.3329 35.8182 22.3317C35.4969 22.3123 35.2155 22.1095 35.0954 21.8108C35.0883 21.793 35.0799 21.769 35.0412 21.6531L35.0351 21.6345C35.0061 21.5475 34.9808 21.4716 34.9523 21.4008C34.5921 20.5047 33.7479 19.8962 32.7839 19.8378C32.7076 19.8332 32.6277 19.8332 32.5359 19.8333H28.4654ZM33.834 29.4166C33.834 31.2575 32.3416 32.7499 30.5007 32.7499C28.6597 32.7499 27.1673 31.2575 27.1673 29.4166C27.1673 27.5756 28.6597 26.0833 30.5007 26.0833C32.3416 26.0833 33.834 27.5756 33.834 29.4166Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9270_61229"
          x="0.5"
          y="0"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9270_61229"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9270_61229"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default TakePicIcon;
