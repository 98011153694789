import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
interface AuthStoreState {
  token: string;
  user?: any;
  lenders?: any;
  selectLender?: any;
  setSelectLender: (lender: any) => void;
  setLenders: (lenders: any) => void;
  setToken: (token: string) => void;
  setUser: (user: any) => void;
}

export const useAuthStore = create<AuthStoreState>()(
  devtools(
    persist(
      (set) => ({
        token: '',
        user: {
          initials: '',
          avatarColour: 'DD3434'
        },
        lenders: [],
        selectLender: {},
        setSelectLender: (lender) => set({ selectLender: lender }),
        setLenders: (org) => set({ lenders: org }),
        setToken: (token) => set({ token }),
        setUser: (user) => set({ user })
      }),
      {
        name: 'auth',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
