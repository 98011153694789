import React, { useState, memo } from 'react';
import InnerSidebar from '@components/widgets/InnerSidebar';
import ProfileDetails from './profileDetails';
import InitialsAvatar from './initialsAvatar';
import ChangePassword from './changePassword';
import Lender from '@pages/lender';

const Profile = () => {
  const [activeItem, setActiveItem] = useState('mydetails');
  const ProfileDetailsMemo = memo(ProfileDetails);
  const InitialsAvatarMemo = memo(InitialsAvatar);
  const ChangePasswordMemo = memo(ChangePassword);
  
  const renderContent = () => {
    switch (activeItem) {
      case 'mydetails':
        return <ProfileDetailsMemo title="My Details" />;
      case 'initialsavatar':
        return <InitialsAvatarMemo title="Initials Avatar" />;
      case 'changepassword':
        return <ChangePasswordMemo title="Change Password" />;

      default:
        return null;
    }
  };

  return (
    <div
      className="flex flex-col md:flex-row"
      style={{ minHeight: 'calc(100vh - 120px)' }}>
      <InnerSidebar activeItem={activeItem} onItemSelect={setActiveItem} />
      <div className="right-content w-full md:w-3/4 h-full overflow-auto p-6 border border-gray-300 md:border-l-0">
        {renderContent()}
      </div>
    </div>
  );
};

export default Profile;
