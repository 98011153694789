import DocumentCard from '@pages/Inspections/components/RightPanelCards/DocumentCard';
import BankCheckCard from '../RightPanelCards/BankCheckCard';
import { useInspectionDetailStore } from '../../../../stores/inspection';
import { BankSecurityCheckEnum, DocumentFormEnum } from '@types';

const BankStatement = () => {
  const { activePanel, rightBarStatus, inspectionDetailData } =
    useInspectionDetailStore();

  return (
    <>
      {activePanel.activeSection === 'BANK_STATEMENTS' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_BANK} />
      )}
      {activePanel.activeSection === 'bankAuthenticity' && (
        <BankCheckCard
          type={BankSecurityCheckEnum.BANK_SECURITY_AUTHENTICITY}
          status={rightBarStatus}
          data={inspectionDetailData.inspectionChecks.bankStatement}
        />
      )}
      {activePanel.activeSection === 'bankStatement' && (
        <BankCheckCard
          type={BankSecurityCheckEnum.BANK_SECURITY_DATA_EDIT}
          status={rightBarStatus}
          data={inspectionDetailData.inspectionChecks.bankStatement}
        />
      )}
      {activePanel.activeSection === 'BANK_STATEMENT_ADDRESS' && (
        <BankCheckCard
          type={BankSecurityCheckEnum.BANK_STATEMENT_ADDRESS}
          status={rightBarStatus}
          data={inspectionDetailData.inspectionChecks.address}
        />
      )}
    </>
  );
};

export default BankStatement;
