import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { GET_PASSWORD_RESET, COMPLETE_PASSWORD_RESET, post } from '@services';
import { useEffect, useState } from 'react';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';

const ResetPassword = () => {
  const navigate = useNavigate();
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/;
  const resetPwdFormSchema = yup
    .object()
    .shape({
      password: yup
        .string()
        .matches(
          passwordRegex,
          'Password must contain at least 8 characters, including at least one number, one symbol, one lowercase letter, and one uppercase letter.'
        )
        .required('Please enter your password.'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), undefined], 'Passwords must match')
        .required('Please enter your password again.')
    })
    .required();
  const {
    register,
    setError,
    watch,
    clearErrors,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(resetPwdFormSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });
  const handlePasswordChange = (e: any) => {
    const value = e.target.value;
    clearErrors('password');
    setValue('password', value);
  };
  const handleConfirmPasswordChange = (e: any) => {
    const value = e.target.value;
    clearErrors('confirmPassword');
    setValue('confirmPassword', value);
  };
  const verifyConfirmPwd = (e: any) => {
    const value = e.target.value;
    if (value !== password) {
      setError('confirmPassword', {
        type: 'custom',
        message: 'Please ensure the passwords entered match.'
      });
    }
  };
  const { password, confirmPassword } = watch();
  const [resetToken, setResetToken] = useState('');
  const { hash } = useLocation();
  const onSubmit = (data: any) => {
    post(
      COMPLETE_PASSWORD_RESET,
      {
        password: data.password,
        resetPwToken: resetToken
      },
      false
    )
      .then(() => {
        navigate('/login');
      })
      .catch((e) => {
        setError('password', {
          type: 'custom',
          message: e.response.data.error.code
        });
      });
  };
  useEffect(() => {
    const token = decodeURIComponent(hash.split('token=')[1]);
    post(GET_PASSWORD_RESET, { resetPwToken: token }, false)
      .then((data) => {
        if (data && data.email) {
          setResetToken(token);
        }
      })
      .catch((e) => {
        if (e.response.data.error.code === 'TokenExpired') {
          navigate('reset-password');
        }
      });
  }, [hash]);
  return (
    <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <h3 className="text-2xl text-grey-900 font-semibold mb-2 text-center">
          Reset password
        </h3>

        <label className="flex flex-col gap-1.5">
          <span className="text-sm font-medium text-grey-700">Password</span>
          <Input
            type="password"
            {...register(`password`)}
            placeholder="Password"
            icon={errors.password && <ErrorIcon />}
            value={password}
            onChange={handlePasswordChange}
          />
          {errors.password && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.password.message}
            </span>
          )}
        </label>

        <label className="flex flex-col gap-1.5">
          <span className="text-sm font-medium text-grey-700">
            Confirm Password
          </span>
          <Input
            type="password"
            {...register(`confirmPassword`)}
            placeholder="Confirm Password"
            icon={errors.confirmPassword && <ErrorIcon />}
            value={confirmPassword}
            onBlur={verifyConfirmPwd}
            onChange={handleConfirmPasswordChange}
          />
          {errors.confirmPassword && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.confirmPassword.message}
            </span>
          )}
        </label>

        <div className="flex flex-col-reverse md:flex-row gap-4">
          <Button
            className="bg-white w-full md:w-1/2 border-grey-300 text-grey-700"
            variant="outlined"
            onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="w-full md:w-1/2 px-4 py-2 bg-primary text-white text-base font-semibold rounded-md shadow-sm normal-case">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ResetPassword;
