import _map from 'lodash/map';
import { DETAILS_VIEW_BANK } from '@constants';
import _get from 'lodash/get';
type Props = {
  bank: object;
};
const BankStatement = ({ bank }: Props) => {
  return (
    <>
      {_map(
        DETAILS_VIEW_BANK,
        (row: any, index: number) =>
          _get(bank, row.keyPath) && (
            <div
              key={index}
              className="flex justify-between md:justify-start flex-row-reverse flex-row md:flex-col items-start gap-2 ">
              <p className="text-base text-grey-700 break-words text-right md:text-left">
                {typeof _get(bank, row.keyPath) === 'object'
                  ? _get(bank, row.keyPath).userEditValue
                    ? _get(bank, row.keyPath).userEditValue
                    : _get(bank, row.keyPath).ocrValue &&
                      _get(bank, row.keyPath).ocrValue
                  : _get(bank, row.keyPath)}
              </p>
              <p className="text-grey-500 text-xs leading-5">
                {row.displayName}
              </p>
            </div>
          )
      )}
    </>
  );
};
export default BankStatement;
