import React, { PropsWithChildren, useRef, useEffect } from 'react';
import { Button, Navbar } from '@material-tailwind/react';
import { ReactComponent as PrimaryCheck } from '@assets/icons/check_activity_success_icon.svg';
import { ReactComponent as AppLogo } from '@assets/images/dark_logo_24.svg';
import { ReactComponent as AppIcon } from '@assets/images/app_logo.svg';
import { ReactComponent as XCloseIcon } from '@assets/icons/white_close.svg';
import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';
import _get from 'lodash/get';
import LayoutFooter from '@components/layouts/LayoutFooter';
import {
  INSPECTION_ONBOARDING_STEP,
  INSPECTION_ONBOARDING_STEP_ORDER,
  INVOICE_TEMPLATE_STEP,
  INVOICE_TEMPLATE_STEP_ORDER
} from '@constants';
import { CreateInspectionStepEnum, CreateInvoiceStepEnum } from '@types';
import { useInspectionDetailStore, useInvoiceDetailStore } from '@stores';

type Props = PropsWithChildren<{
  step: CreateInspectionStepEnum | CreateInvoiceStepEnum;
  successSubmit: boolean;
  onChangeStep: (
    value: CreateInspectionStepEnum | CreateInvoiceStepEnum
  ) => void;
  backToDashboard: () => void;
  onClose: () => void;
  type?: 'inspection' | 'invoice' | 'template';
  title?: string;
}>;

const OnboardingLayout: React.FC<Props> = ({
  step,
  successSubmit,
  onChangeStep,
  backToDashboard,
  onClose,
  children,
  title = 'Create New Inspection',
  type = 'inspection'
}) => {
  const stepOrder =
    type === 'inspection'
      ? INSPECTION_ONBOARDING_STEP_ORDER
      : INVOICE_TEMPLATE_STEP_ORDER;
  const stepList =
    type === 'inspection' ? INSPECTION_ONBOARDING_STEP : INVOICE_TEMPLATE_STEP;
  const activeIndex = stepOrder.findIndex((value) => step === value);

  const isMobile = useMediaQuery('(max-width: 720px)');
  const containerRef = useRef<HTMLUListElement>(null);
  const { inspectionDetailData, setDocumentValidation } =
    useInspectionDetailStore();
  const { invoiceDetailData } = useInvoiceDetailStore();
  const dataDetail =
    type === 'inspection' ? inspectionDetailData : invoiceDetailData;
  const scrollToItem = (index: number) => {
    // Get the item element by index
    if (isMobile) {
      const itemElement = containerRef.current?.children[index] as HTMLElement;
      // Scroll the container to the item element
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  };

  useEffect(() => {
    if (type === 'inspection' && activeIndex > 0) {
      const lender = inspectionDetailData.lender;
      if (!lender.isDone || !lender.template) {
        onChangeStep(0);
        alert('Please select a template first');
      }
    }
    if (type === 'inspection' && activeIndex > 1) {
      setDocumentValidation(true);
    }
  }, [step]);

  const navList = (
    <ul
      ref={containerRef}
      className="flex gap-2 mb-4 mt-2 flex-row items-start lg:items-center lg:gap-6 px-4">
      {stepList.map((step: any, index) => (
        <a
          key={index}
          href="#"
          className={clsx(
            'border border-transparent rounded-lg !text-white font-medium !overflow-visible lg:!overflow-hidden  gap-3 px-3 py-2.5 whitespace-nowrap flex flex-row items-center',
            !(
              activeIndex <= index &&
              index > 0 &&
              !_get(dataDetail, stepList[index - 1].key)
            ) &&
              activeIndex === index &&
              'bg-secondary-dark !border-primary/50'
          )}
          onClick={() => {
            if (
              activeIndex <= index &&
              index > 0 &&
              !_get(dataDetail, stepList[index - 1].key)
            )
              return;
            scrollToItem(index);
            onChangeStep(step.value);
          }}>
          <step.icon className="w-5 h-5" />
          <p className="whitespace-nowrap flex-1">{step.label}</p>
          {type === 'inspection' && (
            <>
              {index === 2 && inspectionDetailData.seller.isDone && (
                <PrimaryCheck />
              )}
              {index === 0 && inspectionDetailData.lender.isDone && (
                <PrimaryCheck />
              )}
              {index === 1 && inspectionDetailData.documentValidation && (
                <PrimaryCheck />
              )}
            </>
          )}
          {type === 'invoice' && (
            <>
              {index === 1 && invoiceDetailData?.seller?.isDone && (
                <PrimaryCheck />
              )}
              {index === 0 && invoiceDetailData?.buyer?.isDone && (
                <PrimaryCheck />
              )}
            </>
          )}
        </a>
      ))}
    </ul>
  );

  return (
    <div className="min-h-screen flex flex-col">
      <div className="bg-secondary min-w-[360px]">
        <div className="min-h-[72px] px-0 py-5 border-b lg:border-none border-b-grey-100 border-opacity-10 flex justify-start md:justify-center md:items-center">
          <div
            className="md:absolute flex flex-row gap-x-3 justify-center items-center left-0 px-4 md:py-4 cursor-pointer"
            onClick={() => backToDashboard()}>
            <AppIcon />
            <AppLogo />
          </div>
          {!successSubmit && !isMobile && (
            <h1 className="text-2xl font-semibold text-white">{title}</h1>
          )}
          <Button
            className="justify-center flex-0 flex-none bg-transparent border-none hover:bg-secondary-dark text-blue-700 font-semibold hover:text-white py-2 px-4 hover:border-transparent rounded flex flex-row items-center capitalize gap-2 right-0 !absolute !shadow-none"
            onClick={() => onClose()}>
            <span className="text-white font-semibold hidden md:block text-base">
              Close
            </span>
            <XCloseIcon style={{ height: '12px', width: '12px' }} />
          </Button>
        </div>
        {!successSubmit && isMobile && (
          <h1 className="relative md:hidden text-2xl font-semibold text-white px-4">
            {title}
          </h1>
        )}
        {!successSubmit && type !== 'template' && (
          <div className="overflow-x-auto overflow-y-hidden py-0">
            <Navbar className="mx-auto max-w-screen-xl px-0 py-0 md:py-4 bg-opacity-100 bg-transparent border-none backdrop-opacity-0">
              <div className="mx-auto flex text-blue-gray-900 justify-start md:justify-center items-center">
                {navList}
              </div>
            </Navbar>
          </div>
        )}
      </div>
      <div className="flex-1 px-10 py-5">
        <div className="flex justify-center py-12 px-0 md:px-10">
          {children}
        </div>
      </div>
      <LayoutFooter />
    </div>
  );
};

export default OnboardingLayout;
