import { useParams } from 'react-router-dom';

const SellerErrorLogin = () => {
  const params = useParams();
  return (
    <div className="px-4 gap-16 flex flex-col justify-center items-center w-full h-screen">
      <div className="mx-auto w-full bg-white gap-6 py-[50px] px-6 rounded-xl shadow-paper relative max-w-[600px]">
        <form className="flex flex-col gap-6">
          <div className="gap-2 flex flex-col">
            <h3 className="text-2xl text-grey-900 font-semibold text-center">
              {params.id === 'TokenExpired'
                ? 'Inspection invite expired'
                : 'Inspection not found'}
            </h3>
            {params.id === 'TokenExpired' && (
              <p className="text-center text-grey-600">
                Please contact your service provider to resend the inspection
                invite.
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default SellerErrorLogin;
