/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import Button from '@components/forms/Button';
import { useForm } from 'react-hook-form';
import { useSellerStore } from '@stores';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { GET_REPORT_DOWNLOAD, START_REPORT_DOWNLOAD, post } from '@services';
import { format, parseISO } from 'date-fns';
const SellerReport = () => {
  useEffect(() => {
    const { setIsHome } = useSellerStore.getState();
    setIsHome(false);
  }, []);
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const [inspection, setInspection] = useState<any>({});
  const [primaryLogo, setPrimaryLogo] = useState('');
  const { hash } = useLocation();
  const [token, setToken] = useState('');
  const {
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    }
  });
  const onSubmit = async () => {
    try {
      const response = await post(
        START_REPORT_DOWNLOAD,
        { token: token },
        false
      );
      if (response.downloadUrl) {
        // Create a URL for the blob
        const url = response.downloadUrl;

        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'inspection_report.pdf';

        // Append to the document, trigger click, and remove
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // Clean up the URL object after a short delay
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 100);
      } else {
        console.error('Download URL not found in the response');
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error('Error downloading report:', error);
      // You might want to show an error message to the user here
    }
  };

  useEffect(() => {
    const userToken =
      hash &&
      hash.split('token=').length > 0 &&
      decodeURIComponent(hash.split('token=')[1]);
    localStorage.clear();
    if (userToken) {
      setToken(userToken);
      post(GET_REPORT_DOWNLOAD, { token: userToken }, false).then((data) => {
        setPrimaryLogo(data.lender.logoUrl);
        setPrimaryColor(`#${data.lender.primaryColour}`);
        setInspection(data.inspection);
      });
    }
  }, []);
  return (
    <div className="px-4 gap-16 flex flex-col justify-center items-center w-full h-screen">
      <div>
        <img
          src={`${primaryLogo}`}
          alt="Uploaded SVG"
          className="max-w-full max-h-20"
        />
      </div>
      <div className="mx-auto w-full bg-white gap-6 py-6 px-6 rounded-xl shadow-paper relative max-w-[500px]">
        <form className="flex flex-col gap-6">
          <div className="flex flex-col">
            <h3 className="text-2xl text-grey-900 font-semibold text-center">
              Inspection complete
            </h3>
          </div>
          <div className="flex flex-col ">
            <div className="flex flex-row justify-between items-center h-[48px] border-t border-grey-200">
              <span className="text-grey-500 text-sm font-medium">
                Reference Number
              </span>
              <span className="text-grey-900 text-sm font-medium">
                {inspection?.lenderReference}
              </span>
            </div>
            <div className="flex flex-row justify-between items-center h-[48px] border-t border-grey-200">
              <span className="text-grey-500 text-sm font-medium">
                Authentik8 ID
              </span>
              <span className="text-grey-900 text-sm font-medium">
                {inspection?.friendlyId}
              </span>
            </div>
            <div className="flex flex-row justify-between items-center h-[48px] border-t border-b border-grey-200">
              <span className="text-grey-500 text-sm font-medium">
                Inspection Created Date
              </span>
              <span className="text-grey-900 text-sm font-medium">
                {inspection?.createdTime
                  ? format(parseISO(inspection.createdTime), 'd MMM yyyy')
                  : 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex flex-col justify-center">
            <Button
              className={clsx(
                'w-full text-white text-md font-semibold normal',
                primaryColor && `!bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={handleSubmit(onSubmit)}>
              Download Inspection Report
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SellerReport;
