import React, { useEffect, useState } from 'react';
import {
  CardCaptureStepEnum,
  DocumentCaptureStepEnum,
  InspectionSecurityStatusEnum
} from '@types';
import { Button } from '@material-tailwind/react';
import Modal from './Modal';
import { useOrgStore, useSellerStore } from '@stores';
import { ReactComponent as Warning } from '@assets/icons/warning_icon.svg';
import { Select } from '@components/forms';
import { Option } from '@material-tailwind/react';
import { post, SET_INSPECTION_DOCUMENT } from '@services';

type Props = {
  open: boolean;
  type: CardCaptureStepEnum | DocumentCaptureStepEnum;
  vin?: string;
  licencePlate?: { rego: string; state: string };
  onClose: () => void;
  onSubmit: () => void;
  setStep: () => void;
};

const ConfirmSellerModal: React.FC<Props> = ({
  open,
  type,
  vin,
  licencePlate,
  onClose,
  onSubmit,
  setStep
}) => {
  const [validate, setValidate] = useState(true);
  const { inspectionChecks, registration, inspectionId, vinObj, numberPlate } =
    useSellerStore();
  const [regoFile, setRegoFile] = useState<any>(registration.file);
  const [plateNumberArr, setPlateNumberArr] = useState<any>([]);
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  const [vinValue, setVinValue] = useState(vin);
  const [plateNumberValue, setPlateNumber] = useState<any>(null);
  const updateFields = (cb: () => void) => {
    let fields2, fileId;
    if (DocumentCaptureStepEnum.registration === type) {
      let updateTimes = 0;
      let finished = 0;
      if (inspectionChecks.vin.userVin !== vinValue) {
        updateTimes++;
        fields2 = {
          vin: {
            vin: vinValue
          }
        };
        fileId = vinObj.file?.fileId;
        const params = {
          inspectionId: inspectionId,
          documentType: CardCaptureStepEnum.vin,
          needsResubmit: false,
          extraFields: fields2
        };
        post(SET_INSPECTION_DOCUMENT, params, false)
          .then(() => {
            finished++;
            if (finished === updateTimes) {
              cb();
            }
          })
          .catch(() => {
            alert('Failed to change fields value for the file.');
          });
      }

      if (plateNumberValue === '1') {
        updateTimes++;
        fields2 = {
          licencePlate: {
            rego: registration.file.fields2.regoCertificate.rego,
            state: registration.file.fields2.regoCertificate.state
          }
        };
        fileId = numberPlate.file?.fileId;
        const params = {
          inspectionId: inspectionId,
          documentType: type,
          needsResubmit: false,
          extraFields: fields2
        };
        post(SET_INSPECTION_DOCUMENT, params, false)
          .then(() => {
            finished++;
            if (finished === updateTimes) {
              cb();
            }
          })
          .catch(() => {
            alert('Failed to change fields value for the file.');
          });
      }
    } else {
      let docType: any = type;
      if (type === CardCaptureStepEnum.vin && vinValue !== vin) {
        fields2 = {
          vin: {
            vin: vinValue
          }
        };
        fileId = vinObj.file?.fileId;
      } else if (
        type === CardCaptureStepEnum.plateNumber &&
        plateNumberValue === '1'
      ) {
        fields2 = {
          licencePlate: {
            rego: registration.file.fields2.regoCertificate.rego,
            state: registration.file.fields2.regoCertificate.state
          }
        };
        fileId = numberPlate.file?.fileId;
      } else {
        docType = DocumentCaptureStepEnum.registration;
        fields2 = {
          regoCertificate: {
            addressLine1: regoFile.fields2.regoCertificate.addressLine1,
            addressLine2: regoFile.fields2.regoCertificate.addressLine2,
            dateOfExpiry: regoFile.fields2.regoCertificate.dateOfExpiry,
            familyName: regoFile.fields2.regoCertificate.familyName,
            givenName: regoFile.fields2.regoCertificate.givenName,
            middleName: regoFile.fields2.regoCertificate.middleName,
            rego:
              type === CardCaptureStepEnum.plateNumber
                ? licencePlate?.rego
                : regoFile.fields2.regoCertificate.rego,
            state:
              type === CardCaptureStepEnum.plateNumber
                ? licencePlate?.state
                : regoFile.fields2.regoCertificate.state,
            vin:
              type === CardCaptureStepEnum.vin
                ? vinValue
                : regoFile.fields2.regoCertificate.vin
          }
        };
        fileId = registration.file?.fileId;
      }
      const params = {
        inspectionId: inspectionId,
        documentType: docType,
        needsResubmit: false,
        extraFields: fields2
      };
      post(SET_INSPECTION_DOCUMENT, params, false)
        .then(() => {
          cb();
        })
        .catch(() => {
          alert('Failed to change fields value for the file.');
        });
    }
  };
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, []);
  useEffect(() => {
    if (
      type !== DocumentCaptureStepEnum.bank &&
      inspectionChecks &&
      registration?.file?.fields2 &&
      ((Object.keys(inspectionChecks.vin).length > 2 &&
        [
          DocumentCaptureStepEnum.registration,
          CardCaptureStepEnum.vin
        ].includes(type) &&
        inspectionChecks.vin?.certificateMatch ===
          InspectionSecurityStatusEnum.discrepancyFound) ||
        (Object.keys(inspectionChecks.licencePlate).length > 2 &&
          [
            DocumentCaptureStepEnum.registration,
            CardCaptureStepEnum.plateNumber
          ].includes(type) &&
          inspectionChecks.licencePlate?.certificateMatch ===
            InspectionSecurityStatusEnum.discrepancyFound))
    ) {
      setRegoFile(registration.file);
      setValidate(false);
      if (
        type === CardCaptureStepEnum.plateNumber ||
        (type === DocumentCaptureStepEnum.registration &&
          inspectionChecks.licencePlate?.certificateMatch ===
            InspectionSecurityStatusEnum.discrepancyFound)
      ) {
        setPlateNumber('0');
        setPlateNumberArr([
          {
            label: `${inspectionChecks.licencePlate.userRego} / ${inspectionChecks.licencePlate.userState}`,
            value: '0'
          },
          {
            label: `${registration.file.fields2.regoCertificate.rego} / ${registration.file.fields2.regoCertificate.state}`,
            value: '1'
          }
        ]);
      }
    }
  }, [validate]);
  return (
    <Modal size="md" open={open} onClose={onClose} hideClose>
      <div className="flex flex-col pt-6 gap-[10px]">
        <div className="flex flex-col py-2 gap-[10px]">
          <h2 className="text-grey-900 font-semibold flex gap-2 flex-row w-full">
            {!validate ? (
              <div className="text-warning-600 whitespace-no-wrap w-full flex flex-row gap-2 items-center">
                Warning <Warning />
              </div>
            ) : (
              'Are you sure?'
            )}
          </h2>
          <p>
            {type === CardCaptureStepEnum.vin &&
              (validate
                ? 'Are you sure the details match your VIN?'
                : 'The VIN does not match the VIN previously provided with the Registration Certificate.')}
            {type === CardCaptureStepEnum.plateNumber &&
              (validate
                ? 'Are you sure the details match your number plate?'
                : 'The Number Plate does not match the Number Plate previously provided with the Registration Certificate.')}
            {type === DocumentCaptureStepEnum.registration &&
              (validate
                ? 'Are you sure all the information matches your registration certificate?'
                : inspectionChecks.vin?.certificateMatch ===
                  InspectionSecurityStatusEnum.discrepancyFound
                ? 'The VIN does not match the VIN previously provided in the Vehicle section.'
                : inspectionChecks.licencePlate?.certificateMatch ===
                  InspectionSecurityStatusEnum.discrepancyFound
                ? 'The Number Plate does not match the Number Plate previously provided in the Vehicle section.'
                : 'The VIN and Number Plate does not match previously provided in the Vehicle section.')}
            {type === DocumentCaptureStepEnum.bank &&
              'Are you sure all the information matches your bank statement?'}
          </p>
          {((type === CardCaptureStepEnum.vin && !validate) ||
            (type === DocumentCaptureStepEnum.registration &&
              !validate &&
              inspectionChecks.vin?.certificateMatch ===
                InspectionSecurityStatusEnum.discrepancyFound)) && (
            <div className="gap-3.5 flex flex-col">
              <h3 className="text-sm font-semibold font-inter text-gray-700">
                Please select the correct VIN
              </h3>
              <Select
                placeholder="Select a VIN"
                value={vinValue}
                style={
                  {
                    '--dynamic-border-color': primaryColor
                  } as React.CSSProperties
                }
                className="custom-state-select dynamic-border"
                onChange={(e) => {
                  setVinValue(e || '');
                }}>
                {[
                  inspectionChecks.vin?.userVin,
                  regoFile.fields2.regoCertificate.vin
                ].map((vin, index) => (
                  <Option key={index} value={vin}>
                    {vin}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {((type === CardCaptureStepEnum.plateNumber && !validate) ||
            (type === DocumentCaptureStepEnum.registration &&
              !validate &&
              inspectionChecks.licencePlate?.certificateMatch ===
                InspectionSecurityStatusEnum.discrepancyFound)) && (
            <>
              <div className="gap-3.5 flex flex-col">
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Please select the correct Number Plate
                </h3>
                <Select
                  placeholder="Select a plate number"
                  value={plateNumberValue}
                  style={
                    {
                      '--dynamic-border-color': primaryColor
                    } as React.CSSProperties
                  }
                  className="custom-state-select dynamic-border"
                  onChange={(e) => {
                    setPlateNumber(e || '');
                  }}>
                  {plateNumberArr.map((obj: any, index: number) => (
                    <Option key={index} value={obj.value}>
                      {obj.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </>
          )}
        </div>
        <div className="grid grid-cols-2 w-full gap-4">
          <Button
            variant="outlined"
            data-dialog-close="true"
            onClick={onClose}
            className="border border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
            No
          </Button>
          <Button
            data-dialog-close="true"
            className="font-semibold normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
            style={{ backgroundColor: primaryColor }}
            onClick={() => {
              onClose();
              if (!validate) {
                //only for not match case
                updateFields(() => setStep());
              } else {
                onSubmit();
              }
            }}>
            Yes, submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmSellerModal;
