import React, { useState } from 'react';
import Modal from '@components/widgets/Modal';
import Button from '@components/forms/Button';

const LayoutFooter: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <footer className="h-[44px] bg-[#F9FAFB] fixed bottom-0 left-0 z-100 right-0 flex items-center justify-end px-5">
      <div className="text-sm text-[#475467]">
        <a
          href="https://www.authentik8.com.au/lender-broker-privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline mr-4">
          Privacy
        </a>
        <a
          href="https://www.authentik8.com.au/lender-broker-terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
          className="underline mr-4">
          Terms of use
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            showModal();
          }}
          className="underline mr-4">
          Disclaimer
        </a>
        <span>Copyright © Valeur Pty Ltd</span>
      </div>

      <Modal
        size="sm"
        open={isModalVisible}
        onClose={handleClose}
        title="Disclaimer">
        <div>
          <p className="mb-4 -mt-4 text-[#475467] text-xs">
            All reasonable care has been taken in the preparation of the
            information and materials published on this website. However,{' '}
            <span className="font-bold">Valeur Pty Ltd</span> makes no
            representations or warranties, express or implied, regarding the
            accuracy, reliability, completeness, or suitability of the
            information provided for any particular purpose. The content
            provided on this website is for general informational purposes only
            and is not intended to be a substitute for professional advice.
          </p>
          <p className="mb-4 text-[#475467] text-xs">
            To the greatest extent permitted by Australian law,{' '}
            <span className="font-bold">Valeur Pty Ltd</span> expressly
            disclaims all liability for any loss, damage, expense, or cost
            (including indirect or consequential damages) incurred as a result
            of relying on the information or materials on this website, whether
            arising from inaccuracies, omissions, or any other cause.
          </p>
          <p className="mb-4 text-[#475467] text-xs">
            While we make every effort to keep the website&apos;s content
            current, <span className="font-bold">Valeur Pty Ltd</span> does not
            guarantee that the information is accurate, complete, or up-to-date.
            We recommend that you independently verify any information including
            seeking professional advice if required should you wish to rely on
            it for any decision or action.
          </p>
        </div>
      </Modal>
    </footer>
  );
};

export default LayoutFooter;
