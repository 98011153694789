import React from 'react';
const EyeIcon = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66667 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V6.66667M6.66667 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V13.3333M17.5 6.66667V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H13.3333M17.5 13.3333V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H13.3333"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.21006 10.3566C5.14197 10.2488 5.10792 10.1949 5.08886 10.1117C5.07455 10.0492 5.07455 9.95075 5.08886 9.88829C5.10792 9.80514 5.14197 9.75123 5.21006 9.64341C5.77276 8.75242 7.4477 6.5 10.0002 6.5C12.5527 6.5 14.2276 8.75242 14.7903 9.64341C14.8584 9.75123 14.8925 9.80514 14.9115 9.88829C14.9259 9.95075 14.9259 10.0492 14.9115 10.1117C14.8925 10.1949 14.8584 10.2488 14.7903 10.3566C14.2276 11.2476 12.5527 13.5 10.0002 13.5C7.4477 13.5 5.77276 11.2476 5.21006 10.3566Z"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0002 11.5C10.8286 11.5 11.5002 10.8284 11.5002 10C11.5002 9.17157 10.8286 8.5 10.0002 8.5C9.17177 8.5 8.5002 9.17157 8.5002 10C8.5002 10.8284 9.17177 11.5 10.0002 11.5Z"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default EyeIcon;
