import { StatusChipVariant } from '@components/widgets/StatusChip';
import {
  InspectionDocumentStatusEnum,
  InspectionSecurityStatusEnum,
  InspectionStatusEnum,
  InspectionStatusType
} from '@types';
import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
interface InspectionFilterStoreState {
  query: string;
  setQuery: (data: any) => void;
  status: string;
  setStatus: (data: any) => void;
  asset: string;
  setAsset: (data: any) => void;
  lender: string;
  setLender: (data: any) => void;
  lenderOptions: any;
  setLenderOptions: (data: any) => void;
}

export const useInspectionStore = create<InspectionFilterStoreState>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      (set) => ({
        query: '',
        status: 'ALL',
        asset: 'ALL',
        lender: 'ALL',
        lenderOptions: [],
        setQuery: (data) => set({ query: data }),
        setStatus: (data) => set({ status: data }),
        setAsset: (data) => set({ asset: data }),
        setLender: (data) => set({ lender: data }),
        setLenderOptions: (data) => set({ lenderOptions: data })
      }),
      {
        name: 'inspection'
      }
    )
  )
);
export interface InspectionDetailStoreState {
  inspectionDetailData: {
    friendlyId: string;
    inspectionId: string;
    status: InspectionStatusType;
    lenderOrganisationId: string;
    lenderReference: string;
    lenderUserId: string;
    saleState: string;
    assetType: string;
    documentTypes: any;
    documentValidation: boolean;
    files: any;
    vehicleInfo: string;
    address?: any;
    nevdisVehicle: any;
    documents?: any;
    hasPpsrCertificate?: boolean;
    rego?: any;
    state?: any;
    vin?: any;
    extraFields?: any;
    expiryDate?: any;
    seller: {
      address: string;
      suburb: string;
      postcode: string;
      email: string;
      firstName: string;
      lastName: string;
      mobile: string;
      userId?: string;
      isDone?: boolean;
    };
    buyer: {
      email?: string;
      firstName?: string;
      lastName?: string;
      mobile?: string;
      address?: string;
      userId?: string;
      isDone?: boolean;
    };
    lender: {
      email?: string;
      firstName?: string;
      lastName?: string;
      mobile?: string;
      lenderName?: string;
      template: any;
      isDone?: boolean;
    };
    inspectionChecks: {
      licenseAuthenticity: any;
      licenseEdits: any;
      licenseExpiry: any;
      licenseFaceMatch: any;
      licenseLiveness: any;
      rav: any;
      address: any;
      stolen: any;
      writtenOff: any;
      registration: any;
      nevdisVehicleDetails: any;
      ppsr: any;
      glassesVehicleData: any;
      ASSET_FRONT: any;
      ASSET_BACK: any;
      BANK_STATEMENTS: any;
      ROADWORTHY_CERT: any;
      regoCertificate: any;
      bankStatement: any;
      vin: any;
      licencePlate: any;
      registrationExpiry: any;
      vehiclePhotos: any;
    };
    progress: number;
  };
  variant: StatusChipVariant;
  statusChipLabel: string;
  setVariant: (data: any) => void;
  setStatusChipLabel: (data: any) => void;
  hasDraft: boolean;
  setInspectionChecks: (data: any) => void;
  setDraft: (data: any) => void;
  setFile: (data: any) => void;
  setInspectionFiles: (data: any) => void;
  setLenderUserId: (data: any) => void;
  setSeller: (data: any) => void;
  setAssetType: (data: any) => void;
  setBuyerStatus: (data: boolean) => void;
  setBuyer: (data: any) => void;
  setLender: (data: any) => void;
  setDocumentValidation: (data: any) => void;
  setDocumentTypes: (data: any) => void;
  setLenderReference: (data: any) => void;
  setSaleState: (data: any) => void;
  setInspectionDetailData: (data: any) => void;
  isCollapsed: boolean;
  isSideCollapsed: boolean;
  showActivity: boolean;
  setCollapse: (data: any) => void;
  setSideCollapse: (data: any) => void;
  setActivity: (data: any) => void;
  activeStep: string;
  setActiveStep: (data: any) => void;
  activePanel: {
    activeSection: string;
    status: documentStatus;
  };
  rightBarStatus: InspectionSecurityStatusEnum;
  setRightBarStatus: (data: any) => void;
  reset: () => void;
  setActivePanel: (data: any) => void;
}
type documentStatus = 'Incomplete' | 'Complete' | 'Resupply Pending';
const inspectionDetailData: any = {
  friendlyId: '',
  inspectionId: '',
  status: InspectionStatusEnum.Initiated,
  lenderOrganisationId: '',
  lenderReference: '',
  lenderUserId: '',
  saleState: '',
  address: {},
  vehicleInfo: '',
  nevdisVehicle: { status: InspectionSecurityStatusEnum.notPerformed },
  assetType: '',
  documentTypes: [
    'SELLER_ID',
    'DRIVER_LICENCE_FRONT',
    'DRIVER_LICENCE_BACK',
    'FACE',
    'ASSET_BACK',
    'ASSET_FRONT',
    'ASSET_LEFT',
    'ASSET_RIGHT',
    'VIN',
    'REGO_PLATE',
    'ODOMETER',
    'REGO_PAPERS',
    'BANK_STATEMENTS',
    'PPSR',
    'NEVDIS',
    'RAV',
    'GLASSES',
    'ROADWORTHY_CERT',
    'SAFETY_CERT'
  ],
  files: [],
  documents: {},
  documentValidation: false,
  seller: {
    address: '',
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
    userId: '',
    suburb: '',
    postcode: '',
    isDone: false
  },
  buyer: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    userId: '',
    isDone: false
  },
  lender: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    template: null,
    isDone: false
  },
  inspectionChecks: {
    licenseAuthenticity: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'LICENSE_AUTHENTICITY',
      subChecks: []
    },
    licenseEdits: {
      type: 'LICENSE_EDITS',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    licenseExpiry: {
      type: 'LICENSE_EXPIRY',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    licenseFaceMatch: {
      type: 'LICENSE_FACE_MATCH',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    licenseLiveness: {
      type: 'LICENSE_LIVENESS',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    rav: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'RAV_COMPLIANCE'
    },
    stolen: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_STOLEN'
    },
    writtenOff: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_WRITTEN_OFF'
    },
    registration: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_REGISTRATION'
    },
    nevdisVehicleDetails: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_VEHICLE_DETAILS'
    },
    ppsr: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'PPSR_ENCUMBRANCE'
    },
    glassesVehicleData: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'VEHICLE_DATA'
    },
    vin: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'VEHICLE_VIN'
    },
    licencePlate: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'VEHICLE_PLATE'
    },
    ASSET_FRONT: { status: InspectionDocumentStatusEnum.Incomplete },
    ASSET_BACK: { status: InspectionDocumentStatusEnum.Incomplete },
    BANK_STATEMENTS: { status: InspectionDocumentStatusEnum.Incomplete },
    ROADWORTHY_CERT: { status: InspectionDocumentStatusEnum.Incomplete },
    VIN: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    REGO_PLATE: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    ODOMETER: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    ASSET_LEFT: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    ASSET_RIGHT: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    bankStatement: {
      status: InspectionDocumentStatusEnum.notPerformed
    },
    regoCertificate: {
      status: InspectionDocumentStatusEnum.notPerformed
    },
    registrationExpiry: {
      status: InspectionDocumentStatusEnum.notPerformed
    },
    vehiclePhotos: {}
  },
  progress: 0
};
export const useInspectionDetailStore = create<InspectionDetailStoreState>()(
  devtools(
    persist(
      (set) => ({
        inspectionDetailData,
        variant: 'info_light',
        statusChipLabel: '',
        setVariant: (data: any) => set({ variant: data }),
        setStatusChipLabel: (data: any) => set({ statusChipLabel: data }),
        showActivity: false,
        rightBarStatus: InspectionSecurityStatusEnum.notPerformed,
        setRightBarStatus: (data: any) => set({ rightBarStatus: data }),
        setBuyer: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              buyer: data
            }
          })),
        setBuyerStatus: (data: boolean) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              buyer: { ...state.inspectionDetailData.buyer, isDone: data }
            }
          })),
        hasDraft: false,
        setInspectionFiles: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              files: data
            }
          })),
        setInspectionChecks: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              inspectionChecks: data
            }
          })),
        setFile: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              files: Array.from(
                new Set([...state.inspectionDetailData.files, ...data])
              )
            }
          })),
        setDraft: (data: any) => set({ hasDraft: data }),
        setAssetType: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              assetType: data
            }
          })),
        setLenderUserId: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              lenderUserId: data
            }
          })),
        setLender: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              lender: data
            }
          })),
        setSeller: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              seller: data
            }
          })),
        setDocumentTypes: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              documentTypes: data
            }
          })),
        setDocumentValidation: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              documentValidation: data
            }
          })),
        setLenderReference: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              lenderReference: data
            }
          })),
        setSaleState: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: {
              ...state.inspectionDetailData,
              saleState: data
            }
          })),
        setInspectionDetailData: (data: any) =>
          set((state) => ({
            ...state,
            inspectionDetailData: { ...state.inspectionDetailData, ...data }
          })),
        isCollapsed: false,
        isSideCollapsed: false,
        setActivity: (data: boolean) =>
          set((state) => ({ ...state, showActivity: data })),
        setCollapse: (data: boolean) =>
          set((state) => ({ ...state, isCollapsed: data })),
        setSideCollapse: (data: boolean) =>
          set((state) => ({ ...state, isSideCollapsed: data })),
        activeStep: '1',
        activePanel: {
          activeSection: 'DRIVER_LICENCE_FRONT',
          status: 'Incomplete'
        },
        setActiveStep: (data: string) => set({ activeStep: data }),
        reset: () =>
          set((state) => ({
            ...state,
            inspectionDetailData: inspectionDetailData
          })),
        setActivePanel: (data) =>
          set((state) => ({
            ...state,
            activePanel: { ...state.activePanel, activeSection: data }
          }))
      }),
      {
        name: 'inspectionDetails',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
