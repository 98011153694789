import React, { forwardRef, Ref } from 'react';
import { Input as MuiInput, InputProps } from '@material-tailwind/react';
import clsx from 'clsx';

type Props = InputProps & {
  ref?: Ref<HTMLInputElement>;
  inputClassName?: string;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, inputClassName, ...rest }, ref) => {
    return (
      <MuiInput
        ref={ref}
        className={clsx(
          '!border-grey-300 focus:!border-blue-500 !text-base shadow-input bg-white text-grey-900',
          inputClassName
        )}
        containerProps={{ className: clsx('h-11', className) }}
        labelProps={{
          className: 'before:content-none after:content-none'
        }}
        {...rest}
      />
    );
  }
);

export default Input;
