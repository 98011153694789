import React, { useEffect, useState } from 'react';
import Modal from '@components/widgets/Modal';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { useForm } from 'react-hook-form';
import { CREATE_AV, post } from '@services';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { Select } from '@components/forms';
import { Option } from '@material-tailwind/react';
import { ReactComponent as Info } from '@assets/icons/info-circle.svg';
import { Tooltip } from 'react-tooltip';

type Props = {
  open: boolean;
  onClose: () => void;
};
// Define the regular expressions
const vinRegExp = /^[A-HJ-NPR-Z0-9]{17}$/;
const regoRegex = /^[A-Z0-9]*$/;
const referenceNumberRegex = /^[A-Z0-9]{6}$/;
// Define the validation schema
const verificationFormSchema = yup.object({
  inputValue: yup
    .string()
    .required('Please input your VIN or Registration number')
    .test(
      'input-value-validation',
      'Invalid VIN or Registration state is not selected',
      function (value) {
        const { stateValue } = this.parent;

        if (vinRegExp.test(value)) {
          return yup.string().isValidSync(stateValue);
        }

        if (regoRegex.test(value)) {
          return yup.string().required().isValidSync(stateValue);
        }

        return true;
      }
    ),
  referenceNumber: yup
    .string()
    .matches(referenceNumberRegex, 'Invalid NVIC')
    .nullable()
    .transform((value) => (value === '' ? null : value)),
  stateValue: yup.string().when('inputValue', {
    is: (inputValue: string) => !vinRegExp.test(inputValue),
    then: (schema) =>
      schema.required(
        'State is required when input value is a registration number'
      ),
    otherwise: (schema) => schema.notRequired()
  })
});

const CreateVerificationModal: React.FC<Props> = ({ open, onClose }) => {
  const stateOptions = ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT', 'NT'];
  const [showSuccessMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(verificationFormSchema),
    defaultValues: {
      inputValue: '',
      stateValue: '',
      referenceNumber: ''
    }
  });
  const { inputValue, stateValue, referenceNumber } = watch();
  const onSubmit = () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const lender = localStorage.getItem(`lenders_${userId}` as any);
      if (lender) {
        const lenderOrganisationId = JSON.parse(lender)[0].organisationId;
        post(
          CREATE_AV,
          {
            lenderOrganisationId,
            ...(inputValue && inputValue.length === 17
              ? { vin: inputValue, candidateNvic: referenceNumber }
              : {
                  rego: inputValue,
                  state: stateValue,
                  candidateNvic: referenceNumber
                })
          },
          false
        )
          .then(() => {
            setSuccessMsg(true);
            setTimeout(() => {
              setSuccessMsg(false);
              onClose();
              reset();
            }, 3000);
          })
          .catch((error) => {
            const errorMessage = error.response?.data?.error?.message;
            const errorCode = error.response?.data?.error?.code;
            if (errorMessage) {
              if (errorCode === 'QuotaExceeded') {
                setErrorMsg(
                  "Your organisation's daily asset verification limit has been reached. Please contact your Authentik8 account manager to request an increase in your quota."
                );
              } else {
                setErrorMsg(errorMessage);
              }
            }
          });
      }
    }
  };
  useEffect(() => {
    reset();
    setErrorMsg('');
  }, [open]);
  return (
    <Modal
      title="New Asset Verification"
      size="md"
      open={open}
      onClose={onClose}>
      <form>
        <div className="flex flex-col mb-4 gap-5">
          <div className="gap-2 flex flex-col">
            <p className="text-sm font-medium text-grey-700">
              Registration or VIN
            </p>
            <Input
              placeholder="Registration or VIN"
              type="text"
              icon={errors.inputValue && <ErrorIcon />}
              value={inputValue}
              {...register('inputValue')}
            />
            {errors.inputValue && (
              <span className="text-xs text-error-light">
                {errors.inputValue.message}
              </span>
            )}
          </div>
          <div className="gap-2 flex flex-col">
            <h3 className="text-sm font-semibold font-inter text-gray-700">
              Registration State
            </h3>
            <Select
              placeholder="Select a State"
              {...register(`stateValue`)}
              value={stateValue}
              onChange={(e) => {
                clearErrors('stateValue');
                setValue('stateValue', e || '');
              }}>
              {stateOptions.map((state, index) => (
                <Option key={index} value={state}>
                  {state}
                </Option>
              ))}
            </Select>
            {errors.stateValue && (
              <span className="text-xs text-error-light">
                {errors.stateValue.message}
              </span>
            )}
          </div>
          <div className="gap-2 flex flex-col">
            <p className="text-sm font-medium text-grey-700 flex flex-row gap-1">
              Comparison NVIC
              <div
                data-tooltip-id="badge-tooltip"
                data-tooltip-content="Optional: Input an additional NVIC to compare another vehicle side by side.">
                <Info />
              </div>
            </p>
            <Input
              placeholder="NVIC"
              type="text"
              maxLength={6}
              icon={errors.referenceNumber && <ErrorIcon />}
              value={referenceNumber || ''}
              {...register('referenceNumber')}
            />
            {errors.referenceNumber && (
              <span className="text-xs text-error-light">
                {errors.referenceNumber.message}
              </span>
            )}
          </div>
        </div>
        <Tooltip
          id="badge-tooltip"
          place="bottom"
          className="rounded-lg z-10 w-[234px] text-sm !text-grey-700 bg-[#F2F4F7] bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-4"
          offset={2}></Tooltip>
        <div className="flex flex-col gap-4 justify-end">
          {showSuccessMsg && (
            <div className="text-success-700 sticky font-medium text-sm text-right my-3">
              Asset verification initiated
            </div>
          )}
          {errorMsg && (
            <div className="text-error-light sticky font-medium text-sm text-center">
              {errorMsg}
            </div>
          )}
          <div className="flex gap-4 justify-center lg:justify-end">
            <Button
              className="font-semibold bg-primary w-auto capitalize"
              disabled={showSuccessMsg}
              onClick={handleSubmit(onSubmit)}>
              Verify Asset
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateVerificationModal;
