import React, { PropsWithChildren, forwardRef, Ref } from 'react';
import { Button as MuiButton, ButtonProps } from '@material-tailwind/react';
import clsx from 'clsx';

type Props = PropsWithChildren<
  ButtonProps & {
    ref?: Ref<HTMLButtonElement>;
  }
>;

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ className, ...rest }, ref) => {
    return (
      <MuiButton
        ref={ref}
        className={clsx(
          'bg-dark-primary h-11 md:!text-base capitalize font-semibold flex items-center justify-center py-0 shadow-none hover:shadow-none px-3.5',
          className
        )}
        {...rest}
      />
    );
  }
);

export default Button;
