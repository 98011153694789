import React from 'react';
import { ReactComponent as CheckIcon } from '@assets/icons/check_icon.svg';
import clsx from 'clsx';

const Variants = {
  success:
    'gap-1 text-success-dark bg-success/10 border-success/30 flex items-center',
  success1:
    'gap-1 text-success-dark bg-success/10 border-success/30 flex items-center',
  text: 'text-grey-700 border-grey-200 bg-grey-50',
  info: 'text-secondary-light border-secondary-light/20 bg-secondary-light/5',
  info2: 'text-xs text-blue-600 border-secondary-light/20 bg-secondary-light/5',
  warning: 'text-[#B54708] border-[#FEDF89] bg-[#FFFAEB]',
  error: 'text-error-700 border-error-100 bg-error-50',
  success_light: 'text-xs text-success-25 bg-success-600 border-success-100',
  info_light: 'text-xs text-white border-grey-500 bg-grey-500',
  warning_light: 'text-xs text-white border-warning-500 bg-warning-500',
  error_light: 'text-xs text-white border-error-500 bg-error-500',
  not_available:
    'text-secondary-light border-secondary-light/20 bg-secondary-light/5'
};

export type StatusChipVariant =
  | 'success'
  | 'success1'
  | 'text'
  | 'info'
  | 'warning'
  | 'info2'
  | 'error'
  | 'success_light'
  | 'info_light'
  | 'warning_light'
  | 'not_available'
  | 'error_light';

type Props = {
  variant: StatusChipVariant;
  label: string;
  clsName?: string;
};

const StatusChip: React.FC<Props> = ({ variant, label, clsName = '' }) => {
  return (
    <div
      className={clsx(
        'text-xs px-2 py-1 rounded-3xl border font-medium capitalize w-fit',
        Variants[variant],
        clsName
      )}>
      {variant === 'success' && <CheckIcon className="w-3.5 h-3.5" />}
      {label}
    </div>
  );
};

export default StatusChip;
