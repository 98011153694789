import React from 'react';
const CustomBank = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="20"
      height="20"
      className="w-6 h-6"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6.99999V15M7.5 6.99999V15M12.5 6.99999V15M17 6.99999V15M1 16.6L1 17.4C1 17.96 1 18.2401 1.10899 18.454C1.20487 18.6421 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H17.4C17.9601 19 18.2401 19 18.454 18.891C18.6422 18.7951 18.7951 18.6421 18.891 18.454C19 18.2401 19 17.96 19 17.4V16.6C19 16.0399 19 15.7599 18.891 15.546C18.7951 15.3578 18.6422 15.2049 18.454 15.109C18.2401 15 17.9601 15 17.4 15H2.6C2.03995 15 1.75992 15 1.54601 15.109C1.35785 15.2049 1.20487 15.3578 1.10899 15.546C1 15.7599 1 16.0399 1 16.6ZM9.65291 1.07712L2.25291 2.72156C1.80585 2.82091 1.58232 2.87058 1.41546 2.99079C1.26829 3.09682 1.15273 3.24088 1.08115 3.40756C1 3.59651 1 3.8255 1 4.28346L1 5.39999C1 5.96004 1 6.24007 1.10899 6.45398C1.20487 6.64214 1.35785 6.79512 1.54601 6.891C1.75992 6.99999 2.03995 6.99999 2.6 6.99999H17.4C17.9601 6.99999 18.2401 6.99999 18.454 6.891C18.6422 6.79512 18.7951 6.64214 18.891 6.45398C19 6.24007 19 5.96004 19 5.39999V4.28346C19 3.8255 19 3.59652 18.9188 3.40756C18.8473 3.24089 18.7317 3.09682 18.5845 2.99079C18.4177 2.87059 18.1942 2.82091 17.7471 2.72157L10.3471 1.07712C10.2176 1.04834 10.1528 1.03395 10.0874 1.02821C10.0292 1.02311 9.97077 1.02311 9.91264 1.02821C9.8472 1.03395 9.78244 1.04834 9.65291 1.07712Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CustomBank;
