import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
interface TemplateStoreState {
  templateObject: any;
  setTemplateObject: (data: any) => void;
}

export const useTemplateStore = create<TemplateStoreState>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      (set) => ({
        templateObject: {},
        setTemplateObject: (data: any) => set({ templateObject: data })
      }),
      {
        name: 'Template',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
