import React, { useEffect, useState, useRef } from 'react';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { useInvoiceDetailStore } from '@stores';
import { Tooltip } from 'react-tooltip';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';
type Props = {
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
};

const VehicleDetailStep: React.FC<Props> = ({
  onClose,
  onPrevious,
  onNext
}) => {
  const phoneRegExp = /^(04\d{8}|\+614\d{8}|614\d{8})$/;
  const [items, setItems] = useState([
    {
      text: ''
    }
  ]);

  const states = [
    { value: '', label: 'Select a State' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' }
  ];
  const buyerFormSchema = yup
    .object({
      make: yup.string().required('Please enter the vehicle make.'),
      model: yup.string().required('Please enter the vehicle model.'),
      year: yup
        .string()
        .required('Please enter the vehicle year.')
        .test('validYear', 'Please enter a valid year.', (value) => {
          // Check if it's a 4-digit number
          if (!/^\d{4}$/.test(value)) return false;

          // Check if year is within reasonable range
          const year = parseInt(value);
          return year >= 1900;
        }),
      complianceMonth: yup
        .string()
        .test(
          'validYear',
          'Please enter a valid year in the format YYYY (e.g., 2024), or leave the field blank.',
          (value) => {
            if (!value) return true;
            return /^\d{4}$/.test(value);
          }
        ),
      vin: yup
        .string()
        .required('Please enter the vehicle VIN.')
        .min(17, 'VIN must be 17 characters')
        .max(17, 'VIN must be 17 characters'),
      regoPlate: yup
        .string()
        .required('Please enter the vehicle rego plate.')
        .min(6, 'Registration plate must be 6 characters')
        .max(6, 'Registration plate must be 6 characters'),
      regoState: yup.string().required('Please select the vehicle rego state.'),
      engineNumber: yup
        .string()
        .required('Please enter the vehicle engine number.'),
      purchasePrice: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .required('Please enter the purchase price.'),
      deposit: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .required('Please enter the deposit.'),
      balance: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : value))
        .required('Please enter the balance.')
    })
    .required();
  const { invoiceDetailData, setInvoiceDetailData } = useInvoiceDetailStore();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(buyerFormSchema),
    defaultValues: {
      make: invoiceDetailData.vehicle?.make,
      model: invoiceDetailData.vehicle?.model,
      year: invoiceDetailData.vehicle?.year,
      vin: invoiceDetailData.vehicle?.vin,
      regoPlate: invoiceDetailData.vehicle?.regoPlate,
      regoState: invoiceDetailData.vehicle?.regoState,
      engineNumber: invoiceDetailData.vehicle?.engineNumber
    }
  });
  const {
    make,
    model,
    year,
    vin,
    regoPlate,
    regoState,
    engineNumber,
    complianceMonth,
    purchasePrice,
    deposit,
    balance
  } = watch();
  useEffect(() => {
    if (invoiceDetailData?.vehicle) {
      setValue('make', invoiceDetailData.vehicle.make || '');
      setValue('model', invoiceDetailData.vehicle.model || '');
      setValue('year', invoiceDetailData.vehicle.year || '');
      setValue('vin', invoiceDetailData.vehicle.vin || '');
      setValue('regoPlate', invoiceDetailData.vehicle.regoPlate || '');
      setValue('regoState', invoiceDetailData.vehicle.regoState || '');
      setValue('engineNumber', invoiceDetailData.vehicle.engineNumber || '');
      setValue(
        'complianceMonth',
        invoiceDetailData.vehicle.complianceMonth || ''
      );
    }
  }, [invoiceDetailData, setValue]);
  const onSubmit = async (data: any) => {
    const vehicle = {
      make: data.make,
      model: data.model,
      year: data.year,
      vin: data.vin,
      regoPlate: data.regoPlate,
      regoState: data.regoState,
      isDone: true,
      engineNumber: data.engineNumber,
      complianceMonth: data.complianceMonth,
      purchasePrice: data.purchasePrice,
      deposit: data.deposit,
      balance: data.balance
    };
    setInvoiceDetailData({ ...invoiceDetailData, vehicle });
    onNext();
  };
  useEffect(() => {
    // Calculate balance only if both purchasePrice and deposit are valid numbers
    if (!isNaN(Number(purchasePrice)) && !isNaN(Number(deposit))) {
      const calculatedBalance = Number(purchasePrice) - Number(deposit);
      setValue('balance', calculatedBalance >= 0 ? calculatedBalance : 0);
    } else {
      setValue('balance', 0);
    }
  }, [purchasePrice, deposit, setValue]);

  const handleStateChange = (e: any) => {
    setValue('regoState', e);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">
        Vehicle description
      </h3>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Make</p>
        <Input
          placeholder="Make"
          {...register(`make`)}
          icon={errors.make && <ErrorIcon />}
          value={make}
        />
        {errors.make && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.make.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Model</p>
        <Input
          placeholder="Model"
          {...register(`model`)}
          icon={errors.model && <ErrorIcon />}
          value={model}
        />
        {errors.model && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.model.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Build year</p>
        <Input
          placeholder="Year"
          {...register(`year`)}
          value={year}
          maxLength={4}
          icon={errors.year && <ErrorIcon />}
        />
        {errors.year && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.year.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Compliance year
        </p>
        <Input
          placeholder="Compliance year"
          {...register(`complianceMonth`)}
          value={complianceMonth}
          maxLength={4}
          icon={errors.complianceMonth && <ErrorIcon />}
        />
        {errors.complianceMonth && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.complianceMonth.message}`}
          </span>
        )}
      </div>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">
        Vehicle identification
      </h3>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">VIN</p>
        <Input
          placeholder="VIN"
          {...register(`vin`)}
          icon={errors.vin && <ErrorIcon />}
          value={vin}
        />
        {errors.vin && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.vin.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Engine or manufacturer number
        </p>
        <Input
          placeholder="Engine or manufacturer number"
          {...register(`engineNumber`)}
          icon={errors.engineNumber && <ErrorIcon />}
          value={engineNumber}
        />
        {errors.engineNumber && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.engineNumber?.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Licence Plate
        </p>
        <Input
          placeholder="Licence Plate"
          {...register(`regoPlate`)}
          icon={errors.regoPlate && <ErrorIcon />}
          value={regoPlate}
        />
        {errors.regoPlate && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.regoPlate?.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Registration State
        </p>
        <Select
          placeholder="Select a State"
          {...register(`regoState`)}
          value={regoState}
          onChange={handleStateChange}>
          {states.map((state, index) => (
            <Option
              key={index}
              value={state.value}
              disabled={state.value === ''}>
              {state.label}
            </Option>
          ))}
        </Select>
        {errors.regoState && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.regoState?.message}`}
          </span>
        )}
      </div>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">
        Sale details (incl. GST)
      </h3>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Purchase price
        </p>
        <Input
          placeholder="Purchase price"
          {...register(`purchasePrice`)}
          icon={errors.purchasePrice && <ErrorIcon />}
          value={purchasePrice}
        />
        {errors.purchasePrice && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.purchasePrice?.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Less deposit</p>
        <Input
          placeholder="Deposit"
          {...register(`deposit`)}
          icon={errors.deposit && <ErrorIcon />}
          value={deposit}
        />
        {errors.deposit && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.deposit?.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">
          Balance owing
        </p>
        <Input
          readOnly
          placeholder="Balance"
          {...register(`balance`)}
          icon={errors.balance && <ErrorIcon />}
          value={balance}
        />
        {errors.balance && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.balance?.message}`}
          </span>
        )}
      </div>
      <Tooltip
        id="badge-tooltip"
        place="bottom"
        className="rounded-lg z-10 w-[320px] text-sm !text-grey-700 bg-[#F2F4F7] bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-4"
        offset={2}></Tooltip>

      <div className="flex justify-between border-t border-t-grey-200 pt-5 mt-4  gap-3">
        <div className="flex flex-row gap-2">
          <Button
            className="bg-white text-grey-700 border-grey-300 !text-sm"
            variant="outlined"
            onClick={onPrevious}>
            Previous
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button type="submit" className="text-white bg-primary !text-sm">
            Generate Invoice
          </Button>
        </div>
      </div>
    </form>
  );
};

export default VehicleDetailStep;
