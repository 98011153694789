/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useOrgStore, useSellerStore } from '@stores';
import { GET_LENDER_ORGANISATION, post } from '@services';
import { useSearchParams } from 'react-router-dom';
const SellerComplete = () => {
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation, setOrg } = useOrgStore();
  const userId = localStorage.getItem('userId');
  const lenderUser = localStorage.getItem(`lenders_${userId}` as any);
  const [searchParams] = useSearchParams();
  const [friendlyId, setFriendlyId] = useState(
    searchParams.get('friendlyId') as string
  );
  const { setIsHome } = useSellerStore();
  useEffect(() => {
    setIsHome(false);
    const organisation = lenderUser && JSON.parse(lenderUser)[0].organisationId;
    post(GET_LENDER_ORGANISATION, { organisationId: organisation }, false)
      .then((data) => {
        if (data) {
          if (data.primaryColour !== '') {
            setPrimaryColor('#' + organisation.primaryColour);
          }
          setOrg(data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  return (
    <>
      <div className="px-4 gap-16 flex flex-col justify-center items-center w-full">
        <div>
          <img
            src={organisation.logoUrl}
            alt="Uploaded SVG"
            className="max-w-full"
          />
        </div>
        <div className="mx-auto w-full bg-white gap-6 py-[50px] px-6 rounded-xl shadow-paper relative max-w-[600px]">
          <div className="flex flex-col gap-6">
            <div className="gap-2 flex flex-col">
              <h3 className="text-2xl text-grey-900 font-semibold text-center">
                Inspection complete
              </h3>
              <p className="text-center text-grey-600">
                Your inspection {friendlyId} is complete. If you have any
                questions, please contact your service provider.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerComplete;
