import React, { PropsWithChildren } from 'react';
import { Dialog, DialogHeader, DialogBody } from '@material-tailwind/react';
import { ReactComponent as XCloseIcon } from '@assets/icons/x_close_icon.svg';
import clsx from 'clsx';

type Props = PropsWithChildren<unknown> & {
  title?: string;
  open: boolean;
  onClose: () => void;
  size?: 'sm' | 'md' | 'lg';
  hideClose?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  open,
  onClose,
  title,
  size,
  hideClose = false
}) => {
  return (
    <Dialog
      open={open}
      handler={onClose}
      className={clsx('rounded-2xl', {
        'md:!min-w-[500px] md:!w-[500px]': size === 'md',
        'md:!min-w-[400px] md:!w-[400px]': size === 'sm'
      })}>
      {title && (
        <DialogHeader className="p-6 pb-5">
          <div className="flex items-center justify-between w-full">
            <h3 className="text-grey-900 font-semibold text-lg">{title}</h3>
            {!hideClose && (
              <XCloseIcon
                className="cursor-pointer hover:opacity-60"
                onClick={onClose}
              />
            )}
          </div>
        </DialogHeader>
      )}

      <DialogBody className="p-6 pt-0">{children}</DialogBody>
    </Dialog>
  );
};

export default Modal;
