import React, { useState, useEffect } from 'react';
import Button from '@components/forms/Button';
import { UPDATE_USER_PROFILE, CURRENT_USER, post } from '@services';
import { useAuthStore } from '@stores';

interface ProfileDetailsProps {
  title: string;
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ title }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profileSaved, setProfileSaved] = useState(false);
  const { setUser, user } = useAuthStore();

  useEffect(() => {
    post(CURRENT_USER, {}).then((res) => {
      setFirstName(res?.user?.firstName);
      setLastName(res?.user?.lastName);
      setEmail(res?.user?.email);
    });
  }, []);

  const handleSave = () => {
    // Save profile logic here
    post(UPDATE_USER_PROFILE, {
      firstName,
      lastName
    })
      .then(() => {
        setUser({
          ...user,
          firstName,
          lastName
        });
        setProfileSaved(true);
        setTimeout(() => setProfileSaved(false), 5000); // Hide message after 3 seconds
      })
      .catch((error) => console.error(error));
  };
  return (
    <div>
      <h1 className="font-semibold text-3xl text-gray-900 mb-6">{title}</h1>

      <form style={{ maxWidth: '520px' }}>
        <div className="mb-6">
          <label className="block font-medium text-gray-700 text-sm">
            First Name
          </label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block font-medium text-gray-700 text-sm">
            Last Name
          </label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block font-medium text-gray-700 text-sm">
            Email
          </label>
          <input
            type="email"
            className="w-full p-2 border rounded bg-gray-100"
            value={email}
            readOnly
          />
        </div>
        {profileSaved && (
          <div className="mb-2 text-green-700 text-sm">Profile saved!</div>
        )}
        <Button type="submit" className="bg-primary" onClick={handleSave}>
          Save
        </Button>
      </form>
    </div>
  );
};

export default ProfileDetails;
