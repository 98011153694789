import { useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as AppLogo } from '@assets/images/dark_logo_24.svg';
import { ReactComponent as AppIcon } from '@assets/images/app_logo.svg';
import { ReactComponent as AppImage } from '@assets/icons/AppLogo_mobile.svg';
import { ReactComponent as AppMobileIcon } from '@assets/images/app_mobile_logo.svg';
import { ReactComponent as DashboardIcon } from '@assets/images/dashboard.svg';
import { ReactComponent as InspectionsIcon } from '@assets/images/inspections.svg';
import { ReactComponent as DocumentIcon } from '@assets/icons/document_icon2.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow_left_icon.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/icons/arrow_right_icon.svg';
import { ReactComponent as AssetIcon } from '@assets/icons/asset_icon.svg';
import { ReactComponent as MoreIcon } from '@assets/icons/more_icon.svg';
import { ReactComponent as SupportIcon } from '@assets/icons/support.svg';
import { IconButton } from '@material-tailwind/react';
import { ReactComponent as BellIcon } from '@assets/icons/bell_light_icon.svg';
import { useMediaQuery } from 'usehooks-ts';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as LogoutIcon } from '@assets/icons/log_out_icon.svg';
import { useInspectionDetailStore } from '@stores';
import { useAuthStore } from '@stores';

const Sidebar = () => {
  const navigator = useNavigate();
  const { user } = useAuthStore();
  const isMobile = useMediaQuery('(max-width: 720px)');
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const location = useLocation();
  const { pathname } = location;

  const { isSideCollapsed, setSideCollapse } = useInspectionDetailStore();
  const [roles, setRoles] = useState<string[]>([]);
  const [isLender, setIsLender] = useState(false);
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const manageRoles = localStorage.getItem(`lenders_${userId}` as any);
      if (manageRoles) {
        if (JSON.parse(manageRoles) && JSON.parse(manageRoles).length > 0) {
          setRoles(JSON.parse(manageRoles)[0].roles);
          setIsLender(true);
        }
      }
    } else {
      navigator('login');
    }
  }, []);

  const toggleMenu = () => {
    setSideCollapse(!isSideCollapsed);
  };
  const closeSideMenu = () => {
    if (isMobile) {
      setSideCollapse(true);
    }
  };
  const email = localStorage.getItem('email');
  const fullName =
    localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
  useEffect(() => {
    setSideCollapse(
      isMobile || isTablet || pathname.indexOf('/inspections/details')
        ? true
        : false
    );
  }, [isMobile, isTablet]);

  return (
    <>
      <div
        className={clsx(
          'z-10',
          isSideCollapsed || !isMobile
            ? 'hidden'
            : 'block fixed bg-opacity-0 w-screen h-[calc(100vh-44px)] z-0'
        )}
        onClick={() => setSideCollapse(true)}></div>
      <div
        className={clsx(
          'sidebar_div bg-secondary flex flex-col justify-between sidebarMenu md:flex fixed h-[calc(100vh-44px)] z-50 md:relative',
          isSideCollapsed ? 'collapsed md:flex' : 'expanded',
          isMobile && 'mobile'
        )}>
        <div className={clsx('flex-col', isSideCollapsed && 'hidden md:flex')}>
          <div
            className={clsx(
              'flex gap-2 px-4 h-[72px] items-left items-center ',
              isSideCollapsed ? 'justify-start' : 'justify-start'
            )}>
            <Link
              className="bg items-center justify-center flex gap-x-3"
              to="/"
              data-tooltip-id="nav-tooltip"
              data-tooltip-content="Home">
              <AppIcon />
              <div
                className="hidden sm:block"
                onClick={() => closeSideMenu()}
                style={
                  isSideCollapsed ? { display: 'none' } : { display: 'block' }
                }>
                <AppLogo />
              </div>
            </Link>
          </div>
          <div>
            <ul className="py-0 px-4 pl-2.5">
              <li>
                <Link
                  className="bg block"
                  to="/"
                  data-tooltip-id="nav-tooltip"
                  data-tooltip-content="Home">
                  <Button
                    className={clsx(
                      'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark',
                      pathname === '/' && 'bg-secondary-dark',
                      isSideCollapsed && 'justify-center'
                    )}
                    onClick={() => closeSideMenu()}
                    variant="text">
                    <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                      <DashboardIcon
                        style={{ width: '24px', height: '24px' }}
                      />
                      {!isSideCollapsed && 'Dashboard'}
                    </div>
                  </Button>
                </Link>
              </li>
              <li>
                <Link
                  className="bg block"
                  to="/inspections"
                  data-tooltip-id="nav-tooltip"
                  data-tooltip-content="Inspections">
                  <Button
                    className={clsx(
                      'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark',
                      pathname === '/inspections' && 'bg-secondary-dark',
                      isSideCollapsed && 'justify-center'
                    )}
                    onClick={() => closeSideMenu()}
                    variant="text">
                    <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                      <InspectionsIcon
                        style={{ width: '24px', height: '24px' }}
                      />
                      {!isSideCollapsed && 'Inspections'}
                    </div>
                  </Button>
                </Link>
              </li>
              {isLender && (
                <>
                  {roles.includes('ADMIN') && (
                    <li>
                      <Link
                        to="/inspection-templates"
                        className="bg block"
                        data-tooltip-id="nav-tooltip"
                        data-tooltip-content="Templates">
                        <Button
                          className={clsx(
                            'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark',
                            pathname === '/inspection-templates' &&
                              'bg-secondary-dark',
                            isSideCollapsed && 'justify-center'
                          )}
                          onClick={() => closeSideMenu()}
                          variant="text">
                          <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                            <DocumentIcon
                              style={{ width: '24px', height: '24px' }}
                            />
                            {!isSideCollapsed && 'Templates'}
                          </div>
                        </Button>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      className="bg block"
                      to="/verifications"
                      data-tooltip-id="nav-tooltip"
                      data-tooltip-content="Asset Verifications">
                      <Button
                        className={clsx(
                          'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark',
                          pathname === '/verifications' && 'bg-secondary-dark',
                          isSideCollapsed && 'justify-center'
                        )}
                        onClick={() => closeSideMenu()}
                        variant="text">
                        <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                          <AssetIcon
                            style={{ width: '24px', height: '24px' }}
                          />
                          {!isSideCollapsed && 'Asset Verifications'}
                        </div>
                      </Button>
                    </Link>
                  </li>
                </>
              )}
            </ul>

            {isSideCollapsed && (
              <Tooltip
                id="nav-tooltip"
                place="right"
                className="border-0 rounded-lg z-10"
                offset={2}
              />
            )}
          </div>
        </div>

        <div>
          {isMobile ? (
            <div
              className={clsx(
                isSideCollapsed && 'justify-center hidden md:flex'
              )}>
              <div className="flex ml-3 mr-3 gap-2 border-b border-b-tardis-500 justify-start">
                <ul className="w-full">
                  <li>
                    <Link
                      className="bg block"
                      to="/profile"
                      data-tooltip-id="nav-tooltip"
                      data-tooltip-content="Home">
                      <Button
                        className={clsx(
                          'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark'
                        )}
                        onClick={() => closeSideMenu()}
                        variant="text">
                        <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                          Profile
                        </div>
                      </Button>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="bg block"
                      to="/settings"
                      data-tooltip-id="nav-tooltip"
                      data-tooltip-content="Home">
                      <Button
                        className={clsx(
                          'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark'
                        )}
                        onClick={() => closeSideMenu()}
                        variant="text">
                        <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                          Settings
                        </div>
                      </Button>
                    </Link>
                  </li>
                  {isLender && (
                    <>
                      <li>
                        <Link
                          className="bg block"
                          to="/users"
                          data-tooltip-id="nav-tooltip"
                          data-tooltip-content="Home">
                          <Button
                            className={clsx(
                              'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark'
                            )}
                            onClick={() => closeSideMenu()}
                            variant="text">
                            <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                              Users
                            </div>
                          </Button>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="bg block"
                          to="/broker"
                          data-tooltip-id="nav-tooltip"
                          data-tooltip-content="Home">
                          <Button
                            className={clsx(
                              'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark'
                            )}
                            onClick={() => closeSideMenu()}
                            variant="text">
                            <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                              Brokers
                            </div>
                          </Button>
                        </Link>
                      </li>
                    </>
                  )}
                  <li>
                    <a
                      href="mailto:support@authentik8.com.au?subject=Authentik8%20Management%20Portal%20Support"
                      className="bg block"
                      data-tooltip-id="nav-tooltip"
                      data-tooltip-content="Home">
                      <Button
                        className={clsx(
                          'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark'
                        )}
                        onClick={() => closeSideMenu()}
                        variant="text">
                        <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                          Support
                        </div>
                      </Button>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex items-center gap-3 w-full pl-5 pr-2 py-6 mb-24">
                <div
                  className="w-10 h-10 rounded-full flex items-center justify-center"
                  style={{
                    backgroundColor: '#' + (user.avatarColour || 'DD3434'),
                    minWidth: '40px',
                    minHeight: '40px'
                  }}>
                  <span
                    className={`text-white ${
                      !user?.initials ||
                      (user?.initials && user?.initials?.length === 4)
                        ? 'text-xs'
                        : 'text-sm'
                    } font-semibold`}>
                    {user.initials ||
                      user?.firstName?.[0] + user?.lastName?.[0]}
                  </span>
                </div>
                <div className="truncate">
                  <p className="text-grey-50 font-[600] capitalize text-sm flex-1">
                    {user?.firstName + ' ' + user?.lastName}
                  </p>
                  <p className="text-grey-50 capitalize text-sm flex-1 truncate">
                    {email}
                  </p>
                </div>
                <Link to="/login">
                  <IconButton className="rounded-full" variant="text">
                    <LogoutIcon className="text-white" />
                  </IconButton>
                </Link>
              </div>
            </div>
          ) : (
            <div
              className={clsx(
                'flex p-4',
                isSideCollapsed
                  ? 'justify-center hidden md:flex'
                  : 'justify-end'
              )}>
              <div className="w-full">
                <div className="w-full p-4">
                  <div className="w-full h-[1px] border-b border-b-tardis-500"></div>
                </div>
                <a
                  href="mailto:support@authentik8.com.au?subject=Authentik8%20Management%20Portal%20Support"
                  className="bg block"
                  data-tooltip-id="nav-tooltip"
                  data-tooltip-content="Support">
                  <Button
                    className={clsx(
                      'py-4 text-grey-50 text-base capitalize font-medium flex gap-3 w-full h-full px-3 hover:bg-secondary-dark active:bg-secondary-dark',
                      pathname === '/' && 'bg-secondary-dark',
                      isSideCollapsed && 'justify-center'
                    )}
                    onClick={() => closeSideMenu()}
                    variant="text">
                    <div className="sm:flex text-left whitespace-no-wrap gap-3 flex">
                      <SupportIcon style={{ width: '24px', height: '24px' }} />
                      {!isSideCollapsed && 'Support'}
                    </div>
                  </Button>
                </a>

                <div
                  className={clsx(
                    'cursor-pointer float-right mt-2 mr-4 mb-4',
                    isSideCollapsed ? 'hidden' : 'visible'
                  )}
                  onClick={() => setSideCollapse(!isSideCollapsed)}>
                  <ArrowLeftIcon />
                </div>
                <div
                  className={clsx(
                    'cursor-pointer float-left ml-3 mt-2 mb-4',
                    isSideCollapsed ? 'visible' : 'hidden'
                  )}
                  onClick={() => setSideCollapse(!isSideCollapsed)}>
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={clsx('w-full', !isMobile && 'hidden')}>
        <nav className="bg-secondary">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-[72px] w-full">
              <div className="flex flex-row  items-center gap-x-3 w-full">
                <AppImage />
                <AppMobileIcon />
              </div>
              <div className="md:hidden flex flex-row">
                <IconButton className="bg-secondary" variant="text">
                  <BellIcon />
                </IconButton>
                <IconButton
                  onClick={toggleMenu}
                  className="bg-secondary"
                  variant="text">
                  <MoreIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
