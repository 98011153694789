import _map from 'lodash/map';
import { DETAILS_VIEW_CERT } from '@constants';
import _get from 'lodash/get';
type Props = {
  cert: object;
};
const Cert = ({ cert }: Props) => {
  return (
    <>
      {_map(
        DETAILS_VIEW_CERT,
        (row: any, index: number) =>
          _get(cert, row.keyPath) && (
            <div key={index} className="flex justify-between md:justify-start flex-row-reverse flex-row md:flex-col items-start gap-2 ">
              <p className="text-base text-grey-700 break-words text-right md:text-left">
                {_get(cert, row.keyPath)}
              </p>
              <p className="text-grey-500 text-xs leading-5">{row.displayName}</p>
            </div>
          )
      )}
    </>
  );
};
export default Cert;
