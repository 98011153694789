import Button from '@components/forms/Button';
import { useNavigate } from 'react-router-dom';

const ResetPasswordComplete = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
      <form>
        <h3 className="text-2xl text-grey-900 font-semibold mb-6 text-center">
          Reset password
        </h3>
        <p className="text-center text-grey-600 mb-12">
          We’ve sent the password reset to your email.
        </p>

        <div className="w-full">
          <Button
            onClick={() => navigate('/login')}
            className="w-full px-4 py-2 bg-primary normal-case text-white text-base font-semibold rounded-md shadow-sm">
            Return to the login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordComplete;
