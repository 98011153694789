import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@material-tailwind/react';

import '@styles/index.css';

import App from './App';

ReactDOM.createRoot(document.getElementById('root')!).render(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
