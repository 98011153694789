import { Outlet } from 'react-router-dom';
import Sidebar from '@components/widgets/Sidebar';

const PrivateLayout = () => {
  return (
    <div className="layout_wrapper w-full h-full flex-col md:flex-row">
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default PrivateLayout;
