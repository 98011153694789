export const processFile = (
  file: File,
  maxSize: number,
  callback: (imageDataUrl: string, file: File) => void
) => {
  const img = new Image();
  const reader = new FileReader();

  reader.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const compressImage = (quality: number) => {
        canvas.toBlob(
          (compressedBlob) => {
            if (!compressedBlob) return;

            const compressedFile = new File([compressedBlob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });

            const compressedReader = new FileReader();
            compressedReader.onload = () => {
              const compressedImageDataUrl = compressedReader.result as string;
              if (compressedImageDataUrl.length > maxSize && quality > 0.1) {
                // Still too big, continue compressing
                const newQuality = quality - 0.08;
                compressImage(Math.max(newQuality, 0.1));
              } else {
                // Size is acceptable or we've reached the minimum quality
                callback(compressedImageDataUrl, compressedFile);
              }
            };
            compressedReader.readAsDataURL(compressedBlob);
          },
          'image/jpeg',
          quality
        );
      };

      // Start the compression process
      const originalImageDataUrl = reader.result as string;
      if (originalImageDataUrl.length > maxSize) {
        // If the original image is too large, start compression
        const initialQuality = 0.95;
        compressImage(initialQuality);
      } else {
        // Use the original if it's small enough
        callback(originalImageDataUrl, file);
      }
    };
    img.src = reader.result as string;
  };
  reader.readAsDataURL(file);
};
