/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input from '@components/forms/Input';
import { useAuthStore, useOrgStore, useSellerStore } from '@stores';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { SellerPageTitle } from '../../types/common';
import { useMediaQuery } from 'usehooks-ts';
import {
  COMPLETE_INSPECTION_LOGIN,
  CURRENT_USER,
  GET_INSPECTION_INVITE,
  GET_MY_INSPECTION,
  UPDATE_INSPECTION,
  post,
  START_INSPECTION_LOGIN,
  UPDATE_USER
} from '@services';
import Loader from '@components/widgets/Loader';
const SellerLogin = () => {
  useEffect(() => {
    const { setIsHome } = useSellerStore.getState();
    setIsHome(false);
  }, []);
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const [inviteInspectionId, setInspectionId] = useState('');
  const [primaryLogo, setPrimaryLogo] = useState('');
  const { setOrg } = useOrgStore();
  const { hash } = useLocation();
  const [token, setToken] = useState('');
  const navigator = useNavigate();
  useEffect(() => {
    const userToken =
      hash &&
      hash.split('token=').length > 0 &&
      decodeURIComponent(hash.split('token=')[1]);
    localStorage.clear();
    if (userToken) {
      setToken(userToken);
      setIsLoading(true);
      post(GET_INSPECTION_INVITE, { token: userToken }, false)
        .then((data) => {
          setIsLoading(false);
          setStep(1);
          setValue('email', data.inspectionInvite.email);
          setPrimaryColor(`#${data.inspectionInvite.lenderPrimaryColour}`);
          setHasAccepted(!!data.inspectionInvite.hasAcceptedTerms);
          setPrimaryLogo(data.inspectionInvite.lenderLogoUrl);
          setInspectionId(data.inspectionInvite.inspectionId);
          setOrg({
            ...data.inspectionInvite,
            organisationId: data.inspectionInvite.lenderOrganisationId,
            name: data.inspectionInvite.lenderName,
            logoBase64: data.inspectionInvite.lenderLogoBase64,
            logoUrl: data.inspectionInvite.lenderLogoUrl,
            primaryColour: data.inspectionInvite.lenderPrimaryColour
          });
          localStorage.setItem('email', data.inspectionInvite.email);
          localStorage.setItem('firstName', data.inspectionInvite.firstName);
          localStorage.setItem('lastName', data.inspectionInvite.lastName);
          // Set the lender organisation id inspectionUserId  !== authentik8UserId because there isn't organisation for seller user sometimes.
          localStorage.setItem(
            `lenders_${data.inspectionInvite.inspectionUserId}`,
            JSON.stringify([
              {
                organisationId: data.inspectionInvite.lenderOrganisationId,
                organisationName: data.inspectionInvite.lenderName,
                roles: []
              }
            ])
          );
          localStorage.setItem(
            `lenders_${data.inspectionInvite.authentik8UserId}`,
            JSON.stringify([
              {
                organisationId: data.inspectionInvite.lenderOrganisationId,
                organisationName: data.inspectionInvite.lenderName,
                roles: []
              }
            ])
          );
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
          navigator(`/inspection/not-found/${e.response.data.error.code}`);
        });
    } else {
      setIsLoading(false);
      setStep(0);
    }
  }, [hash]);
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    }
  });
  const { email } = watch();
  const [step, setStep] = useState(1);
  const onSubmit = () => setStep(2);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);
  if (isLoading) return <Loader />;
  if (step === 1) {
    return (
      <SellerEmailInitialPage
        email={email}
        primaryColor={primaryColor}
        logoUrl={primaryLogo}
        setStep={() => setStep(2)}
      />
    );
  } else if (step === 2) {
    return (
      <SellerEmailLoginForm
        email={email}
        token={token}
        primaryColor={primaryColor}
        logoUrl={primaryLogo}
        setStep={setStep}
        hasAccepted={hasAccepted}
        inspectionId={inviteInspectionId}
      />
    );
  } else if (step === 3) {
    return (
      <SellerTermsPage
        primaryColor={primaryColor}
        token={token}
        setStep={setStep}
        inspectionId={inviteInspectionId}
      />
    );
  } else if (step === 4) {
    return (
      <SellerMobileLoginForm
        primaryColor={primaryColor}
        token={token}
        hasAccepted={hasAccepted}
        logoUrl={primaryLogo}
        setStep={setStep}
      />
    );
  } else if (step === 5) {
    return (
      <SellerVerification primaryColor={primaryColor} logoUrl={primaryLogo} />
    );
  }
  return (
    <div className="px-4 gap-16 flex flex-col justify-center items-center w-full h-screen">
      <div>
        <img
          src={`data:image/svg+xml;base64,${primaryLogo}`}
          alt="Uploaded SVG"
          className="max-w-full max-h-20"
        />
      </div>
      <div className="mx-auto w-full bg-white gap-6 py-[50px] px-6 rounded-xl shadow-paper relative max-w-[600px]">
        <form className="flex flex-col gap-6">
          <div className="gap-2 flex flex-col">
            <h3 className="text-2xl text-grey-900 font-semibold text-center">
              Welcome
            </h3>
            <p className="text-center text-grey-600">
              Enter your email address to continue
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <label className="flex flex-col">
              <span className="text-sm font-medium text-grey-700">Email</span>
              <Input
                {...register(`email`)}
                placeholder="example@mail.com"
                type="email"
                value={email}
              />
              {errors.email && (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.email.message}
                </span>
              )}
            </label>
          </div>

          <div className="flex flex-col justify-center">
            <Button
              className={clsx(
                'w-full text-white text-md font-semibold normal',
                primaryColor && `bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={handleSubmit(onSubmit)}>
              Continue
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
type SellerEmailInitialProps = {
  email?: string;
  primaryColor: string;
  logoUrl: string;
  setStep: () => void;
};
const SellerEmailInitialPage: React.FC<SellerEmailInitialProps> = ({
  email,
  primaryColor,
  logoUrl,
  setStep
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div
      className="px-4 gap-16 flex flex-col justify-center items-center w-full"
      style={{ height: isMobile ? 'calc(100vh - 128px)' : '100vh' }}>
      <div className="flex flex-col gap-5">
        <img src={logoUrl} alt="Uploaded SVG" className="max-w-full max-h-20" />
      </div>
      <div className="mx-auto w-full bg-white gap-6 py-[50px] px-6 rounded-xl shadow-paper relative max-w-[600px]">
        <form className="flex flex-col gap-6">
          <div className="gap-2 flex flex-col">
            <h3 className="text-2xl text-grey-900 font-semibold text-center">
              Welcome
            </h3>
            <p className="text-center text-grey-600">
              To get started, we first need to verify your email address.
            </p>
            <p className="text-center text-grey-600">
              Press continue and we’ll send an email to <b>{email}</b> with your
              access code.
            </p>
          </div>

          <div className="flex flex-col gap-4 justify-center">
            <Button
              className={clsx(
                'w-full text-white text-md font-semibold normal',
                primaryColor && `bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={setStep}>
              Continue
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

type SellerLoginFormProps = {
  email?: string;
  mobile?: string;
  inspectionId?: string;
  hasAccepted: boolean;
  token: string;
  primaryColor: string;
  logoUrl: string;
  setStep: (step: number) => void;
};
const SellerEmailLoginForm: React.FC<SellerLoginFormProps> = ({
  email,
  token,
  primaryColor,
  logoUrl,
  setStep,
  hasAccepted,
  inspectionId
}) => {
  const loginFormSchema = yup
    .object({
      emailValue: yup
        .string()
        .required('Please enter your email.')
        .email('Please provide a valid email address'),
      password: yup
        .string()
        .matches(/^[0-9]{6}$/, 'Must be exactly 6 digits')
        .required('Please enter your password.')
    })
    .required();
  const {
    register,
    watch,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      emailValue: email || '',
      password: ''
    }
  });
  const [isEditing, setIsEditing] = useState(false);
  const { emailValue, password } = watch();
  const toggleEdit = () => setIsEditing(!isEditing);
  const [ticket, setTicket] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const resendCode = () => {
    setLoading(true);
    post(START_INSPECTION_LOGIN, { inviteToken: token }, false)
      .then((data) => {
        setLoading(false);
        const ticket = data;
        if (ticket) {
          setTicket(ticket);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        setError('password', {
          type: 'custom',
          message: e.response.data.error.message
        });
      });
  };
  const navigate = useNavigate();
  const { setUser } = useAuthStore();
  const setUpCurrentUser = (cb: () => void) => {
    setLoading(true);
    post(CURRENT_USER, {}).then((resp) => {
      setLoading(false);
      if (resp.user) {
        setUser(resp.user);
        localStorage.setItem('userId', resp.user.userId);
        localStorage.setItem('lastLogin', resp.user.lastLogin);
        localStorage.setItem('email', resp.user.email);
        localStorage.setItem('firstName', resp.user.firstName);
        localStorage.setItem('lastName', resp.user.lastName);
      }
      const userId = resp.user.userId;

      if (resp.verifiers && resp.verifiers.length > 0) {
        localStorage.setItem(
          `verifiers_${userId}`,
          JSON.stringify(resp.verifiers)
        );
      }
      if (resp.lenders && resp.lenders.length > 0) {
        localStorage.setItem(`lenders_${userId}`, JSON.stringify(resp.lenders));
      }
      cb();
    });
  };
  // Update the useEffect to only run once on component mount
  useEffect(() => {
    // Only send code if token exists and it's the initial mount
    if (token) {
      const initialCodeSent = sessionStorage.getItem(`code_sent_${token}`);
      if (!initialCodeSent) {
        resendCode();
        sessionStorage.setItem(`code_sent_${token}`, 'true');
      }
    }
  }, []);
  const onSubmit = (data: any) => {
    setLoading(true);
    post(
      COMPLETE_INSPECTION_LOGIN,
      { ...ticket, verificationToken: data.password },
      false
    )
      .then((data) => {
        setLoading(false);
        if (data.accessToken) {
          localStorage.setItem('accessToken', data.accessToken);
          localStorage.setItem('refreshToken', data.refreshToken);
          localStorage.setItem('expiry', data.expiry);
          setUpCurrentUser(() =>
            hasAccepted ? navigate(`/inspection/${inspectionId}`) : setStep(3)
          );
        } else {
          setTicket(data);
          setError('password', {
            type: 'custom',
            message: 'The verification code is invalid, please try again.'
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setError('password', {
          type: 'custom',
          message: 'Maximum verification attempts exceeded.'
        });
      });
  };
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isLoading) return <Loader />;
  return (
    <div
      className="px-4 gap-16 flex flex-col justify-center items-center w-full"
      style={{ height: isMobile ? 'calc(100vh - 128px)' : '100vh' }}>
      <div className="flex flex-col gap-5">
        <img src={logoUrl} alt="Uploaded SVG" className="max-w-full max-h-20" />
      </div>
      <div className="mx-auto w-full bg-white gap-6 py-[50px] px-6 rounded-xl shadow-paper relative max-w-[600px]">
        <form className="flex flex-col gap-6">
          <div className="gap-2 flex flex-col">
            <h3 className="text-2xl text-grey-900 font-semibold text-center">
              Verify your email
            </h3>
            <p className="text-center text-grey-600">
              We&apos;ve sent an email with your code to <b>{email}</b>
            </p>
          </div>
          <div className="flex flex-col gap-5">
            {!token && (
              <div className="relative flex w-full">
                <label className="flex flex-col w-full">
                  <span className="text-sm font-medium text-grey-700">
                    Email
                  </span>
                  <Input
                    type="email"
                    variant="static"
                    disabled={!isEditing}
                    value={emailValue}
                    icon={errors.emailValue && <ErrorIcon />}
                    inputClassName="pr-20 w-full !rounded !px-3 !py-2.5"
                    containerProps={{
                      className: 'min-w-0'
                    }}
                  />
                </label>
                <a
                  href="#"
                  color={emailValue ? 'gray' : 'blue-gray'}
                  className="!absolute right-4 top-8 rounded text-blue-500 font-medium"
                  onClick={toggleEdit}>
                  Edit
                </a>
              </div>
            )}
            <label className="flex flex-col">
              <span className="text-sm font-medium text-grey-700">
                Enter code from email
              </span>
              <Input
                type="text"
                {...register(`password`, {
                  onChange: (e) => {
                    let value = e.target.value;
                    if (value.length > 6) {
                      value = value.substring(0, 6);
                    }
                    setValue('password', value);
                  }
                })}
                placeholder="Enter your code"
                icon={errors.password && <ErrorIcon />}
                className="rounded"
                value={password}
              />
              {errors.password && (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.password.message}
                </span>
              )}
            </label>
          </div>

          <div className="flex flex-col gap-4 justify-center">
            <Button
              type="submit"
              className={clsx(
                'w-full text-white text-md font-semibold normal',
                primaryColor && `bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={handleSubmit(onSubmit)}>
              Continue
            </Button>
            <p className="text-grey-600 text-center">
              Didn’t receive a code?{' '}
              <a
                href="#"
                className="font-semibold text-blue-500 underline"
                onClick={(event) => {
                  event.preventDefault();
                  setValue('password', '');
                  resendCode();
                }}>
                Resend
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
type SellerTermsPageProps = {
  primaryColor: string;
  token?: string;
  inspectionId?: string;
  setStep: (step: number) => void;
};
const SellerTermsPage: React.FC<SellerTermsPageProps> = ({
  primaryColor,
  inspectionId,
  token,
  setStep
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleAcceptClick = () => {
    setIsLoading(true);

    post(UPDATE_INSPECTION, { inspectionId, acceptTerms: true }, false)
      .then(() => {
        setIsLoading(false);
        if (token) {
          navigate(`/inspection/${inspectionId}`);
        } else {
          setStep(4);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  };
  if (isLoading) return <Loader />;
  return (
    <div className="flex flex-col w-full overflow-y-scroll no-scrollbar bg-gray-50 pb-16">
      <div className="flex flex-col justify-center items-center gap-5 h-18 p-4 border-b ">
        <p className="text-grey-900 font-semibold">Welcome</p>
      </div>
      <div className="mx-auto w-full flex flex-col gap-8 py-8 px-4 pb-[100px]">
        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Welcome to Authentik8!
          </h3>
          <p className="text-left text-grey-600 text-sm">
            This is a summary only; please review the{' '}
            <a
              href="https://www.authentik8.com.au/seller-terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
              className="underline">
              Terms of Use
            </a>{' '}
            for full details.
          </p>
        </div>
        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Acceptance of Terms
          </h3>
          <p className="text-left text-grey-600 text-sm">
            By using this service, you acknowledge and agree to abide by the
            terms outlined.
          </p>
        </div>
        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Privacy
          </h3>
          <p className="text-left text-grey-600 text-sm">
            Authentik8 values your privacy and complies with the Privacy Act
            1988 (Cth) and the Australian Privacy Principles (APPs). Our Privacy
            Policy outlines how we collect, use, and protect your data. By using
            this service, you consent to our data practices as described in the{' '}
            <a
              href="https://www.authentik8.com.au/seller-privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="underline">
              Privacy Policy
            </a>
            .
          </p>
        </div>

        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Data Collection and Third Parties
          </h3>
          <p className="text-left text-grey-600 text-sm">
            Authentik8 collects necessary information, like identity and vehicle
            details, to verify sellers. We also use third-party services such as
            Truuth and government data services to support our verification
            processes. Authentik8 is not responsible for issues arising from
            these third-party services. Any disputes regarding their services
            should be directed to them, as their terms apply.
          </p>
        </div>
        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Your Responsibilities
          </h3>
          <p className="text-left text-grey-600 text-sm">
            <ul className="list-disc list-inside">
              <li>
                Ensure the accuracy of all submitted documents and information.
              </li>
              <li>
                Keep your access code and email secure; sharing access
                credentials is not allowed.
              </li>
              <li>Use the platform responsibly, adhering to lawful use.</li>
            </ul>
          </p>
        </div>

        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Limited Liability
          </h3>
          <p className="text-left text-grey-600 text-sm">
            The service is provided “as-is,” and Authentik8 is not responsible
            for indirect, incidental, special or consequential losses or damages
            arising from your use of the service.
          </p>
        </div>

        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Termination
          </h3>
          <p className="text-left text-grey-600 text-sm">
            Authentik8 reserves the right to suspend or terminate access for any
            term of use violations or suspected fraudulent or inappropriate
            usage.
          </p>
        </div>
        <div className="gap-3 flex flex-col">
          <h3 className="text-base text-grey-800 font-semibold text-left">
            Contact Information
          </h3>
          <p className="text-left text-grey-600 text-sm">
            For any questions, contact us at{' '}
            <a className="underline" href="mailto:support@authentik8.com.au">
              support@authentik8.com.au
            </a>
            .
          </p>
        </div>
      </div>
      <div className="block lg:relative lg:w-full lg:justify-end w-full px-4 py-6 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        <Button
          className={clsx(
            'w-full text-white text-md font-semibold normal-case',
            primaryColor && `bg-[${primaryColor}]`
          )}
          style={{ backgroundColor: primaryColor }}
          onClick={handleAcceptClick}>
          Agree and continue
        </Button>
      </div>
    </div>
  );
};
const SellerMobileLoginForm: React.FC<SellerLoginFormProps> = ({
  primaryColor,
  logoUrl,
  setStep
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const phoneRegExp = /^(04\d{8}|\+614\d{8}|614\d{8})$/;
  const loginFormSchema = yup.object({
    mobile: yup
      .string()
      .matches(
        phoneRegExp,
        'Invalid mobile number.  Please enter a valid mobile.'
      )
      .required('Please enter your mobile.'),
    password: yup
      .string()
      .matches(/^[0-9]{6}$/, 'Must be exactly 6 digits')
      .required('Please enter your password.')
  });
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      mobile: '',
      password: ''
    }
  });
  const { mobile, password } = watch();
  const onSubmit = () => {
    setStep(5);
  };
  return (
    <div
      className="px-4 gap-16 flex flex-col justify-center items-center w-full h-full"
      style={{ height: isMobile ? 'calc(100vh - 128px)' : '100vh' }}>
      <div className="flex flex-col gap-5">
        <img src={logoUrl} alt="Uploaded SVG" className="max-w-full max-h-20" />
      </div>
      <div className="mx-auto w-full bg-white gap-6 py-[50px] px-6 rounded-xl shadow-paper relative max-w-[600px]">
        <form className="flex flex-col gap-6">
          <div className="gap-2 flex flex-col">
            <h3 className="text-2xl text-grey-900 font-semibold text-center">
              Verify your mobile
            </h3>
            <p className="text-center text-grey-600">
              We&apos;ve sent a text message to:
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <label className="flex flex-col">
              <span className="text-sm font-medium text-grey-700">Mobile</span>
              <Input
                {...register(`mobile`)}
                placeholder="Enter your mobile number"
                icon={errors.mobile && <ErrorIcon />}
                className="rounded"
                value={mobile}
              />
              {errors.mobile && (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.mobile.message}
                </span>
              )}
            </label>
            <label className="flex flex-col">
              <span className="text-sm font-medium text-grey-700">
                Enter the 6-digit code
              </span>
              <Input
                type="text"
                {...register(`password`, {
                  onChange: (e) => {
                    let value = e.target.value;
                    if (value.length > 6) {
                      value = value.substring(0, 6);
                    }
                    setValue('password', value);
                  }
                })}
                icon={errors.password && <ErrorIcon />}
                placeholder="Enter your password"
                className="rounded"
                value={password}
              />
              {errors.password && (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.password.message}
                </span>
              )}
            </label>
          </div>

          <div className="flex flex-col gap-4 justify-center">
            <Button
              className={clsx(
                'w-full text-white text-md font-semibold normal',
                primaryColor && `bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={handleSubmit(onSubmit)}>
              Continue
            </Button>
            <p className="text-grey-600 text-center">
              Didn’t receive a code?{' '}
              <a href="#" className="font-semibold text-blue-500 underline">
                Resend
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

const SellerVerification = ({
  primaryColor,
  logoUrl
}: {
  primaryColor: string;
  logoUrl: string;
}) => {
  const [verifications, setVerifications] = useState<string[]>([]);
  const fetchVerifications = async () => {
    setIsLoading(true);
    post(GET_MY_INSPECTION, {}, false)
      .then((data) => {
        setIsLoading(false);
        if (data.inspections && data.inspections.length > 1) {
          setVerifications(data.inspections);
        } else if (data.inspections && data.inspections.length === 1) {
          navigate(`/inspection/${data.inspections[0].inspectionId}`);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  };
  useEffect(() => {
    fetchVerifications();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setSellerVerification } = useSellerStore();
  const handleSubmit = (data: any) => {
    setSellerVerification({
      isHome: true,
      activePageTitle: SellerPageTitle.Blank,
      ...SellerVerification
    });
    navigate(`/inspection/${data.inspectionId}`);
  };
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (isLoading) return <Loader />;
  return (
    <>
      <div
        className="px-4 gap-16 flex flex-col justify-center items-center w-full h-full"
        style={{ height: isMobile ? 'calc(100vh - 128px)' : '100vh' }}>
        <div className="flex flex-col gap-5">
          <img
            src={logoUrl}
            alt="Uploaded SVG"
            className="max-w-full max-h-20"
          />
        </div>
        <div className="flex flex-col gap-6">
          <p className="text-center text-grey-600">
            Choose the inspection you&apos;d like to continue with
          </p>
          <div className="flex flex-col mx-auto w-full bg-white rounded-xl shadow-paper relative max-w-[600px]">
            {verifications.map((verification: any, index: number) => (
              <Button
                key={index}
                onClick={() => handleSubmit(verification)}
                className={clsx(
                  'border-b border-grey-200 h-[68px] bg-white w-full p-5 flex rounded-none justify-between items-center font-semibold text-lg text-grey-900',
                  index === 0 && 'rounded-t-lg',
                  index === verifications.length - 1 && 'rounded-b-lg'
                )}>
                <div className="flex">
                  <div className="ml-4">
                    <div className="flex">
                      <p className="text-left mr-1 text-[18px] font-semibold mb-1">
                        {verification.friendlyId}
                      </p>
                    </div>
                  </div>
                </div>
                <LeftIcon />
              </Button>
            ))}
          </div>
          <div className="flex flex-col gap-4 justify-center w-full">
            <Button
              className={clsx(
                'w-full text-white text-md font-semibold normal',
                primaryColor && `bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SellerLogin;
