/* eslint-disable react/no-unescaped-entities */
import { Button } from '@material-tailwind/react';
import { DocumentCaptureStepEnum, SellerPage } from '@types';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import { ReactComponent as FrontIcon } from '@assets/icons/front_reg.svg';
import { ReactComponent as BackIcon } from '@assets/icons/back-reg.svg';
import { ReactComponent as CloudIcon } from '@assets/icons/cloud.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/arrow-narrow-up-right.svg';
import { ReactComponent as QRSample } from '@assets/icons/qr-sample.svg';
import { useState } from 'react';
import { Radio } from '@material-tailwind/react';
import Input from '@components/forms/Input';
import { isMobile } from 'react-device-detect';
import { SellerVerifyDesktopQR } from '@components/widgets/SellerVerifyDesktopQR';
import { useSellerStore } from '@stores';
import DocumentComponent from './CaptureProcess/DocumentCapture';

export const Register = ({
  setPage
}: {
  setPage: (page: SellerPage) => void;
}) => {
  const [step, setStep] = useState('init');

  return (
    <>
      <DocumentComponent
        type={DocumentCaptureStepEnum.registration}
        setPage={setPage}
        init={() => setStep('init')}
      />
    </>
  );
};
