import _map from 'lodash/map';
import { DETAILS_VIEW_VEHICLE_DETAILS } from '@constants';
import _get from 'lodash/get';
type Props = {
  vehicle: object;
};
const VehicleDetails = ({ vehicle }: Props) => {
  return (
    <>
      {_map(
        DETAILS_VIEW_VEHICLE_DETAILS,
        (row: any, index: number) =>
          _get(vehicle, row.keyPath) && (
            <div
              key={index}
              className="flex justify-between md:justify-start flex-row-reverse flex-row md:flex-col items-start gap-2">
              <p className="text-base text-grey-700 break-words text-right md:text-left">
                {_get(vehicle, row.keyPath)}
              </p>
              <p className="text-grey-500 text-xs leading-5">
                {row.displayName}
              </p>
            </div>
          )
      )}
    </>
  );
};

export default VehicleDetails;
