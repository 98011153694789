import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@components/forms/Button';
import dashboard from '@assets/images/dashboard.png';
import { ReactComponent as CloseIcon } from '@assets/icons/x_close_icon.svg';
import { ReactComponent as InfoErrorIcon } from '@assets/icons/info_error_icon.svg';
import checkDone from '@assets/images/checkDone.png';
import ReactECharts from 'echarts-for-react';
import { useAuthStore } from '@stores';
import { CURRENT_USER, post } from '@services';

const Dashboard = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [hasAlert, setHasAlert] = useState(false);
  const { user } = useAuthStore();
  const [pieOption, setPieOption] = useState({
    legend: {
      orient: 'vertical',
      left: 'left',
      icon: 'circle',
      bottom: '10%'
    },

    series: [
      {
        name: 'Inspection Status',
        type: 'pie',
        radius: '70%',
        center: ['50%', '35%'],
        data: [
          { value: 0, name: 'Submitted', itemStyle: { color: '#EAECF0' } },
          { value: 0, name: 'Initiated', itemStyle: { color: '#EAECF0' } },
          { value: 0, name: 'In Progress', itemStyle: { color: '#EAECF0' } },
          { value: 0, name: 'Completed', itemStyle: { color: '#EAECF0' } }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          show: false
        }
      }
    ]
  });
  const [lineOption, setLineOption] = useState({
    xAxis: {
      type: 'category',
      data: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: '#e31b54'
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(227, 27, 84, 0.7)'
              },
              {
                offset: 1,
                color: 'rgba(227, 27, 84, 0)'
              }
            ],
            global: false
          }
        }
      }
    ]
  });

  const navigate = useNavigate();
  useEffect(() => {
    post(CURRENT_USER, {}).then((res) => {});
  }, []);
  useEffect(() => {
    if (!hasAlert) return;
    setLineOption({
      ...lineOption,
      series: [
        {
          ...lineOption.series[0],
          data: [80, 50, 65, 59, 80, 81, 56, 55, 40, 60, 75, 50, 90, 100, 200]
        }
      ]
    });
    setPieOption({
      ...pieOption,
      series: [
        {
          ...pieOption.series[0],
          data: [
            { value: 5, name: 'Submitted', itemStyle: { color: '#e31b54' } },
            { value: 2, name: 'Initiated', itemStyle: { color: '#9e77ed' } },
            { value: 4, name: 'In Progress', itemStyle: { color: '#eaaa08' } },
            { value: 1, name: 'Completed', itemStyle: { color: '#16b364' } }
          ]
        }
      ]
    });
  }, [hasAlert, isVisible]);

  function changeEmpty() {
    setHasAlert(true);
  }
  return (
    <div className="flex flex-col justify-center">
      <div className="relative">
        {isVisible ? (
          <div>
            {/* <button
              className="absolute top-0 right-4 text-gray-400 hover:text-gray-600"
              onClick={() => setIsVisible(false)}>
              <CloseIcon className="h-6 w-6" />
            </button> */}
            <div className="flex flex-col md:flex-row pt-8 gap-6">
              <div className="h-[178px] md:h-auto md:w-1/3 flex justify-center">
                <img
                  className="object-contain"
                  src={dashboard}
                  alt="dashboard"
                  style={{ maxHeight: '100%' }}
                />
              </div>
              <div className="md:w-2/3 text-left">
                <h2 className="text-xl md:text-3xl font-extrabold text-gray-900 max-w-[700px]">
                  Hi {user.firstName} {user.lastName}! Welcome to the Authentik8
                  identity and vehicle verification portal
                </h2>
                <p className="mt-4 text-lg text-gray-600">
                  Authentik8 streamlines the process for identity and vehicle
                  verification. Within this portal, you can start inspections,
                  prompting vehicle sellers to undergo the verification process.
                </p>
                <p className="mt-4 text-lg text-gray-600">
                  Log in at any time to track the inspection&apos;s progress and
                  monitor identity or vehicle checks in real-time.
                </p>
                <div className="mt-8 flex justify-center md:justify-start space-x-4 mb-4">
                  <Button
                    className="capitalize bg-primary flex items-center gap-2 w-auto text-sm font-semibold py-2.5 font-[600] text-white lg:self-end sm:self-center mb-1"
                    onClick={() => navigate('/inspections/create')}>
                    New inspection
                  </Button>
                  <Button
                    className="bg-white border border-gray-300 hover:bg-gray-400 text-gray-800 text-sm font-[600] py-2 px-4 rounded inline-flex items-center"
                    onClick={() => navigate('/inspections')}>
                    Inspection List
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-[#FFF5F6] p-10 px-6 rounded-lg mb-8">
            <div className="flex flex-col md:flex-row justify-between items-start">
              <div>
                <div className="text-2xl font-bold text-black">
                  Hi {user.firstName} {user.lastName}!
                </div>
                <div className="text-sm text-black mt-1">
                  View your inspections list or get started with a new
                  inspection.
                </div>
              </div>
              <div className="flex flex-col justify-center h-[56px] items-center">
                <div className="flex gap-4">
                  <Button
                    className="capitalize bg-white border border-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                    onClick={() => navigate('/inspections')}>
                    Inspections List
                  </Button>
                  <Button
                    className="capitalize bg-primary flex items-center gap-2 w-auto text-sm font-semibold py-2.5 text-white lg:self-end sm:self-center mb-1"
                    onClick={() => navigate('/inspections/create')}>
                    New inspection
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isVisible && <hr className="my-8 -mx-6 shadow-sm" />}

      </div>
    </div>
  );
};

export default Dashboard;
