import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RotateIcon } from '@assets/icons/rotate_icon.svg';
import StatusChip from '@components/widgets/StatusChip';
import { INSPECTION_STATUS_VARIANT } from '@constants';
import Button from '@components/forms/Button';
import { InspectionStatusEnum } from '@types';
import Table, { TableHeader } from '@components/widgets/Table';
import { format, parseISO } from 'date-fns';

export const TABLE_HEAD: TableHeader[] = [
  {
    label: 'Created Date/Time',
    field: 'createdTime',
    sort: true,
    render: (row) => {
      const formattedDate = format(
        parseISO(row.createdTime),
        'dd/MM/yyyy h:mma'
      );
      return <span>{formattedDate}</span>;
    }
  },
  {
    label: 'VIN',
    field: 'vin'
  },
  {
    label: 'Registration',
    field: 'rego'
  },
  {
    label: 'State',
    field: 'state'
  },
  {
    label: 'Vehicle',
    field: 'vehicle',
    render: (row) => (
      <div className="flex flex-row ">
        <span className="normal-case">
          {row.vehicleMake &&
            row.vehicleModel &&
            `${row.vehicleYear || ''} ${row.vehicleMake || ''} ${
              row.vehicleModel || ''
            }`}
        </span>
      </div>
    )
  },
  {
    label: 'Status',
    field: 'status',
    render: (row) => (
      <StatusChip
        variant={INSPECTION_STATUS_VARIANT[row.status as InspectionStatusEnum]}
        label={row.status}
      />
    )
  }
];

interface LandingDataProps {
  landingData: any[];
  onLoadMore: (lastCreatedTime: string) => void; // Updated this line
}

const InspectionTable: React.FC<LandingDataProps> = ({ landingData, onLoadMore }) => {
  const navigate = useNavigate();
  const handleRowClick = (record: any) => {
    navigate(`/verifications/detail/${record.id}`);
  };

  const handleLoadMore = () => {
    if (landingData.length > 0) {
      const lastItem = landingData[landingData.length - 1];
      onLoadMore(lastItem.createdTime);
    }
  };

  return (
    <div className="pb-12">
      <Table
        headers={TABLE_HEAD}
        data={landingData}
        onClickRow={(row) => handleRowClick(row)}
      />
      {!landingData[landingData.length - 1]?.isLast && (
        <div className="flex justify-center pt-6">
          <Button
            className="bg-white border-grey-300 text-grey-600 text-sm flex items-center gap-2"
            variant="outlined"
            onClick={handleLoadMore} // Updated this line
          >
            <RotateIcon />
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};

export default InspectionTable;