import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
interface LenderFilterStoreState {
  query: string;
  setQuery: (data: any) => void;
  status: string;
  setStatus: (data: any) => void;
  lenderListData: [];
  setLenderList: (data: any) => void;
}

export const useLenderStore = create<LenderFilterStoreState>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      (set) => ({
        query: '',
        status: 'all',
        lenderListData: [],
        setQuery: (data) => set({ query: data }),
        setStatus: (data) => set({ status: data }),
        setLenderList: (data) => set({ lenderListData: data })
      }),
      {
        name: 'Lender'
      }
    )
  )
);
