import { Outlet, useLocation } from 'react-router-dom';
import Header from '@components/layouts/Header';
import Sidebar from '@components/widgets/Sidebar';
import RightDetailsBar from '@pages/Inspections/components/RightPanelCards/RightDetailsBar';
import { useInspectionDetailStore } from '@stores';
import { useMediaQuery } from 'usehooks-ts';
import LayoutFooter from '@components/layouts/LayoutFooter';
const PrivateLayout = () => {
  const isMobile = useMediaQuery('(max-width: 720px)');
  const location = useLocation();
  const inspectionDetailStore = useInspectionDetailStore();
  const isSideCollapsed = inspectionDetailStore.isSideCollapsed;
  return (
    <div className="layout_wrapper w-full h-full flex-col md:flex-row">
      <Sidebar />
      <div
        style={{
          maxWidth: isMobile
            ? '100%'
            : `calc(100vw - ${isSideCollapsed ? '80px' : '256px'})`
        }}
        className={`main min-h-full md:min-h-screen w-full flex flex-col pb-[44px]`}>
        <Header />
        <div className="content overflow-y-auto overflow-x-hidden w-full md:w-auto sm:justify-start">
          <div className="flex-1 p-4 lg:p-6 flex flex-col w-full ">
            <Outlet />
          </div>
        </div>
      </div>
      {location.pathname.indexOf('/inspections/detail/') >= 0 && (
        <RightDetailsBar />
      )}
      <LayoutFooter />
    </div>
  );
};

export default PrivateLayout;
