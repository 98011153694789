/* eslint-disable react/no-unescaped-entities */
import { ReactComponent as QRSample } from '@assets/icons/qr-sample.svg';
import { useSellerStore } from '@stores';

export const SellerVerifyDesktopQR = () => {
  const { sellerSession } = useSellerStore();
  const { qrCode } = sellerSession;
  return (
    <div className="w-full flex flex-col items-center">
      <p className="w-full text-grey-900 font-normal text-[16px] mb-16">
        Transition to a mobile device to complete this verification task. Simply
        scan the barcode below with your phone's camera to pick up where you
        left off.
      </p>
      {qrCode ? (
        <img
          className="w-[200px] p-2 bg-white"
          src={`data:image/svg+xml;base64,${qrCode}`}
          alt="QR Code"
        />
      ) : (
        <QRSample />
      )}

      <p className="text-grey-800 mt-8  text-[18px]">
        Transition to your mobile device to complete the submission process.
      </p>
    </div>
  );
};
