import _map from 'lodash/map';
import { ReactComponent as ArrowIcon } from '@assets/icons/arrow_grey_up_right_icon.svg';
import StatusChip from '@components/widgets/StatusChip';
import {
  INSPECTION_SECURITY_CHECK_VARIANT,
  INSPECTION_SECURITY_CHECK_LABEL_STATUS,
  SECURITY_CHECK_LIST
} from '@constants';
import { DocumentFormType, InspectionSecurityStatusEnum } from '@types';
import clsx from 'clsx';
const stringFormat = (template: string, ...args: any[]) => {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
};

const Variants = {
  success_light:
    'bg-success-50 border-success-500 active:bg-success-800 hover:bg-success-800',
  info_light:
    'border-grey-300 bg-grey-25 hover:bg-grey-100 focus:bg-grey-100 active:bg-grey-200',
  warning_light:
    'border-warning-500 bg-warning-50 active:bg-warning-700 hover:bg-warning-700',
  error_light:
    'border-error-400 bg-error-50 active:!bg-error-100 hover:bg-error-100',
  none_light:
    'border-grey-300 bg-grey-50 hover:bg-grey-100 focus:bg-grey-100 active:bg-grey-200'
};

export type SecurityCheckVariant =
  | 'success_light'
  | 'none_light'
  | 'info_light'
  | 'warning_light'
  | 'info_light';
const SecurityCheck = ({
  docType,
  data,
  setType,
  setStatus,
  setIsCollapsed,
  setVariant,
  setStatusChipLabel
}: {
  docType: object;
  data: any;
  setType: (type: DocumentFormType) => void;
  setStatus: (status: InspectionSecurityStatusEnum) => void;
  setIsCollapsed: (isCollapsed: boolean) => void;
  setVariant: (variant: string) => void;
  setStatusChipLabel: (statusChipLabel: string) => void;
}) => {
  const handleClick = (
    status: any,
    key: string,
    type: string,
    documentObj: any
  ) => {
    if (
      !(
        (status &&
          ![
            InspectionSecurityStatusEnum.notPerformed,
            InspectionSecurityStatusEnum.pass,
            InspectionSecurityStatusEnum.noRegister,
            InspectionSecurityStatusEnum.noReport,
            InspectionSecurityStatusEnum.noRecord,
            InspectionSecurityStatusEnum.notFound,
            InspectionSecurityStatusEnum.match,
            InspectionSecurityStatusEnum.error
          ].includes(status)) ||
        (type === 'NEVDIS_REGISTRATION' &&
          (status === InspectionSecurityStatusEnum.noRegister ||
            status === InspectionSecurityStatusEnum.discrepancyFound) &&
          documentObj.expiry &&
          documentObj.expiry !== '')
      )
    ) {
      return;
    }
    setType(key as DocumentFormType);
    setStatus(status);
    setVariant(getVariant(status, type, documentObj));
    setStatusChipLabel(getLabel(status, type, documentObj));
    setIsCollapsed(false);
  };
  const getVariant = (status: string, type: string, documentObj: any) =>
    type === 'PPSR_ENCUMBRANCE' &&
    status === InspectionSecurityStatusEnum.reportFound
      ? 'warning_light'
      : ['RAV_COMPLIANCE'].includes(type) &&
        status === InspectionSecurityStatusEnum.reportFound
      ? 'success_light'
      : type === 'NEVDIS_REGISTRATION' &&
        status === InspectionSecurityStatusEnum.reportFound &&
        documentObj.regoStatus ===
          InspectionSecurityStatusEnum.registered.toLocaleUpperCase()
      ? 'success_light'
      : ['PPSR_ENCUMBRANCE'].includes(type) &&
        status === InspectionSecurityStatusEnum.noRecord
      ? 'success_light'
      : type === 'NEVDIS_REGISTRATION' &&
        status === InspectionSecurityStatusEnum.noReport
      ? 'info_light'
      : ['RAV_COMPLIANCE'].includes(type) &&
        status === InspectionSecurityStatusEnum.noReport
      ? 'info_light'
      : INSPECTION_SECURITY_CHECK_VARIANT[
          status as InspectionSecurityStatusEnum
        ];
  const getLabel = (status: string, type: string, documentObj: any) => {
    if (status === InspectionSecurityStatusEnum.notRequested) {
      return 'Disabled';
    }

    if (
      type === 'NEVDIS_REGISTRATION' &&
      status === InspectionSecurityStatusEnum.expired
    ) {
      return 'Expired';
    }
    if (
      type === 'NEVDIS_REGISTRATION' &&
      status === InspectionSecurityStatusEnum.reportFound &&
      documentObj.regoStatus ===
        InspectionSecurityStatusEnum.registered.toLocaleUpperCase()
    ) {
      return 'Registered';
    }
    if (
      type === 'NEVDIS_REGISTRATION' &&
      status === InspectionSecurityStatusEnum.noReport
    ) {
      return 'Not Found';
    }
    if (
      ['NEVDIS_STOLEN', 'NEVDIS_WRITTEN_OFF'].includes(type) &&
      status === InspectionSecurityStatusEnum.reportFound
    ) {
      if (type === 'NEVDIS_STOLEN') {
        return documentObj.stolenDetails.length > 1
          ? `${documentObj.stolenDetails.length} Reports Found `
          : '1 Report Found';
      } else {
        return documentObj.writtenOffDetails.length > 1
          ? `${documentObj.writtenOffDetails.length} Reports Found `
          : '1 Report Found';
      }
    }
    if (
      type === 'PPSR_ENCUMBRANCE' &&
      status === InspectionSecurityStatusEnum.reportFound
    ) {
      return stringFormat(
        INSPECTION_SECURITY_CHECK_LABEL_STATUS[
          InspectionSecurityStatusEnum.registrations
        ],
        documentObj.ppsrData.length
      );
    }
    if (
      ['RAV_COMPLIANCE'].includes(type) &&
      status === InspectionSecurityStatusEnum.noReport
    ) {
      return 'No Found';
    }
    if (
      ['RAV_COMPLIANCE'].includes(type) &&
      status === InspectionSecurityStatusEnum.reportFound
    ) {
      return 'Received';
    }
    if (
      type === 'PPSR_ENCUMBRANCE' &&
      status === InspectionSecurityStatusEnum.noRecord
    ) {
      return 'No Registrations Found';
    }

    return INSPECTION_SECURITY_CHECK_LABEL_STATUS[
      status as keyof typeof INSPECTION_SECURITY_CHECK_LABEL_STATUS
    ];
  };
  return (
    <>
      <div className="text-md">
        <h1 className="text-black font-semibold">Vehicle Check</h1>
      </div>
      <div className="border-grey-200  w-full flex flex-col justify-center h-min">
        {_map(SECURITY_CHECK_LIST[5], (key: any, index) => {
          const items: { [key: string]: any } = data;
          const documentObj: any = items && items[key];
          const type = documentObj && documentObj['type'];
          const status =
            documentObj?.status || InspectionSecurityStatusEnum.notPerformed;
          if (!documentObj) return;
          const label = docType[key as keyof typeof docType];
          return (
            <div
              className={clsx(
                'flex border rounded-xl px-3.5 py-3 items-center justify-between mt-2 whitespace-nowrap  flex-wrap  items-start',
                ((status &&
                  ![
                    InspectionSecurityStatusEnum.notPerformed,
                    InspectionSecurityStatusEnum.pass,
                    InspectionSecurityStatusEnum.noRegister,
                    InspectionSecurityStatusEnum.noReport,
                    InspectionSecurityStatusEnum.noRecord,
                    InspectionSecurityStatusEnum.notFound,
                    InspectionSecurityStatusEnum.match,
                    InspectionSecurityStatusEnum.error
                  ].includes(status)) ||
                  (type === 'NEVDIS_REGISTRATION' &&
                    (status === InspectionSecurityStatusEnum.noRegister ||
                      status ===
                        InspectionSecurityStatusEnum.discrepancyFound) &&
                    documentObj.expiry &&
                    documentObj.expiry !== '')) &&
                  'cursor-pointer',
                index === 0 && 'first:mt-0 cursor-default',
                ['PPSR_ENCUMBRANCE'].includes(type) &&
                  status === InspectionSecurityStatusEnum.reportFound
                  ? Variants['warning_light']
                  : (['PPSR_ENCUMBRANCE'].includes(type) &&
                      status === InspectionSecurityStatusEnum.noRecord) ||
                    (['RAV_COMPLIANCE'].includes(type) &&
                      status === InspectionSecurityStatusEnum.reportFound) ||
                    (type === 'NEVDIS_REGISTRATION' &&
                      status === InspectionSecurityStatusEnum.reportFound &&
                      documentObj.regoStatus ===
                        InspectionSecurityStatusEnum.registered.toLocaleUpperCase())
                  ? Variants['success_light']
                  : (['RAV_COMPLIANCE'].includes(type) &&
                      status === InspectionSecurityStatusEnum.noReport) ||
                    (type === 'NEVDIS_REGISTRATION' &&
                      status === InspectionSecurityStatusEnum.noReport)
                  ? Variants['info_light']
                  : Variants[
                      INSPECTION_SECURITY_CHECK_VARIANT[
                        status as InspectionSecurityStatusEnum
                      ] as keyof typeof Variants
                    ],
                status === InspectionSecurityStatusEnum.notRequested &&
                  key !== 'ppsr' &&
                  key !== 'rav' &&
                  key !== 'registration' &&
                  key !== 'stolen' &&
                  key !== 'writtenOff' &&
                  'hidden'
              )}
              key={index}
              onClick={() => handleClick(status, key, type, documentObj)}>
              <div className="flex flex-row items-center whitespace-nowrap gap-3">
                <span className="text-sm font-semibold">{label}</span>
              </div>
              {![
                InspectionSecurityStatusEnum.notPerformed,
                InspectionSecurityStatusEnum.error,
              ].includes(status) && (
                <div className="flex flex-wrap flex-row justify-start gap-x-6 whitespace-nowrap">
                  {((type === 'NEVDIS_REGISTRATION' &&
                    documentObj.expiry &&
                    documentObj.expiry !== '') ||
                    (type !== 'NEVDIS_REGISTRATION' &&
                      ![
                        InspectionSecurityStatusEnum.noReport,
                        InspectionSecurityStatusEnum.noRecord
                      ].includes(status))) &&
                    ![
                      'ppsr',
                      'rav',
                      'registration',
                      'stolen',
                      'writtenOff'
                    ].includes(key) && (
                      <span className="flex flex-row font-semibold justify-center items-center text-sm text-grey-600">
                        <span className="underline">Details</span>
                        <ArrowIcon />
                      </span>
                    )}
                  <StatusChip
                    variant={getVariant(status, type, documentObj)}
                    clsName={clsx('border text-xs font-normal max-w-full')}
                    label={getLabel(status, type, documentObj)}
                  />
                </div>
              )}
            </div>
          );
        })}
        <div></div>
      </div>
    </>
  );
};

export default SecurityCheck;
