/* eslint-disable react/no-unescaped-entities */
import { DocumentCaptureStepEnum, SellerPage } from '@types';
import { useState } from 'react';
import CardCaptureComponent from './CaptureProcess/CardCapture';

export const Bank = ({ setPage }: { setPage: (page: SellerPage) => void }) => {
  const [step, setStep] = useState('init');

  return (
    <>
      <CardCaptureComponent
        type={DocumentCaptureStepEnum.bank}
        setPage={setPage}
        init={() => setStep('init')}
      />
    </>
  );
};
