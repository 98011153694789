import React from 'react';
const LightIcon = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33268 14.7153V16.6665C8.33268 17.587 9.07887 18.3332 9.99935 18.3332C10.9198 18.3332 11.666 17.587 11.666 16.6665V14.7153M9.99935 1.6665V2.49984M2.49935 9.99984H1.66602M4.58268 4.58317L4.0826 4.08309M15.416 4.58317L15.9162 4.08309M18.3327 9.99984H17.4993M14.9993 9.99984C14.9993 12.7613 12.7608 14.9998 9.99935 14.9998C7.23793 14.9998 4.99935 12.7613 4.99935 9.99984C4.99935 7.23841 7.23793 4.99984 9.99935 4.99984C12.7608 4.99984 14.9993 7.23841 14.9993 9.99984Z"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default LightIcon;
