import OnboardingLayout from '@components/layouts/OnboardingLayout';
import React, { useState, useEffect, useRef } from 'react';
import _intersection from 'lodash/intersection';
import _find from 'lodash/find';
import {
  post,
  CREATE_INSPECTION_DOCUMENT,
  GET_INSPECTION_FILE
} from '@services';
import { CreateInvoiceStepEnum } from '@types';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import BuyerDetailStep from './BuyerDetailStep';
import SellerDetailStep from './SellerDetailStep';
import VehicleDetailStep from './VehicleDetailStep';
import ConfirmInspectionModal from '@components/widgets/ConfirmInspectionModal';
import {
  CreateInspectionStepEnum,
  NewInspectionModalEnum,
  confirmModalType
} from '@types';
import { useInvoiceDetailStore, useInspectionDetailStore } from '@stores';
const GenerateInvoice = () => {
  const params = useParams();
  const [step, setStep] = useState(CreateInvoiceStepEnum.Buyer);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState<confirmModalType>(
    NewInspectionModalEnum.close
  );
  const navigate = useNavigate();
  const { inspectionDetailData } = useInspectionDetailStore();

  const { setInvoiceDetailData, resetInvoiceDetailData } =
    useInvoiceDetailStore();
  useEffect(() => {
    resetInvoiceDetailData();

    const latestState = useInvoiceDetailStore.getState();
    const invoiceDetailData = latestState.invoiceDetailData;
    // Get year part from compliance month string (e.g. "2010-03" -> "2010")
    const year = inspectionDetailData?.nevdisVehicle?.complianceMonth?.split('-')?.[0];
    
    setInvoiceDetailData({
      seller: {
        ...invoiceDetailData?.seller,
        ...inspectionDetailData?.seller,
        address:
          [
            inspectionDetailData?.documents?.REGO_PAPERS?.address
              ?.addressNumber,
            inspectionDetailData?.documents?.REGO_PAPERS?.address?.street
          ]
            .filter(Boolean)
            .join(' ') || '',
        suburb: inspectionDetailData?.documents?.REGO_PAPERS?.address?.suburb,
        postcode:
          inspectionDetailData?.documents?.REGO_PAPERS?.address?.postalCode,
        state:
          inspectionDetailData?.documents?.REGO_PAPERS?.extraFields
            ?.regoCertificate?.state,
        bsb: inspectionDetailData?.documents?.BANK_STATEMENTS?.extraFields
          ?.bankStatement?.bsb,
        accountNumber:
          inspectionDetailData?.documents?.BANK_STATEMENTS?.extraFields
            ?.bankStatement?.accountNumber
      },
      buyer: {
        ...invoiceDetailData?.buyer,
        ...inspectionDetailData?.buyer,
        phone: inspectionDetailData?.buyer?.mobile
      },
      vehicle: {
        ...invoiceDetailData.vehicle,
        make: inspectionDetailData?.nevdisVehicle?.make,
        model: inspectionDetailData?.nevdisVehicle?.model,
        year: inspectionDetailData?.nevdisVehicle?.buildYear,
        complianceMonth: year,
        engineNumber: inspectionDetailData?.nevdisVehicle?.engineNumber,
        vin: inspectionDetailData?.vin,
        regoPlate: inspectionDetailData?.rego,
        regoState: inspectionDetailData?.extraFields?.licencePlate?.state
      }
    });
  }, []);
  const {
    setValue,
    formState: { errors }
  } = useForm();
  const successSubmit = false;
  function onDashboardClick() {}
  function onCloseClick() {
    setIsOpenModal(true);
    setModalType(NewInspectionModalEnum.close);
  }
  function onCloseModalClick() {
    setIsOpenModal(false);
  }
  function submit() {
    const latestState = useInvoiceDetailStore.getState();
    const invoiceDetailData = latestState.invoiceDetailData;
    post(CREATE_INSPECTION_DOCUMENT, {
      inspectionId: params.id,
      documentType: 'PRIVATE_SALE_INVOICE',
      fields: {
        buyer: {
          ...invoiceDetailData.buyer,
          phone: invoiceDetailData.buyer.mobile,
          address: `${invoiceDetailData.buyer.address}, ${invoiceDetailData.buyer.suburb}, ${invoiceDetailData.buyer.state}, ${invoiceDetailData.buyer.postcode}`,
          fullName: `${invoiceDetailData.buyer.firstName} ${invoiceDetailData.buyer.lastName}`
        },
        seller: {
          ...invoiceDetailData.seller,
          phone: invoiceDetailData.seller.mobile,
          address: `${invoiceDetailData.seller.address}, ${invoiceDetailData.seller.suburb}, ${invoiceDetailData.seller.state}, ${invoiceDetailData.seller.postcode}`,
          fullName: `${invoiceDetailData.seller.firstName} ${invoiceDetailData.seller.lastName}`
        },
        vehicle: {
          ...invoiceDetailData.vehicle,
          complianceMonth: invoiceDetailData.vehicle.complianceMonth
            ? `${invoiceDetailData.vehicle.complianceMonth}-01`
            : ''
        },
        sale: {
          balance: invoiceDetailData.vehicle.balance,
          deposit: invoiceDetailData.vehicle.deposit,
          purchasePrice: invoiceDetailData.vehicle.purchasePrice
        }
      }
    }).then((res) => {
      post(
        GET_INSPECTION_FILE,
        { fileId: res.fileId, inspectionId: params.id },
        false
      ).then((data) => {
        const byteCharacters = atob(data.dataBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
        navigate(`/inspections/detail/${params.id}`);
      });
    });
  }
  function handleClose() {
    setIsOpenModal(false);
    navigate(`/inspections/detail/${params.id}`);
  }
  return (
    <OnboardingLayout
      step={step}
      onChangeStep={(value) => setStep(value as CreateInvoiceStepEnum)}
      successSubmit={successSubmit}
      type="invoice"
      backToDashboard={() => onDashboardClick()}
      title="Generate Private Sale Invoice"
      onClose={() => onCloseClick()}>
      {!successSubmit && (
        <div className="max-w-[600px] w-full">
          {step === CreateInvoiceStepEnum.Buyer && (
            <BuyerDetailStep
              onPrevious={() => setStep(CreateInvoiceStepEnum.Seller)}
              onNext={() => setStep(CreateInvoiceStepEnum.Seller)}
              onClose={() => onCloseClick()}
            />
          )}
          {step === CreateInvoiceStepEnum.Seller && (
            <SellerDetailStep
              onPrevious={() => setStep(CreateInvoiceStepEnum.Buyer)}
              onNext={() => setStep(CreateInvoiceStepEnum.Vehicle)}
              onClose={() => onCloseClick()}
            />
          )}
          {step === CreateInvoiceStepEnum.Vehicle && (
            <VehicleDetailStep
              onPrevious={() => setStep(CreateInvoiceStepEnum.Seller)}
              onNext={() => submit()}
              onClose={() => onCloseClick()}
            />
          )}
        </div>
      )}
      <ConfirmInspectionModal
        open={isOpenModal}
        type={modalType}
        onSubmit={() => handleClose()}
        onClose={() => onCloseModalClick()}
      />
    </OnboardingLayout>
  );
};
export default GenerateInvoice;
