import React, { forwardRef, Ref } from 'react';
import { Select as MuiSelect, SelectProps } from '@material-tailwind/react';
import clsx from 'clsx';

type Props = SelectProps & {
  ref?: Ref<HTMLDivElement>;
};

const Select = forwardRef<HTMLDivElement, Props>(
  ({ className, children, placeholder, style, ...rest }, ref) => {
    return (
      <MuiSelect
        ref={ref}
        className={clsx('border-grey-300  !text-base shadow-input bg-white')}
        style={{ ...style }}
        containerProps={{
          className: clsx('h-11 shadow-input', className)
        }}
        labelProps={{
          className: 'before:content-none after:content-none'
        }}
        {...rest}>
        {children}
      </MuiSelect>
    );
  }
);

export default Select;
