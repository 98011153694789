import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorHandler: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleNavigation = (event: CustomEvent) => {
      const { path } = event.detail;
      navigate(path);
    };

    window.addEventListener('navigate', handleNavigation as EventListener);

    return () => {
      window.removeEventListener('navigate', handleNavigation as EventListener);
    };
  }, [navigate]);

  return null;
};

export default ErrorHandler;