import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FORGET_PASSWORD, post } from '@services';
import { useState } from 'react';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const forgetPwdFormSchema = yup.object({
    email: yup
      .string()
      .required('Please enter your email')
      .email('Please provide a valid email address')
  });
  const {
    register,
    setError,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(forgetPwdFormSchema),
    defaultValues: {
      email: ''
    }
  });
  const { email } = watch();
  const [step, setStep] = useState(0);
  const onSubmit = (data: any) => {
    post(
      FORGET_PASSWORD,
      {
        email: data.email
      },
      false
    )
      .then(() => {
        setStep(1);
      })
      .catch((e) => {
        if (e.response.data.error.code === 'InvalidPassword') {
          setError('email', {
            type: 'custom',
            message: 'Rate Limit Exceeded'
          });
        } else {
          setError('email', {
            type: 'custom',
            message: e.response.data.error.code
          });
        }
      });
  };
  if (step === 0) {
    return (
      <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-2xl text-grey-900 font-semibold mb-2 text-center">
            Reset password
          </h3>
          <p className="text-center text-grey-600 mb-6">
            To reset your password, we’ll need your email.
          </p>
          <p className="text-center text-grey-600 mb-6">
            We’ll send password reset instructions to this email address.
          </p>

          <div className="mb-6">
            <p className="text-sm text-grey-700 mb-1.5">Email</p>
            <Input
              placeholder="Enter your email"
              value={email}
              icon={errors.email && <ErrorIcon />}
              {...register(`email`)}
            />
            {errors.email && (
              <span className="text-error-light text-2sm mt-[6px]">
                {errors.email?.message}
              </span>
            )}
          </div>

          <div className="flex flex-col-reverse md:flex-row gap-4">
            <Button
              className="bg-white w-full md:w-1/2 border-grey-300 text-grey-700"
              variant="outlined"
              onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              type="submit"
              className="w-full md:w-1/2 px-4 py-2 bg-primary text-white text-base font-semibold rounded-md shadow-sm normal-case">
              Reset Password
            </Button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
        <div>
          <h3 className="text-2xl text-grey-900 font-semibold mb-2 text-center">
            Reset password
          </h3>
          <p className="text-center text-grey-600 mb-6">
            Password reset was successfully submitted. Please check your email
            inbox for instructions.
          </p>
          <div className="flex flex-col-reverse md:flex-row gap-4">
            <Button
              onClick={() => navigate('/login')}
              className="w-full px-4 py-2 bg-primary text-white text-base font-semibold rounded-md shadow-sm normal-case">
              Return to the login
            </Button>
          </div>
        </div>
      </div>
    );
  }
};
export default ForgetPassword;
