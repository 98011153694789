import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInspectionStore } from '@stores';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import Loader from '@components/widgets/Loader';
import _uniqBy from 'lodash/uniqBy';
import FilterBar from './components/FilterBar';
import InspectionTable from './components/InspectionTable';
import { INSPECTION_TABLE_ROWS } from '@constants';

const DemoInspection = () => {
  const navigate = useNavigate();
  const [lenderInspections, setLenderInspections] = useState<any[]>(
    INSPECTION_TABLE_ROWS
  );
  const { query, status, asset, lender, setLenderOptions } =
    useInspectionStore();
  const [isLoading, setLoading] = useState(false);
  const loadMore = (createdTime: string) => {};
  useEffect(() => {
    let keyword = query;
    let newData = lenderInspections;
    // setLoading(true);
    const userId = localStorage.getItem('userId');
    if (userId) {
      const lenderUser = localStorage.getItem(`lenders_${userId}` as any);
      if (lenderUser) {
        const organisationId = JSON.parse(lenderUser)[0].organisationId;
        const params = {
          lenderOrganisationId: organisationId
        };
        //For Demo
        newData = INSPECTION_TABLE_ROWS;
        const lenders = _uniqBy(
          _map(INSPECTION_TABLE_ROWS, (item) => ({
            label: item.lender.firstName + ' ' + item.lender.lastName,
            value: item.lender.userId
          })),
          'value'
        );
        lenders.unshift({ value: 'ALL', label: 'All' });
        setLenderOptions(lenders);
        const pattern = /[`~!@#$^&*()={}':;',\\]/g;
        keyword = keyword.replace(pattern, '');
        if (!_isNil(keyword) && keyword !== '') {
          const pattern = new RegExp(keyword, 'i');
          newData = INSPECTION_TABLE_ROWS.filter((item: any) => {
            return (
              pattern.test(item.friendlyId) ||
              pattern.test(item.status) ||
              pattern.test(item.lenderReference) ||
              pattern.test(item.buyer.firstName + ' ' + item.buyer.lastName) ||
              pattern.test(
                item.seller.firstName + ' ' + item.seller.lastName
              ) ||
              pattern.test(
                item.lender.firstName + ' ' + item.lender.lastName
              ) ||
              pattern.test(item.assetType)
            );
          });
        }
        if (status !== 'ALL' && newData.length > 0) {
          newData = newData.filter(
            (item) => item.status.toLowerCase() === status.toLowerCase()
          );
        }
        if (asset !== 'ALL' && newData.length > 0) {
          newData = newData.filter(
            (item) => item.assetType.toLowerCase() === asset.toLowerCase()
          );
        }
        if (lender !== 'ALL' && newData.length > 0) {
          newData = newData.filter((item) => item.lender.userId === lender);
        }
        setLenderInspections(newData);
      }
    }
  }, [query, status, asset, lender]);
  if (isLoading) return <Loader />;
  return (
    <>
      <div className="flex items-center justify-between lg:flex-row md:flex-col md:items-start md:w-full">
        <FilterBar />
      </div>
      <div className="pt-6">
        <InspectionTable data={lenderInspections} loadMoreData={loadMore} />
      </div>
    </>
  );
};

export default DemoInspection;
