import React from 'react';
const HelpCircle = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15759_8783)">
        <path
          d="M7.57435 7.50002C7.77027 6.94308 8.15698 6.47344 8.66598 6.1743C9.17498 5.87515 9.77343 5.7658 10.3553 5.86561C10.9372 5.96543 11.465 6.26796 11.8452 6.71963C12.2255 7.1713 12.4336 7.74296 12.4327 8.33335C12.4327 10 9.93268 10.8334 9.93268 10.8334M9.99935 14.1667H10.0077M18.3327 10C18.3327 14.6024 14.6017 18.3334 9.99935 18.3334C5.39698 18.3334 1.66602 14.6024 1.66602 10C1.66602 5.39765 5.39698 1.66669 9.99935 1.66669C14.6017 1.66669 18.3327 5.39765 18.3327 10Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15759_8783">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HelpCircle;
