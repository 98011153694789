import React from 'react';
const CustomCert = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      className="w-6 h-6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.95194 1.68398C10.7825 0.772005 12.2174 0.772005 13.048 1.68398L14.1161 2.85674C14.5358 3.31766 15.1391 3.56755 15.7619 3.53845L17.3464 3.4644C18.5784 3.40681 19.5932 4.42149 19.5356 5.65365L19.4615 7.23814C19.4324 7.86086 19.6823 8.46416 20.1432 8.88391L21.316 9.95194C22.228 10.7825 22.228 12.2174 21.316 13.048L20.1432 14.1161C19.6823 14.5358 19.4324 15.1391 19.4615 15.7619L19.5356 17.3464C19.5932 18.5784 18.5784 19.5932 17.3464 19.5356L15.7619 19.4615C15.1391 19.4324 14.5358 19.6823 14.1161 20.1432L13.048 21.316C12.2174 22.228 10.7825 22.228 9.95194 21.316L8.88391 20.1432C8.46416 19.6823 7.86086 19.4324 7.23814 19.4615L5.65364 19.5356C4.42149 19.5932 3.40681 18.5784 3.4644 17.3464L3.53845 15.7619C3.56755 15.1391 3.31766 14.5358 2.85674 14.1161L1.68398 13.048C0.772005 12.2174 0.772005 10.7825 1.68398 9.95194L2.85674 8.88391C3.31766 8.46416 3.56755 7.86086 3.53845 7.23814L3.4644 5.65364C3.40681 4.42149 4.42149 3.40681 5.65365 3.4644L7.23814 3.53845C7.86086 3.56755 8.46416 3.31766 8.88391 2.85674L9.95194 1.68398Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M8.35938 11.5001L10.4532 13.5939L14.6407 9.40634"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CustomCert;
