import React, { useEffect, useState } from 'react';
import Input from '@components/forms/Input';
import { Button, Option } from '@material-tailwind/react';
import { ReactComponent as SearchIcon } from '@assets/icons/search_icon.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus_icon.svg';
import Select from '@components/forms/Select';
import { useMediaQuery } from 'usehooks-ts';
import { useInspectionDetailStore, useInspectionStore } from '@stores';
import { INSPECTION_STATUS_OPTIONS } from '@constants';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

const FilterBar = () => {
  const isMobile = useMediaQuery('(max-width: 720px)');
  const navigate = useNavigate();
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const {
    query,
    status,
    asset,
    lender,
    lenderOptions,
    setQuery,
    setStatus,
    setAsset,
    setLender
  } = useInspectionStore();
  const { reset } = useInspectionDetailStore();
  useEffect(() => {
    if (!status || status === '') {
      setStatus('ALL');
    }
    if (!asset || asset === '') {
      setAsset('ALL');
    }
    if (!lender || lender === '') {
      setLender('ALL');
    }
    setQuery(query || '');
  }, [query, status, asset, lender]);
  useEffect(() => {}, []);
  const handleQueryChange = (e: any) => {
    const value = e.target.value;
    setQuery(value);
    refineList();
  };
  const handleStatusChange = (e: any) => {
    setStatus(e);
    refineList();
  };
  const handleLenderChange = (e: any) => {
    setLender(e);
    refineList();
  };
  const resetForm = () => {
    setQuery('');
    setStatus('ALL');
    setAsset('ALL');
    setLender('ALL');
  };
  const refineList = () => {};
  return (
    <div
      className={clsx(
        'flex gap-x-3 gap-y-4 flex-col lg:flex-row md:flex-wrap md:flex-col w-full h-full',
        isMobile && 'mobile-filter-background'
      )}>
      <div className="flex items-start lg:items-start flex-col lg:flex-row gap-x-3 gap-y-4 w-full">
        <div className="flex-col w-full lg:w-1/3 xl:w-1/4 md:w-1/3">
          <p className="text-grey-700 text-sm mb-1.5 font-medium">Search</p>
          <div className="relative flex flex-row gap-2 justify-center items-center">
            <SearchIcon className="absolute top-1/2 lg:top-1/2 left-3.5 -translate-y-1/2 text-grey-500 z-10 md:top-2/3 " />
            <Input
              inputClassName="!h-10 w-full lg:flex justify-center items-center text-sm leading-6 text-grey-400 rounded-md shadow-sm py-1.5 pl-10 pr-3 hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
              placeholder="Search"
              value={query}
              onChange={handleQueryChange}
            />
            {isMobile && (
              <Button
                size="sm"
                className={clsx(
                  'bg-white capitalize text-sm px-3 mt-[-4px] flex !font-semibold items-center gap-2 border border-grey-300 text-grey-600 shadow-none',
                  isShowFilter && 'bg-primary text-white'
                )}
                onClick={() => setIsShowFilter(!isShowFilter)}>
                Filters
              </Button>
            )}
          </div>
        </div>

        <div className="flex items-start lg:items-start flex-col lg:flex-row gap-x-3 gap-y-4 flex-1 w-full xl:w-auto">
          {(isShowFilter || !isMobile) && (
            <>
              <div className="flex-1 w-full lg:w-auto">
                <p className="text-grey-700 text-sm mb-1.5 font-medium">
                  Created By
                </p>
                <Select
                  value={lender}
                  onChange={handleLenderChange}
                  className="w-full !h-10 lg:flex items-center text-sm leading-6 text-grey-400 rounded-md shadow-sm hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700">
                  {lenderOptions.map((item: any, index: number) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex-1 w-full lg:w-auto">
                <p className="text-grey-700 text-sm mb-1.5 font-medium">
                  Status
                </p>
                <Select
                  className="w-full !h-10 lg:flex items-center text-sm leading-6 text-grey-400 rounded-md shadow-sm hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
                  value={status}
                  onChange={handleStatusChange}
                  selected={(element) =>
                    element &&
                    React.cloneElement(element, {
                      className:
                        'flex items-center px-0 gap-2 pointer-events-none'
                    })
                  }>
                  {INSPECTION_STATUS_OPTIONS.map((item, index) => (
                    <Option
                      key={index}
                      className="flex items-center gap-2"
                      value={item.value}>
                      <span className="w-2.5 h-2.5 rounded-full bg-green-600" />
                      <div>{item.label}</div>
                    </Option>
                  ))}
                </Select>
              </div>
            </>
          )}
        </div>
        <div className="flex w-full h-full justify-start md:justify-between items-end">
          <Button
            variant="text"
            className="text-grey-600 capitalize text-sm font-semibold px-4"
            onClick={() => resetForm()}>
            Clear filters
          </Button>

          <Button
            className="capitalize bg-primary flex items-center gap-2 w-auto text-sm font-semibold py-2.5 text-white mb-1"
            onClick={() => {
              reset();
              navigate('/inspections/create');
            }}>
            <PlusIcon />
            New inspection
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
