import { ACTIVITY_HISTORY_STEPS } from '@constants';
import { ReactComponent as CheckActivityPrimaryIcon } from '@assets/icons/check_activity_success_icon.svg';
import { ReactComponent as TargetIcon } from '@assets/icons/target_icon.svg';
import clsx from 'clsx';

const ActivityHistory = () => {
  return (
    <div className="">
      <h3 className="text-lg font-semibold text-grey-900 pb-5 border-b border-b-grey-200 p-3">
        Activity History
      </h3>
      <div className="p-6">
        {ACTIVITY_HISTORY_STEPS.map((step, index) => (
          <div className="flex gap-5" key={index}>
            <div className="relative">
              {step.status ? (
                <CheckActivityPrimaryIcon className="w-6 h-6" />
              ) : (
                <TargetIcon className="w-6 h-6 bg-white rounded-full" />
              )}
              <span className="absolute w-0.5 rounded-lg bg-grey-200 top-7 left-3 bottom-1.5" />
            </div>
            <div
              className={clsx(
                'pb-8',
                index === ACTIVITY_HISTORY_STEPS.length - 1 && 'pb-0'
              )}>
              <p className="text-grey-700 text-sm font-medium">{step.label}</p>
              <p className="text-grey-700 text-xs leading-5">{step.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityHistory;
