import React from 'react';
const OdometerIcon = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.80777 6.15127C1.80777 5.27846 1.80558 4.40492 1.80777 3.53211C1.81068 2.58784 2.41808 1.97023 3.35579 1.96804C5.1386 1.9644 6.92141 1.96513 8.70423 1.96804C9.21245 1.96877 9.51943 2.37929 9.32766 2.79054C9.2037 3.05596 8.97475 3.13617 8.69912 3.13471C8.06256 3.13252 7.426 3.13471 6.78943 3.13471C5.68912 3.13471 4.58808 3.13471 3.48777 3.13471C3.0262 3.13471 2.97662 3.18502 2.97662 3.65679C2.97662 5.33023 2.97662 7.00367 2.97662 8.67784C2.97662 8.7595 2.97881 8.8419 2.97443 8.92357C2.95256 9.29836 2.71339 9.54846 2.38454 9.54263C2.05495 9.53679 1.81214 9.28086 1.80995 8.90752C1.80412 7.98877 1.80777 7.07002 1.80777 6.15127Z"
        fill={color}
      />
      <path
        d="M5.95717 26.4309C5.11134 26.4309 4.2655 26.4331 3.41967 26.4309C2.39592 26.428 1.80821 25.8425 1.80748 24.826C1.80675 23.0709 1.80602 21.3158 1.80748 19.56C1.80748 19.1254 2.0379 18.8644 2.40394 18.8702C2.6854 18.8746 2.90852 19.0583 2.95592 19.3391C2.97561 19.455 2.97488 19.5746 2.97488 19.692C2.97634 21.3836 2.97488 23.0753 2.97561 24.767C2.97561 25.214 3.03321 25.2708 3.478 25.2708C5.22436 25.2708 6.96998 25.2708 8.71634 25.2716C9.0904 25.2716 9.33613 25.467 9.37769 25.7849C9.42363 26.1385 9.14873 26.4258 8.73894 26.428C7.81144 26.4339 6.88394 26.4295 5.95571 26.4302L5.95717 26.4309Z"
        fill={color}
      />
      <path
        d="M26.2689 6.13875C26.2689 7.06625 26.2733 7.99448 26.2667 8.92198C26.2638 9.29167 26.0101 9.54834 25.6834 9.54323C25.3546 9.53813 25.119 9.27854 25.1081 8.90813C25.1059 8.84469 25.1081 8.78052 25.1081 8.71708C25.1081 7.58906 25.1081 6.46177 25.1081 5.33375C25.1081 4.77011 25.1081 4.20573 25.1081 3.64208C25.1081 3.19073 25.0541 3.13531 24.6115 3.13531C22.9293 3.13531 21.2464 3.13531 19.5642 3.13531C19.4556 3.13531 19.3455 3.13677 19.2376 3.12438C18.9218 3.09011 18.7184 2.87136 18.7111 2.56875C18.7038 2.25886 18.916 2.01386 19.2325 1.97448C19.3134 1.96427 19.3958 1.96792 19.4775 1.96792C21.2056 1.96792 22.9337 1.96719 24.6618 1.96792C25.6827 1.96792 26.2682 2.55344 26.2704 3.575C26.2726 4.42958 26.2704 5.2849 26.2704 6.13948H26.2697L26.2689 6.13875Z"
        fill={color}
      />
      <path
        d="M22.1075 26.4309C21.1887 26.4309 20.2707 26.4338 19.3519 26.4294C18.9808 26.428 18.7496 26.2369 18.7154 25.9278C18.6738 25.5493 18.9276 25.2759 19.3366 25.273C20.0731 25.2686 20.8095 25.2715 21.5467 25.2715C22.5741 25.2715 23.6022 25.2715 24.6296 25.2715C25.0372 25.2715 25.1087 25.2015 25.1087 24.8012C25.1087 23.0461 25.108 21.291 25.1102 19.5359C25.1102 19.1203 25.3413 18.8665 25.7015 18.8709C25.9946 18.8746 26.2258 19.0722 26.2593 19.3638C26.2768 19.5169 26.2717 19.6723 26.2717 19.8268C26.2717 21.4908 26.2732 23.1555 26.2717 24.8194C26.271 25.8519 25.6986 26.428 24.6727 26.4309C23.8181 26.4331 22.9628 26.4309 22.1082 26.4309H22.1075Z"
        fill={color}
      />
      <g clipPath="url(#clip0_9270_61146)">
        <path
          d="M5.75 17.5088C5.79083 17.2113 5.81917 16.9113 5.87417 16.6167C6.29042 14.3851 7.44333 12.6288 9.32083 11.3597C10.6996 10.4276 12.2371 9.96174 13.8971 10.033C17.1533 10.1734 19.515 11.7372 20.9721 14.6488C21.4621 15.628 21.6787 16.6838 21.7162 17.7767C21.7183 17.8388 21.7167 17.9013 21.7158 17.9638C21.7158 17.973 21.7092 17.9822 21.7025 17.9997H20.7717C20.7154 15.7867 19.8654 13.9517 18.155 12.548C16.855 11.4813 15.3446 10.9617 13.6604 10.9842C11.7413 11.0101 10.0925 11.7063 8.7425 13.0663C7.39583 14.4226 6.73542 16.0792 6.70125 18.0076H5.75C5.75 17.8413 5.75 17.6751 5.75 17.5088Z"
          fill={color}
        />
        <path
          d="M20.4015 18.0013H15.0177C14.9402 17.1001 14.269 16.7401 13.739 16.7372C13.0698 16.7334 12.5977 17.1955 12.4523 18.0001H7.05273C7.11648 15.7955 7.96357 13.9672 9.74815 12.6722C12.0023 11.0359 14.4386 10.8618 16.9102 12.1484C19.2869 13.3855 20.4298 15.8247 20.4019 18.0013H20.4015ZM12.1127 17.2272C12.3027 16.8443 12.5819 16.5784 12.9482 16.3809C11.9744 15.6818 11.024 15.0001 10.074 14.318L10.0452 14.3463C10.729 15.2988 11.4127 16.2513 12.1127 17.2272Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9270_61146">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(5.75 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default OdometerIcon;
