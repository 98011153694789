import React from 'react';
const MobileIcon = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99935 14.5832H10.0077M6.83268 18.3332H13.166C14.0994 18.3332 14.5661 18.3332 14.9227 18.1515C15.2363 17.9917 15.4912 17.7368 15.651 17.4232C15.8327 17.0666 15.8327 16.5999 15.8327 15.6665V4.33317C15.8327 3.39975 15.8327 2.93304 15.651 2.57652C15.4912 2.26292 15.2363 2.00795 14.9227 1.84816C14.5661 1.6665 14.0994 1.6665 13.166 1.6665H6.83268C5.89926 1.6665 5.43255 1.6665 5.07603 1.84816C4.76243 2.00795 4.50746 2.26292 4.34767 2.57652C4.16602 2.93304 4.16602 3.39975 4.16602 4.33317V15.6665C4.16602 16.5999 4.16602 17.0666 4.34767 17.4232C4.50746 17.7368 4.76243 17.9917 5.07603 18.1515C5.43255 18.3332 5.89926 18.3332 6.83268 18.3332ZM10.416 14.5832C10.416 14.8133 10.2295 14.9998 9.99935 14.9998C9.76923 14.9998 9.58268 14.8133 9.58268 14.5832C9.58268 14.3531 9.76923 14.1665 9.99935 14.1665C10.2295 14.1665 10.416 14.3531 10.416 14.5832Z"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default MobileIcon;
