import React from 'react';

interface InnerSidebarProps {
  activeItem: string;
  onItemSelect: (item: string) => void;
}

const InnerSidebar: React.FC<InnerSidebarProps> = ({
  activeItem,
  onItemSelect
}) => {
  const items = [
    { name: 'My details', key: 'mydetails' },
    { name: 'Initials avatar', key: 'initialsavatar' },
    { name: 'Change password', key: 'changepassword' },
  ];

  return (
    <div className="w-full md:h-full md:w-[266px] md:border-r md:border-gray-300">
      <ul className="flex md:h-full md:flex-col md:space-y-4 space-x-4 overflow-x-auto scrollbar-hide md:overflow-x-hidden p-6 bg-gray-100">
        {items.map((item) => (
          <li key={item.key} className="flex-none h-[44px] !ml-0">
            <a
              href="#"
              className={`flex items-center justify-start  p-3 h-full m-auto rounded-lg ${
                activeItem === item.key
                  ? 'border border-red-700 bg-white text-black font-semibold'
                  : 'text-gray-700'
              }`}
              onClick={() => onItemSelect(item.key)}>
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InnerSidebar;
