import { useEffect, useState } from 'react';
import Button from '@components/forms/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus_icon.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete_icon.svg';
import { ReactComponent as TemplateIcon } from '@assets/icons/template-icon.svg';

import Table, { TableHeader } from '@components/widgets/Table';
import { AssetType } from '@types';
import {
  post,
  LIST_INSPECTION_TEMPLATES,
  DELETE_INSPECTION_TEMPLATE
} from '@services';
import CreateDocumentRequirement from './CreateDocumentRequirement';
import { useNavigate } from 'react-router-dom';
import Loader from '@components/widgets/Loader';
import Modal from '@components/widgets/Modal';
import { useTemplateStore } from '@stores';
const LenderDocument = () => {
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [param, setParam] = useState<AssetType>();
  const navigate = useNavigate();
  const { setTemplateObject } = useTemplateStore();
  const handleRowClick = (row: any) => {
    setParam(row);
    setTemplateObject(row);
    navigate('/template/create');
    // setIsOpenCreateModal(true);
  };
  const lenderName =
    localStorage.getItem('lastName') +
      ' ' +
      localStorage.getItem('firstName') || '';
  const TABLE_HEAD: TableHeader[] = [
    {
      label: 'Inspection Template Name',
      field: 'name',
      contentClassName: 'text-grey-900 font-medium'
    },
    {
      label: 'Total Documents',
      field: 'totalDocuments',
      render: (row) => (
        <span className="text-grey-900 font-medium">
          {row.documentTypes.length}
        </span>
      )
    },
    {
      label: '',
      field: 'action',
      render: (row) => (
        <div
          className="flex flex-row gap-2 whitespace-nowrap font-semibold text-md pointer-events-auto z-75"
          onClick={(e) => deleteTemplate(e, row)}>
          <DeleteIcon />
          Delete
        </div>
      )
    }
  ];
  const deleteTemplate = (e: any, row: any) => {
    e.stopPropagation();
    setIsOpenDeleteModal(true);
    setRow(row);
  };
  const [random, setRandom] = useState(0);
  const [isDeleteOpen, setIsOpenDeleteModal] = useState(false);
  const [row, setRow] = useState<any>();
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const lender = localStorage.getItem(`lenders_${userId}` as any);
      if (lender) {
        const lenderOrganisationId = JSON.parse(lender)[0].organisationId;
        setLoading(true);
        post(LIST_INSPECTION_TEMPLATES, { lenderOrganisationId }).then(
          (res) => {
            if (res) {
              setDocuments(res.templates);
              setLoading(false);
            }
          }
        );
      } else {
        setLoading(false);
        navigate('/');
      }
    }
  }, [random]);
  if (isLoading) return <Loader />;
  return (
    <>
      <div className="flex flex-col md:flex-row  items-left md:items-center justify-start md:justify-between pb-8 pt-2 px-2 gap-x-[120px] gap-y-6">
        <div className="text-grey-600">
          <div className="bg-grey-50 border border-grey-200 p-3">
            <h2 className="font-semibold text-lg text-left mb-6">
              Create custom inspection templates to define the required
              documents and verification steps for your business.
            </h2>
            <p className="">
              All users and brokers within your instance of Authentik8 can
              select from these inspection templates when creating a new
              inspection. Their selection of an inspection template will
              automatically set the necessary inspection requirements.
            </p>
          </div>
        </div>
        <Button
          className="capitalize bg-primary flex items-center text-[14px] h-10 gap-2 min-w-0 md:min-w-[274px] text-sm py-2.5 px-4 whitespace-nowrap"
          onClick={() => {
            setTemplateObject(undefined);
            navigate('/template/create');
          }}>
          <PlusIcon />
          Create inspection template
        </Button>
      </div>
      <div className="px-2">
        {documents.length > 0 && (
          <Table
            headers={TABLE_HEAD}
            data={documents}
            onClickRow={(row) => handleRowClick(row)}
          />
        )}
        {documents.length === 0 && (
          <div className="flex justify-center items-center bg-grey-50 border border-grey-200 rounded-xl min-h-[584px] px-4 no-result-found">
            <div className="flex flex-col max-w-[651px] gap-8 justify-center items-center z-100">
              <TemplateIcon />
              <h2 className="font-semibold text-xl text-center">
                No inspection templates found
              </h2>
              <Button
                className="capitalize bg-primary flex items-center font-semibold text-[14px] gap-2 text-sm py-2 px-3.5 h-[36px]"
                onClick={() => {
                  setParam(undefined);
                  navigate('/template/create');
                }}>
                <PlusIcon />
                Create inspection template
              </Button>
            </div>
          </div>
        )}
      </div>
      <CreateDocumentRequirement
        open={isOpenCreateModal}
        param={param}
        onClose={() => {
          setParam(undefined);
          setIsOpenCreateModal(false);
          setRandom(Math.random() * 1000);
        }}
      />
      <ConfirmDeleteModal
        open={isDeleteOpen}
        row={row}
        onClose={() => setIsOpenDeleteModal(false)}
        onConfirm={() => setRandom(Math.random() * 1000)}
      />
    </>
  );
};
const ConfirmDeleteModal = ({
  open,
  row,
  onClose,
  onConfirm
}: {
  open: boolean;
  row: any;
  onClose: any;
  onConfirm: any;
}) => {
  const deleteTemplate = () => {
    post(DELETE_INSPECTION_TEMPLATE, { templateId: row.templateId }).then(
      (res) => {
        if (res) {
          onConfirm();
          onClose();
        }
      }
    );
  };
  return (
    <Modal
      title="Delete Inspection Template"
      size="md"
      open={open}
      onClose={onClose}>
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col gap-[10px]">
          <p>Are you sure you want to delete the inspection template?</p>
        </div>
        <div className="grid grid-cols-2 w-full gap-4 mt-8">
          <Button
            variant="outlined"
            data-dialog-close="true"
            onClick={onClose}
            className="border bg-white border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
            Cancel
          </Button>
          <Button
            data-dialog-close="true"
            className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
            onClick={deleteTemplate}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default LenderDocument;
