import React from 'react';

const NotFound = () => {
  return (
    <div className="w-full flex items-center justify-center">
      <h1 className="text-3xl font-bold text-secondary">😉 Not Found</h1>
    </div>
  );
};

export default NotFound;
