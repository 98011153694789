import React, { useEffect, useState } from 'react';
import Button from '@components/forms/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus_icon.svg';
import CreateVerificationModal from './CreateVerificationModal';
import Input from '@components/forms/Input';
import { ReactComponent as SearchIcon } from '@assets/icons/search_icon.svg';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';
import LandingTable from './components/LandingTable';
import { INSPECTION_STATUS_OPTIONS } from '@constants';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from '@types';
import { post, LIST_AV } from '@services';
import { format, parseISO } from 'date-fns';

const Verification = () => {
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('ALL');
  const handleQueryChange = (e: any) => {
    const value = e.target.value;
    setQuery(value);
  };
  const handleStatusChange = (e: any) => {
    setStatus(e);
  };
  const reset = () => {
    setQuery('');
    setStatus('ALL');
  };
  const navigator = useNavigate();
  const [roles, setRoles] = useState<string[]>([]);
  const [verifications, setVerifications] = useState<any[]>([]);
  const [filteredVerifications, setFilteredVerifications] =
    useState(verifications);
  const [lenderOrganisationId, setLenderOrganisationId] = useState('');

  const onLoadMore = (lastCreatedTime: string) => {
    fetchVerifications(lastCreatedTime);
  };
  const userId = localStorage.getItem('userId');
  const lender = localStorage.getItem(`lenders_${userId}` as any);
  const fetchVerifications = async (olderThan?: any) => {
    if (lender) {
      const lenderOrganisationId = JSON.parse(lender)[0].organisationId;

      const response: any = await post(LIST_AV, {
        olderThan,
        lenderOrganisationId: lenderOrganisationId
      });
      const data = response.assetVerifications || [];
      if (data.length < 50) {
        data[data.length - 1].isLast = true;
      }

      if (olderThan) {
        setVerifications([...verifications, ...data]);
        setFilteredVerifications([...filteredVerifications, ...data]);
      } else {
        setVerifications(data);
        setFilteredVerifications(data);
      }
    }
  };
  useEffect(() => {
    if (userId) {
      if (lender) {
        fetchVerifications();
      }
      const manageRoles = localStorage.getItem(`lenders_${userId}` as any);
      if (manageRoles) {
        if (JSON.parse(manageRoles) && JSON.parse(manageRoles).length > 0) {
          setRoles(JSON.parse(manageRoles)[0].roles);
        }
      }
    } else {
      navigator('/login');
    }
  }, []);

  useEffect(() => {
    const filteredVerifications = verifications
      .filter((verification: any) => {
        if (status === 'ALL') {
          return verification;
        }
        return verification.status === status;
      })
      .filter((verification: any) => {
        if (query === '') {
          return verification;
        }
        return (
          verification.vin?.toLowerCase().includes(query.toLowerCase()) ||
          verification.rego?.toLowerCase().includes(query.toLowerCase()) ||
          verification.state?.toLowerCase().includes(query.toLowerCase()) ||
          verification?.vehicle?.toLowerCase().includes(query.toLowerCase()) ||
          verification.status?.toLowerCase().includes(query.toLowerCase()) ||
          format(
            parseISO(verification.createdTime),
            'dd/MM/yyyy h:mma'
          ).includes(query.toLowerCase())
        );
      });
    setFilteredVerifications(filteredVerifications);
  }, [query, status, isOpenCreateModal]);
  return (
    <div className="py-6 px-2 min-h-[640px]">
      <div className="flex md:hidden justify-between">
        <h3>Asset Verification</h3>
          <Button
            className="capitalize bg-primary flex items-center text-[14px] h-10 gap-2 text-sm py-2.5 px-4"
            onClick={() => setIsOpenCreateModal(true)}>
            <PlusIcon />
            New verification
          </Button>
      </div>
      <div className="flex md:w-full gap-3 p-5 md:p-0 border border-grey-200 bg-grey-50 md:border-none md:bg-opacity-0">
        <div className="flex flex-col md:flex-row flex-1 w-full lg:w-auto h-full justify-start min-w-max gap-3">
          <div className="flex-col">
            <p className="text-grey-700 text-sm mb-1.5 font-medium">Search</p>
            <div className="relative">
              <SearchIcon className="absolute top-1/2 lg:top-1/2 left-3.5 -translate-y-1/2 text-grey-500 z-10 md:top-2/3 " />
              <Input
                inputClassName="!h-10 w-full lg:flex items-center text-sm leading-6 text-grey-400 rounded-md ring-1 ring-grey-900/10 shadow-sm py-1.5 pl-10 pr-3 hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
                placeholder="Search"
                value={query}
                onChange={handleQueryChange}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <div className="flex flex-col">
              <p className="text-grey-700 text-sm mb-1.5 font-medium">Status</p>
              <div className="relative">
                <Select
                  className="!h-10 lg:flex items-center text-sm leading-6 text-grey-400 rounded-md ring-1 ring-grey-900/10 shadow-sm hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
                  value={status}
                  onChange={handleStatusChange}
                  selected={(element) =>
                    element &&
                    React.cloneElement(element, {
                      className:
                        'flex items-center px-0 gap-2 pointer-events-none'
                    })
                  }>
                  {INSPECTION_STATUS_OPTIONS.map((item, index) => (
                    <Option
                      key={index}
                      className="flex items-center gap-2"
                      value={item.value}>
                      <div>{item.label}</div>
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <Button
              variant="text"
              className="place-self-end bg-white align-middle select-none font-sans text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-3 rounded-lg hover:bg-blue-500/10 active:bg-blue-500/30 text-grey-600 capitalize text-sm font-semibold px-4"
              onClick={() => reset()}>
              Clear filters
            </Button>
          </div>
        </div>
        <div className="hidden md:flex items-end">
          <Button
            className="capitalize bg-primary flex items-center text-[14px] h-10 gap-2 text-sm py-2.5 px-4"
            onClick={() => setIsOpenCreateModal(true)}>
            <PlusIcon />
            New verification
          </Button>
        </div>
      </div>
      <div className="pt-6">
        <LandingTable
          landingData={filteredVerifications}
          onLoadMore={onLoadMore}
        />
      </div>
      <CreateVerificationModal
        open={isOpenCreateModal}
        onClose={() => {
          if (lender) {
            fetchVerifications();
          }
          setIsOpenCreateModal(false);
        }}
      />
    </div>
  );
};

export default Verification;
