import React, { useEffect } from 'react';
import Modal from '@components/widgets/Modal';
import Button from '@components/forms/Button';
import { ReactComponent as PhoneLogo } from '@assets/images/phone.svg';
import { ReactComponent as MailLogo } from '@assets/images/mail.svg';
import { useOrgStore, useSellerStore } from '@stores';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ContactSupportSellerModal: React.FC<Props> = ({ open, onClose }) => {
  const { organisation } = useOrgStore();
  const { friendlyId } = useSellerStore();
  const [brand, setBrand] = React.useState('');
  const emailSubject = encodeURIComponent(`${friendlyId} Inspection Support`);
  const emailHref = `mailto:support@authentik8.com.au?subject=${emailSubject}`;
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setBrand(organisation.name);
    }
  }, []);
  return (
    <Modal size="md" open={open} onClose={onClose} hideClose>
      <div className="px-2 pt-6">
        <div className="mb-4">
          <p className="text-[18px] font-medium text-grey-900 mb-10">
            Get in touch with {brand} for any enquiries relating to your
            inspections
          </p>
        </div>
        <Button
          variant="outlined"
          data-dialog-close="true"
          className="w-full py-4 h-14 bg-white border border-solid border-gray-400 text-[18px] text-dark-primary"
          onClick={() => (window.location.href = emailHref)}>
          <MailLogo className="mr-1" /> Email us
        </Button>
      </div>
    </Modal>
  );
};

export default ContactSupportSellerModal;
