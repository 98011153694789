import React, { useEffect, useState } from 'react';
import { Button, Input } from '@components/forms';
import { ReactComponent as UploadIcon } from '@assets/icons/upload.svg';
import {
  GET_LENDER_ORGANISATION,
  post,
  UPDATE_LENDER_ORGANISATION
} from '@services';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from '@types';

const schema = yup
  .object({
    primaryColour: yup
      .string()
      .matches(/^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/, 'Invalid HEX color')
      .required('Primary color is required'),
    notificationEmail: yup
      .string()
      .required('Please enter notification email.')
      .email('Please provide a valid email address'),
    logoBase64: yup
      .mixed()
      .required('Logo is required')
      .test('fileType', 'Please select a SVG file', (value: any) => {
        return (value && value.file?.type === 'image/svg+xml') || value.base64;
      })
  })
  .required();
const AccountSetting = () => {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      primaryColour: '',
      notificationEmail: '',
      logoBase64: {}
    }
  });
  const [previewImage, setPreviewImage] = useState<string>('');
  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles && acceptedFiles[0]) {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            setValue(
              'logoBase64',
              {
                file,
                base64: reader.result
              },
              { shouldValidate: true }
            );
            setPreviewImage(reader.result as string); // Update the type of previewImage to allow null and string values
          }
        };
        reader.readAsDataURL(file);
      }
    },
    [setValue]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/svg+xml': ['.svg'] },
    multiple: false
  });
  const { primaryColour, notificationEmail, logoBase64 } = watch();
  const userId = localStorage.getItem('userId');
  const lenderUser = localStorage.getItem(`lenders_${userId}` as any);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const onSubmit = (data: any) => {
    const organisation = lenderUser && JSON.parse(lenderUser)[0].organisationId;
    if (!organisation) {
      return;
    }
    const param = {
      logoBase64: data.logoBase64.base64.split('base64,')[1],
      organisationId: organisation,
      notificationEmail: data.notificationEmail,
      primaryColour: data.primaryColour.split('#')[1]
    };
    post(UPDATE_LENDER_ORGANISATION, param, false)
      .then(() => {
        setShowSuccessMsg(true);
        const timer = setTimeout(() => {
          setShowSuccessMsg(false);
        }, 5000);
        return () => clearTimeout(timer);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const [orgName, setOrgName] = useState('');
  const navigator = useNavigate();
  const [roles, setRoles] = useState<string[]>([]);
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const manageRoles = localStorage.getItem(`lenders_${userId}` as any);
      if (manageRoles) {
        if (JSON.parse(manageRoles) && JSON.parse(manageRoles).length > 0) {
          setRoles(JSON.parse(manageRoles)[0].roles);
        }
      }
    } else {
      navigator('/login');
    }
    if (lenderUser) {
      const organisation =
        lenderUser && JSON.parse(lenderUser)[0].organisationId;
      post(GET_LENDER_ORGANISATION, { organisationId: organisation }, false)
        .then((data) => {
          if (data) {
            setOrgName(data.name);
            setValue('primaryColour', `#${data.primaryColour}`);
            setValue('notificationEmail', data.notificationEmail);
            setValue('logoBase64', {
              file: null,
              base64: `data:image/svg+xml;base64,${data.logoBase64}`
            });
            setPreviewImage(`data:image/svg+xml;base64,${data.logoBase64}`);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [lenderUser, setValue]);
  if (roles.includes(RoleEnum.STANDARD)) {
    return (
      <div style={{ maxWidth: '520px' }} className="p-2">
        <h1 className="font-semibold text-xl text-gray-900 mb-6">
          You don&apos;t have permission to access this page.
        </h1>
      </div>
    );
  }
  return (
    <div style={{ maxWidth: '520px' }} className="p-2">
      <h1 className="font-semibold text-xl text-gray-900 mb-6">Settings</h1>

      <div className="mb-12" style={{ maxWidth: '520px' }}>
        <label
          className="block font-medium text-gray-700 text-sm mb-2"
          htmlFor="initials">
          Organisation Name
        </label>
        <Input
          type="text"
          placeholder="Organization name"
          value={orgName}
          disabled
        />
      </div>
      <h1 className="font-semibold text-xl text-gray-900 mb-6">
        Notifications
      </h1>
      <p className="text-[16px] text-gray-700 mb-6">
        Receive notification emails when inspections are submitted
      </p>
      <div className="mb-12" style={{ maxWidth: '520px' }}>
        <label
          className="block font-medium text-gray-700 text-sm mb-2"
          htmlFor="initials">
          Notification Email Address
        </label>
        <Input
          type="text"
          placeholder="Notification Email"
          {...register(`notificationEmail`)}
          icon={errors.notificationEmail && <ErrorIcon />}
        />
        {errors.notificationEmail && (
          <p>
            <span className="text-xs text-error-light">
              {errors.notificationEmail.message}
            </span>
          </p>
        )}
      </div>
      <h1 className="font-semibold text-xl text-gray-900 mb-6">
        Seller inspection interface
      </h1>
      <p className="text-[16px] text-gray-700 mb-6">
        Customise the Seller’s inspection experience with your logo and brand
        colours
      </p>
      <div className="mb-6">
        <label className="block font-medium text-gray-700 text-sm mb-2">
          Icon and Button Colour (HEX)
        </label>
        <div className="flex mb-2">
          <Input
            type="text"
            {...register(`primaryColour`)}
            icon={errors.primaryColour && <ErrorIcon />}
            placeholder="Icon and Button Colour"
          />
          <button
            style={{
              backgroundColor: primaryColour
            }}
            className="w-10 h-10 ml-2 rounded-[10px] flex items-center justify-center"></button>
        </div>
        {errors.primaryColour && (
          <p>
            <span className="text-xs text-error-light">
              {errors.primaryColour.message}
            </span>
          </p>
        )}{' '}
        <p className="text-sm text-gray-500">
          For best result, use a dark colour.
        </p>
      </div>
      <div className="mb-12">
        <label className="block font-medium text-gray-700 text-sm mb-2">
          Logo
        </label>
        <div
          className="border border-gray-300 rounded-[10px] p-6 text-center cursor-pointer text-gray-500 upload-text-highlight"
          {...getRootProps()}
          onClick={() => document.getElementById('logoBase64')?.click()}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <UploadIcon />
          </div>
          \
          <p className="font-bold text-[14px] text-gray-600">
            Drag and drop SVG File or Browse File to upload
          </p>
          <p className="text-sm">open and select your files manually.</p>
          <p className="md:hidden">Click to select files.</p>
          <input {...getInputProps()} id="logoBase64" hidden />
        </div>
        {errors.logoBase64 && (
          <span className="text-xs text-error-light">
            {errors.logoBase64.message}
          </span>
        )}{' '}
      </div>
      {previewImage && (
        <div className="mt-4 flex flex-col">
          <p className="font-bold">Preview:</p>
          <img
            src={previewImage}
            alt="Uploaded SVG"
            className="max-w-full h-auto mt-2"
          />
        </div>
      )}
      {showSuccessMsg && (
        <span className="text-success-700 font-medium text-sm text-right mb-3">
          Settings saved!
        </span>
      )}
      <Button
        type="submit"
        className="bg-primary"
        onClick={() => {
          if (!isValid) {
            trigger();
          }
          handleSubmit(onSubmit)();
        }}>
        Save
      </Button>
    </div>
  );
};

export default AccountSetting;
