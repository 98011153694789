import { useState } from 'react';
import Button from '@components/forms/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus_icon.svg';
import Table, { TableHeader } from '@components/widgets/Table';
import StatusChip from '@components/widgets/StatusChip';
import { LENDER_STATUS_VARIANT, LENDER_TABLE_ROWS } from '@constants';
import { LenderStatusEnum } from '@types';
import CreateLenderModal from '@pages/lender/CreateLenderModal';
import Loader from '@components/widgets/Loader';

export const TABLE_HEAD: TableHeader[] = [
  {
    label: 'Lender',
    field: 'lender',
    sort: true,
    contentClassName: 'text-grey-900 font-medium'
  },
  {
    label: 'Status',
    field: 'status',
    render: (row) => (
      <StatusChip
        variant={LENDER_STATUS_VARIANT[row.status as LenderStatusEnum]}
        label={row.status}
      />
    )
  },
  {
    label: 'Lender Ref No',
    field: 'renderRef'
  }
];

const Lender = () => {
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleRowClick = (row: any) => {
    setIsOpenCreateModal(false);
  };
  if (isLoading) return <Loader />;
  return (
    <>
      <div className="flex items-center justify-between pb-10">
        <h1 className="font-semibold text-3xl text-gray-900">Lenders</h1>

        <Button
          className="capitalize bg-primary flex items-center text-[14px] h-10 gap-2 text-sm py-2.5 px-4"
          onClick={() => setIsOpenCreateModal(true)}>
          <PlusIcon />
          Add lender
        </Button>
      </div>
      <div className="w-full">
        <Table
          headers={TABLE_HEAD}
          data={LENDER_TABLE_ROWS}
          onClickRow={(row) => handleRowClick(row)}
        />
      </div>

      <CreateLenderModal
        open={isOpenCreateModal}
        onClose={() => setIsOpenCreateModal(false)}
      />
    </>
  );
};

export default Lender;
