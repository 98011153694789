import { faker } from '@faker-js/faker';
export const MockData = {};
export const SellerVerification =
  process.env.NODE_ENV === 'development'
    ? {
        driverLicense: {
          isDone: faker?.datatype.boolean()
        },
        selfie: {
          isDone: faker?.datatype.boolean()
        },
        cert: {
          image: '',
          isDone: faker?.datatype.boolean()
        },
        bank: {
          image: '',
          accountName: faker?.finance.accountName(),
          accountNumber: faker?.finance.accountNumber(),
          bsb: faker?.finance.pin(6),
          isDone: faker?.datatype.boolean()
        },
        registration: {
          registrationNumberPlate: faker?.vehicle.vrm(),
          registrationState: faker?.location.state(),
          registrationVin: faker?.vehicle.vin(),
          image: '',
          isDone: faker?.datatype.boolean()
        },
        vin: {
          image: '',
          vin: faker?.vehicle.vin(),
          isDone: faker?.datatype.boolean()
        },
        numberPlate: {
          image: '',
          isDone: faker?.datatype.boolean(),
          plateNumber: faker?.vehicle.vrm()
        },
        odometer: {
          image: '',
          odometer: '',
          isDone: faker?.datatype.boolean()
        },
        vehicle: {
          imageFront: '',
          imageDriver: '',
          imageBack: '',
          imagePassenger: '',
          vehiclePosition: 1,
          isDone: faker?.datatype.boolean()
        },
        ownership: {
          image: '',
          isDone: faker?.datatype.boolean(),
          isOwner: true,
          relationship: 'partner',
          other: ''
        }
      }
    : {};
