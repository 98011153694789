import Input from '@components/forms/Input';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ACCEPT_USER_INVITE, post, USER_INVITE } from '@services';
import { useEffect, useState } from 'react';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { ReactComponent as Tick } from '@assets/icons/Tick.svg';

const setUpFormSchema = yup
  .object({
    firstName: yup.string().required('Please enter your first name.'),
    lastName: yup.string().required('Please enter your last name.'),
    password: yup.string().required('Please enter your password.'),
    confirmPassword: yup.string().required('Please confirm your password.')
  })
  .required();
const InviteUserSetup = () => {
  const {
    register,
    watch,
    clearErrors,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(setUpFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    }
  });
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { firstName, lastName, password, confirmPassword } = watch();
  const [selectedColor, setSelectedColor] = useState('');

  const colors = [
    '#DD3434',
    '#EC6BC0',
    '#58A356',
    '#862E9C',
    '#FD9C2B',
    '#667085',
    '#39ACFF'
  ];
  const handleFirstNameChange = (e: any) => {
    const value = e.target.value;
    clearErrors('firstName');
    setValue('firstName', value);
  };
  const handleLastNameChange = (e: any) => {
    const value = e.target.value;
    clearErrors('lastName');
    setValue('lastName', value);
  };
  const handlePasswordChange = (e: any) => {
    const value = e.target.value;
    clearErrors('password');
    setValue('password', value);
  };
  const handleConfirmPasswordChange = (e: any) => {
    const value = e.target.value;
    clearErrors('confirmPassword');
    setValue('confirmPassword', value);
  };
  const verifyConfirmPwd = (e: any) => {
    const value = e.target.value;
    if (value !== password) {
      setError('confirmPassword', {
        type: 'custom',
        message: 'Please ensure the passwords entered match.'
      });
    }
  };

  const onSubmit = () => {
    const token = decodeURIComponent(hash.split('=')[1]);
    post(
      ACCEPT_USER_INVITE,
      {
        password: getValues('password'),
        token,
        user: {
          firstName: getValues('firstName'),
          lastName: getValues('lastName'),
          avatarColour: selectedColor ? selectedColor.replace('#', '') : '',
          initials: (
            getValues('firstName')[0] + getValues('lastName')[0]
          ).toUpperCase()
        }
      },
      false
    )
      .then((data) => {
        localStorage.setItem('user', data.user);
        localStorage.setItem('verifierUser', data.verifierUser);
        navigate('/login');
      })
      .catch((e) => {
        if (e.response.data.error.code === 'InvalidPassword') {
          setError('confirmPassword', {
            type: 'custom',
            message:
              'Your password must be at least 8 characters long and include at least one number, one special character, one uppercase letter, and one lowercase letter.'
          });
        } else {
          setError('root.server', {
            type: 'custom',
            message: e.response.data.error.code
          });
        }
      });
  };
  useEffect(() => {
    const token = decodeURIComponent(hash.split('=')[1]);
    post(USER_INVITE, { token }, false)
      .then((data) => {
        if (data.userInvite) {
          if (data.userInvite.hasAccepted) {
            navigate('/login');
          } else {
            setValue('firstName', data.userInvite.firstName);
            setValue('lastName', data.userInvite.lastName);
          }
        }
      })
      .catch((e) => {
        if (e.response.data.error.code === 'TokenExpired') {
          setError('root.server', {
            type: '400',
            message:
              'Token is expired or be used. If you have set up your profile, please login.'
          });
        } else {
          setError('root.server', {
            type: '400',
            message: e.response.data.error.code
          });
        }
      });
  }, [hash]);
  return (
    <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
      <form className="flex flex-col gap-6">
        <h3 className="text-2xl text-grey-900 font-semibold mb-6 text-center">
          Setup Profile
        </h3>
        {errors?.root?.server && (
          <>
            <p className="text-xs text-error-light">
              {' '}
              {errors?.root?.server.message}
            </p>
          </>
        )}
        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">First Name</span>
          <Input
            type="text"
            {...register(`firstName`)}
            onKeyDown={(event) => {
              if (!/[A-Za-z]+/i.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="First Name"
            value={firstName}
            icon={errors.firstName && <ErrorIcon />}
            onChange={handleFirstNameChange}
          />
          {errors.firstName && (
            <span className="text-error-light text-2sm mt-[6px]">
              {errors.firstName?.message}
            </span>
          )}
        </label>

        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">Last name</span>
          <Input
            type="text"
            {...register(`lastName`)}
            onKeyDown={(event) => {
              if (!/[A-Za-z]+/i.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Last Name"
            icon={errors.lastName && <ErrorIcon />}
            value={lastName}
            onChange={handleLastNameChange}
          />
          {errors.lastName && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.lastName.message}
            </span>
          )}
        </label>
        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">
            Avatar Colour
          </span>
          <div className="flex">
            {colors.map((color) => (
              <div
                key={color}
                style={{
                  backgroundColor: color
                }}
                className="w-10 h-10 mr-2 rounded-[10px] flex items-center justify-center"
                onClick={(event) => {
                  event.preventDefault(); // Prevent form submission
                  setSelectedColor(color);
                }}>
                {selectedColor === color && <Tick />}
              </div>
            ))}
          </div>
        </label>
        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">Password</span>
          <Input
            type="password"
            {...register(`password`)}
            placeholder="Password"
            icon={errors.password && <ErrorIcon />}
            value={password}
            onChange={handlePasswordChange}
          />
          {errors.password && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.password.message}
            </span>
          )}
        </label>

        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">
            Confirm Password
          </span>
          <Input
            type="password"
            {...register(`confirmPassword`)}
            placeholder="Confirm Password"
            icon={errors.confirmPassword && <ErrorIcon />}
            value={confirmPassword}
            onBlur={verifyConfirmPwd}
            onChange={handleConfirmPasswordChange}
          />
          {errors.confirmPassword && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.confirmPassword.message}
            </span>
          )}
        </label>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Button
            className="bg-white border-grey-300 text-grey-700"
            variant="outlined">
            Cancel
          </Button>
          <Button
            className="w-full bg-primary text-white text-md font-semibold normal"
            onClick={(e) => {
              handleSubmit(onSubmit)();
            }}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InviteUserSetup;
